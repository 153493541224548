export default {
    namespaced: true,
    state: {
        filter: {
            // propriedades do filtro
            precoMin: null,
            precoMax: null,
            caloriasMin: null,
            caloriasMax: null,
            selos: []
            // outras propriedades do filtro...
        }
    },
    mutations: {
        // mutations do filtro, se necessário
        SET_FILTER(state, filter) {
            state.filter = filter;
        },
        ADD_SELO(state, seloId) {
            state.filter.selos.push(seloId);
        },
        REMOVE_SELO(state, seloId) {
            state.filter.selos = state.filter.selos.filter((id) => id !== seloId);
        }
    },
    actions: {
        async fetchProducts() {
            // Simulando a busca dos produtos com base no filtro
            // const products = [
            //     { id: 1, name: "Produto 1", price: 10, calories: 200, selos: ["selo1", "selo2"] },
            //     { id: 2, name: "Produto 2", price: 20, calories: 300, selos: ["selo2"] },
            //     { id: 3, name: "Produto 3", price: 15, calories: 250, selos: ["selo1"] }
            //     // outros produtos...
            // ];

            // Aplicar o filtro nos produtos
            // const filteredProducts = products.filter((product) => {
            // Aplicar as condições do filtro
            //const { precoMin, precoMax, caloriasMin, caloriasMax, selos } = state.filter;
            //     return (
            // (precoMin === null || product.price >= precoMin) &&
            // (precoMax === null || product.price <= precoMax) &&
            // (caloriasMin === null || product.calories >= caloriasMin) &&
            // (caloriasMax === null || product.calories <= caloriasMax) &&
            // (selos.length === 0 || selos.every((selo) => product.selos.includes(selo)))
            // outras condições do filtro...
            //        true
            //     );
            //  });

            // Retornar os produtos filtrados
            //   return filteredProducts;
        },
        addSelo({ commit }, seloId) {
            commit('ADD_SELO', seloId);
        },
        removeSelo({ commit }, seloId) {
            commit('REMOVE_SELO', seloId);
        }
    }
};

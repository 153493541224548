import { database, dbRef, dbGet } from '@/firebase/index';

export const getProducts = async (uidShop) => {
    try {
        const productsSnapshot = await dbGet(dbRef(database, `shops/${uidShop}/products`));
        const productsObject = productsSnapshot.val();
        if (productsObject !== null) {
            const productSameArray = Object.values(productsObject);
            return productSameArray;
        } else {
            return [];
        }
    } catch (error) {
        console.error("Erro ao buscar os pedidos:", error);
        throw error;
    }
}

export const getProductByProductId2 = (uidShop, productId) => {
    const productRef = dbRef(database, `shops/${uidShop}/products/${productId}`);
    return dbGet(productRef);
}

export const getProductByProductId = async (idShop, productId) => {
    try {
        const productRef = dbRef(database, `shops/${idShop}/products/${productId}`);
        const snapshot = await dbGet(productRef);

        if (snapshot.exists()) {
            return snapshot.val();
        }
    } catch (error) {
        console.error('Erro ao buscar produto:', error);
        throw error;
    }
}

export const getSealBySealId = async (idShop, sealId) => {
    try {
        const sealRef = dbRef(database, `shops/${idShop}/seals/${sealId}`);
        const snapshot = await dbGet(sealRef);

        if (snapshot.exists()) {
            return snapshot.val();
        }

        throw new Error('Produto não encontrados.');
    } catch (error) {
        console.error('Erro ao buscar detalhes do pedido:', error);
        throw error;
    }
}

export const getProductsSame = (uidShop,) => {
    const productsSameRef = dbRef(database, `shops/${uidShop}/productsSame`);
    return dbGet(productsSameRef);
}

export const getProductsSameByProducSametId = async (uidShop, productSameId) => {
    try {
        const productDetailRef = dbRef(database, `shops/${uidShop}/productsSame/${productSameId}`);
        const snapshot = await dbGet(productDetailRef);

        if (snapshot.exists()) {
            return snapshot.val();
        }

        throw new Error('Detalhes do pedido não encontrados.');
    } catch (error) {
        console.error('Erro ao buscar detalhes do pedido:', error);
        throw error;
    }
}

export const getProductsSameByproductSameIdList = async (uidShop, productSameIdList) => {
    try {
        const productSameArray = {};

        // Iterar sobre a lista de IDs de produtos
        for (const p of Object.values(productSameIdList)) {
            if (p) {
                // Chamar a função getProductsSameByproductSameId para recuperar o produto
                const productSame = await getProductsSameByProducSametId(uidShop, p.productSameId);

                // Armazenar o produto no array
                productSameArray[p.productSameId] = productSame
            }
        }

        // Retornar o array de produtos
        return productSameArray;
    } catch (error) {
        console.error('Erro ao buscar detalhes dos produtos:', error);
        throw error;
    }
}

export const getProductsByProductIdList = async (uidShop, productsSame) => {
    try {
        const products = {};
        // Iterar sobre a lista de IDs de produtos
        for (const p of Object.values(productsSame)) {
            if (p) {
                for (const o of Object.values(p.measurementsOptions)) {
                    // Chamar a função getProductsSameByproductSameId para recuperar o produto
                    const product = await getProductByProductId(uidShop, o.productId);
                    products[o.productId] = product
                }
                // Armazenar o produto no array
            }
        }

        // Retornar o array de produtos
        return products;
    } catch (error) {
        console.error('Erro ao buscar detalhes dos produtos:', error);
        throw error;
    }
}
export const getSealsBySealIdList = async (uidShop, products) => {
    try {
        const seals = {};
        // Iterar sobre a lista de IDs de produtos
        for (const p of Object.values(products)) {
            if (p.seals) {
                for (const s of Object.values(p.seals)) {
                    if (s) {
                        const product = await getSealBySealId(uidShop, s.sealId);
                        seals[s.sealId] = product;

                    }
                }
            }
        }

        // Retornar o array de produtos
        return seals;
    } catch (error) {
        console.error('Erro ao buscar detalhes dos produtos:', error);
        throw error;
    }
}
export const getSealsBySealIdObjects = async (uidShop, sealsObject) => {
    try {
        const seals = []
        for (const s of Object.values(sealsObject)) {
            if (s) {
                console.log({ s })
                const seal = await getSealBySealId(uidShop, s.sealId)
                seals.push(seal)
            }
        }
        return seals;
    } catch (error) {
        console.error('Erro ao buscar selos dos produtos:', error, sealsObject);
        throw error;
    }
}

<template>
    <main class="pg-internas bg-loja">
        <div class="container">
            <div class="row">
                <!-- Meu Carrinho -->
                <div class="col-xs-12 animated fadeIn" v-if="products.length">
                    <div class="row">
                        <div class="col-xs-12">
                            <div class="tabela-loja">
                                <!-- Conteúdo da tabela de produtos -->
                                <table class="tabela-padrao">
                                    <thead>
                                        <tr>
                                            <th width="45%" class="td-product">Produto</th>
                                            <th width="15%" class="text-center td-price">Preço unitário</th>
                                            <th width="10%" class="text-center td-quant">Quantidade</th>
                                            <th width="10%" class="text-center td-total">Total</th>
                                            <th width="5%" class="text-center td-excluir">Excluir</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <!-- Linhas da tabela de produtos -->
                                        <tr v-for="(product, index) in products" :key="index">
                                            <td class="td-product">
                                                <router-link :to="product.link" class="flex">
                                                    <img :src="product.dataSrc" class="img-responsive img-product"
                                                        :alt="product.name" />
                                                    <h6>{{ product.name }}</h6>
                                                </router-link>
                                            </td>
                                            <td data-th="Preço unitário" class="text-center td-price">
                                                <div class="valor">{{ getPrice(product) }}</div>
                                            </td>
                                            <td data-th="Quantidade" class="text-center td-quant">
                                                <div class="quantity">
                                                    <div class="box-number">
                                                        <div class="add-qtd add-qtd2"
                                                            @click="decrementProductQuantity(product)">
                                                            <i class="fa fa-minus"><span>-</span></i>
                                                        </div>
                                                        <input type="text" class="qtd" v-model="product.quantity" />
                                                        <div class="add-qtd add-qtd2"
                                                            @click="incrementProductQuantity(product)">
                                                            <i class="fa fa-plus"><span>+</span></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td data-th="Total" class="text-center td-total">{{ calcularTotal(product)
                                                }}</td>
                                            <td data-th="Excluir" class="text-center td-excluir">
                                                <a @click="removeProduct(product)" class="btn-excluir"><i
                                                        class="fa fa-times"></i></a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <!-- Box de resumo da cesta -->
                            <div class="box-cesta-v2">
                                <div class="row">
                                    <!-- Valor total -->
                                    <div class="col-xs-12">
                                        <div class="card-cesta-total">
                                            <div class="card-cesta-valor">
                                                <h5>Valor total</h5>
                                                <div class="valor-total gtag-value">{{ calcularValorTotal() }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Botões de navegação -->
                        <div class="margin-top-60">
                            <div class="flex no-flex-xs">
                                <!-- Botão "Continuar comprando" -->
                                <div class="col-xs-12">
                                    <div class="box-voltar-bottom">
                                        <router-link :to="{ path: '/' }" class="btn-voltar icon-left"><i
                                                class="fa fa-arrow-left"></i>Continuar comprando</router-link>
                                    </div>
                                </div>
                                <!-- Botão "Finalizar compra" -->
                                <div class="col-xs-12">
                                    <div class="box-btn-bottom">
                                        <router-link :to="{ path: '/checkout' }" class="btn btn-wide">Finalizar compra
                                            »</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12" v-else>
                    <div class="alert alert-warning">Sua cesta está vazia.</div>
                    <!-- Botões de navegação -->
                    <div class="margin-top-60">
                            <div class="flex no-flex-xs">
                                <!-- Botão "Continuar comprando" -->
                                <div class="col-xs-12">
                                    <div class="box-voltar-bottom">
                                        <router-link :to="{ path: '/' }" class="btn-voltar icon-left"><i
                                                class="fa fa-arrow-left"></i>Voltar</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </main>
</template>


<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    computed: {
        ...mapGetters('cart', {
            subtotal: 'getSubtotalFormatted',
            products: 'getCartItens'
        })
    },
    methods: {
        ...mapGetters('cart', [
            'getSubtotalFormatted',
            'getCartItens'
        ]),
        ...mapActions('cart', [
            'addProduct',
            'removeProduct',
            'incrementProductQuantity',
            'decrementProductQuantity'
        ]),
        // Método para calcular o total de um produto
        calcularTotal(product) {
            let subtotal = 0;
            let price = product.price.value;
            if (product.price.discount.active) {
                price = price * (1 - product.price.discount.value)
            }
            subtotal = price * product.quantity;

            return subtotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
        },
        // Método para calcular o valor total da compra
        calcularValorTotal() {
            return this.subtotal;
        },
        getPrice(item) {
            let price = item.price.value;
            if (item.price.discount.active) {
                price = price * (1 - item.price.discount.value)
            }

            return price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

        }
    }
};
</script>

<style scoped>
img {
    max-height: 50px !important;
    min-height: 50px !important;
    margin-right: 10px !important;
}
</style>

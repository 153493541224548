<template>
    <MainLayout>
      <!-- Banner internas -->
      <section class="banner-interna"
               style="background-image: url(https://jullietcongelados.com.br/lojas/juliettecongelados/theme/assets/img/ban-interna-1.jpg);">
        <div class="pg-titulo">
          <div class="container">
            <div class="row">
              <div class="col-xs-12">
                <h1 class="animated fadeIn">STATUS DO MEU PEDIDO
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
  
      <!-- Content -->
      <!-- Detalhes -->
      <DetailsOrderTable v-if="isOrderDetailsLoaded" />
    </MainLayout>
  </template>
  
  <script>
  import MainLayout from '@/layouts/MainLayout.vue';
  import DetailsOrderTable from '@/components/Order/DetailsOrderTable.vue';
  import { mapActions, mapGetters } from 'vuex';
  
  export default {
    components: {
      MainLayout,
      DetailsOrderTable,
    },
    computed: {
      ...mapGetters('user', ['getOrderDetailsSelected']),
      isOrderDetailsLoaded() {
        return !!this.getOrderDetailsSelected;
      }
    },
    methods: {
      ...mapActions('user', ['setOrderDetails'])
    },
    async beforeMount() {
      await this.setOrderDetails(this.$route.params.orderId)
    } 
  }
  </script>
  
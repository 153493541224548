<template>
    <div class="dsac-row">
        <div class="dsac-col-12 dsac-mb-3 dsac-mb-sm-4">
            <h1 class="dsac-h2">Como você quer
                <span v-if="resumoPedido.tipo.entrega.active">receber</span>
                <span v-if="resumoPedido.tipo.retirada.active">retirar</span>
                seus produtos?
            </h1>
            <p class="dsac-small dsac-mt-2">Campos com <span class="dsac-text-danger">*</span> são obrigatórios.</p>
        </div>

        <div class="dsac-col-12 dsac-col-lg-8 dsac-mb-4 dsac-mb-lg-0">
            <div class="dsac-row">
                <div class="dsac-col-12 dsac-col-md-4 dsac-mb-4 dsac-mb-md-0">
                    <ul class="dsac-nav dsac-nav-icon">
                        <li>
                            <button class="dsac-btn dsac-w-xs-100 dsac-mt-3"
                                @click="setActiveStepChoiceTypeComponent('StepChoiceTypeDelivery')"
                                :class="[activeStepChoiceTypeComponent === 'StepChoiceTypeDelivery' ? 'dsac-btn-primary' : 'dsac-btn-outline-primary']">
                                <i class="dsac-fa dsac-la-truck"></i>Entrega
                            </button>
                        </li>
                        <li>
                            <button class="dsac-btn dsac-w-xs-100 dsac-mt-3"
                                @click="setActiveStepChoiceTypeComponent('StepChoiceTypePickup')"
                                :class="[activeStepChoiceTypeComponent === 'StepChoiceTypePickup' ? 'dsac-btn-primary' : 'dsac-btn-outline-primary']">
                                <i class="dsac-fa dsac-la-store-alt"></i>Retirada
                            </button>
                        </li>
                    </ul>
                </div>
                <div class="dsac-col-12 dsac-col-md-8">
                    <div class="dsac-tab-content">

                        <!-- Entrega -->
                        <component :is="activeStepChoiceTypeComponent">
                        </component>

                        <!-- Retirada -->

                    </div>
                </div>
            </div>
        </div>

        <!-- Resumo -->
        <div class="dsac-col-12 dsac-col-lg-4">
            <div class="dsac-row">
                <div class="dsac-col-12 dsac-mb-3 dsac-mb-sm-4">
                    <div class="dsac-card-white" id="js-resumo">
                        <h4 class="dsac-mb-1">Resumo do pedido</h4>
                        <div class="dsac-card-white-list">
                            <h5 class="dsac-pe-sm-3">Valor dos produtos</h5>
                            <h5 class="dsac-text-nowrap"><strong>{{ formattedValorProdutos }}</strong></h5>
                        </div>
                        <div class="dsac-card-white-list" v-if="resumoPedido.frete.active">
                            <h5 class="dsac-pe-sm-3">Frete</h5>
                            <h5 class="dsac-text-nowrap dsac-js-valor-frete"><strong>+ {{ formattedFrete }}</strong>
                            </h5>
                        </div>
                        <div class="dsac-card-white-list" v-if="resumoPedido.coupon.active">
                            <h5 class="dsac-pe-sm-3">Cupom de Desconto</h5>
                            <h5 class="dsac-text-nowrap dsac-text-success dsac-">
                                <strong>{{ formattedDescontoCupom }}</strong>
                            </h5>
                        </div>
                        <div class="dsac-card-white-list" v-if="resumoPedido.descontoPagamento.active">
                            <h5 class="dsac-pe-sm-3">Desconto Pagamento</h5>
                            <h5 class="dsac-text-nowrap dsac-text-success dsac-">
                                <strong>{{ formattedDescontoPagamento }}</strong>
                            </h5>
                        </div>
                        <div class="dsac-card-white-list">
                            <h3 class="dsac-pe-sm-3">Total</h3>
                            <h3 class="dsac-text-nowrap dsac-js-resumo-total">{{ formattedTotalCheckout }}</h3>
                        </div>
                        <div class="dsac-alert dsac-alert-info dsac-text-center dsac-mt-2 dsac-mt-sm-3 dsac-d-none"
                            id="js-alerta-agendamento"></div>
                        <div class="dsac-alert dsac-text-center dsac-mt-2 dsac-mt-sm-3" id="js-alerta-desconto-frete">
                            Faltam
                            <strong>{{ formattedDescontoFreteGratis }}</strong> para ganhar <strong>100% de
                                desconto</strong> no frete.
                        </div>
                    </div>
                </div>
                <div class="dsac-col-12 dsac-text-sm-end dsac-mt-2">
                    <div class="dsac-d-sm-inline-flex dsac-align-items-center dsac-justify-content-end dsac-w-100">
                        <button id="btnContinuar" @click="setActiveStepComponent('StepShedule')"
                            :disabled="verifyIfIsButtonDisabledNextInCheckoutStepChoiceType"
                            class="dsac-btn dsac-btn-primary dsac-btn-xs-fixed">
                            Continuar<i class="dsac-fa dsac-la-arrow-right dsac-ms-1"></i>
                        </button>
                        <div class="dsac-text-center dsac-mt-4 dsac-d-sm-none">
                            <router-link :to="{ path: '/shopping-cart' }" class="dsac-btn-link">Voltar para o
                                Carrinho</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import Summary from '@/components/Checkout/Steps/StepChoiceTypeSummary.vue'
import StepChoiceTypeDelivery from '@/components/Checkout/Steps/StepChoiceTypeDelivery.vue'
import StepChoiceTypePickup from '@/components/Checkout/Steps/StepChoiceTypePickup.vue'
import { mapState, mapActions, mapGetters } from 'vuex';
export default {
    components: {
        Summary,
        StepChoiceTypeDelivery,
        StepChoiceTypePickup
    },
    data() {
        return {
            showModalEndereco: false,
            showModalEnderecoInt: false
        };
    },
    methods: {
        ...mapActions('checkout', ['abrirModalEndereco', 'abrirModalEnderecoInt', 'setActiveStepChoiceTypeComponent', 'setActiveStepComponent', 'updateCheckoutTotal'])
    },
    computed: {
        ...mapState('checkout', ['modalEnderecoVisivel', 'modalEnderecoIntVisivel', 'activeStepChoiceTypeComponent', 'resumoPedido']),
        ...mapGetters('checkout', ['formattedValorProdutos', 'formattedFrete', 'formattedDescontoPagamento', 'formattedTotal', 'formattedTotalCheckout', 'formattedDescontoFreteGratis', 'formattedDescontoCupom', 'verifyIfIsButtonDisabledNextInCheckoutStepChoiceType']),
    },
    created() {
        this.updateCheckoutTotal()
    }
};
</script>

const firebaseConfig = {
    apiKey: "AIzaSyC9NvJgj1SxMdzjsDkAbaWN6NwtiW5h5eo",
    authDomain: "donasolajuda.firebaseapp.com",
    databaseURL: "https://donasolajuda.firebaseio.com",
    projectId: "donasolajuda",
    storageBucket: "donasolajuda.appspot.com",
    messagingSenderId: "411966866521",
    appId: "1:411966866521:web:39a5c17f4e436238b45d48"
  };
export default firebaseConfig;

<template>
    <div class="js-overlay-html" v-show="cartVisivel === true"> <!-- Adicione a classe js-overlay-html aqui -->
        <div class="dsac-offcanvas dsac-offcanvas-end" :class="{ 'dsac-show': cartVisivel }" tabindex="-1"
            id="offcanvasCarrinho" aria-modal="true" role="dialog">
            <div class="dsac-offcanvas-header">
                <h2>Meus produtos</h2>
                <button type="button" class="dsac-btn-close dsac-text-reset" @click="fecharCart"
                    aria-label="Close"></button>
            </div>
            <div class="dsac-offcanvas-body">

                <div class="dsac-offcanvas-main">
                    <div class="dsac-card-frete dsac-mb-4">
                        <h4>{{ getSubtotalFormatted }}</h4>
                        <div class="dsac-progress dsac-sm dsac-mt-1 dsac-d-none" id="js-bar">
                            <div class="dsac-progress-bar" role="progressbar" id="js-frete-bar"></div>
                        </div>
                        <div class="dsac-tag dsac-tag-warning dsac-mt-2 dsac-d-none" id="js-frete-header">
                        </div>
                    </div>

                    <div class="dsac-card-product" v-for="(product, index) in getCartItens" :key="index">
                        <div class="dsac-cover">
                            <picture class="dsac-bg-picture">
                                <img class="dsac-img-fluid" :src="product.dataSrc" :alt="product.title">
                            </picture>
                            <span class="dsac-badge">{{ product.quantity }}</span>
                        </div>
                        <div class="dsac-d-block">
                            <h5>{{ product.name }}</h5>
                            <h4>{{ calculateSubtotal(product) }}</h4>
                        </div>
                    </div>

                </div>

                <div class="dsac-offcanvas-footer">
                    <router-link :to="{ path: 'shopping-cart' }" class="dsac-btn dsac-btn-white dsac-w-100"
                        role="button">Voltar para o
                        Carrinho</router-link>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
    methods: {
        ...mapActions('checkout', ['abrirCart', 'fecharCart']),
        calculateSubtotal(item) {
            let subtotal = 0;
            let price = item.price.value;
            if (item.price.discount.active) {
                price = price * (1 - item.price.discount.value)
            }
            subtotal = price * item.quantity;

            return subtotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

        }
    },
    computed: {
        ...mapState('checkout', ['cartVisivel']),
        ...mapGetters('cart', ['getSubtotalFormatted', 'getQuantityFromCart', 'getCartItens'])
    }
};
</script>

<style scoped="">
/* Adicione seus estilos aqui */
</style>

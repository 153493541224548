<!-- LoginPage.vue -->
<template>
    <MainLayout>
        <!-- Banner internas -->
        <section class="banner-interna"
            style="background-image: url(https://jullietcongelados.com.br/lojas/juliettecongelados/theme/assets/img/ban-interna-1.jpg);">
            <div class="pg-titulo">
                <div class="container">
                    <div class="row">
                        <div class="col-xs-12">
                            <h1 class="animated fadeIn">Indique um amigo</h1>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- Content -->
        <ReferAFriendContent />

        <!-- Modal Novidades-->


        <!-- Modal Snapchat e Whatsapp -->



        <div id="whatsapp-modal" class="modal-social modal fade" tabindex="-1" role="dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                        <div class="content">
                            <div class="icon"><i class="fa fa-whatsapp"></i></div>
                            <h2>WhatsApp</h2>
                            <div class="tel"><a href="tel:(11) 97702-0353">(11) 97702-0353</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </MainLayout>
</template>

<script>
import MainLayout from '@/layouts/MainLayout.vue';
import ReferAFriendContent from '@/components/ReferAFriendContent.vue';
export default {
    components: {
        MainLayout,
        ReferAFriendContent
    },
    data() {
        return {
            email: 'usuario@exemplo.com',
            password: 'senha123'
        }
    },
    methods: {
        login() {
            // Chamar a action de login da store Vuex
            this.$store.dispatch('login', { email: this.email, password: this.password })
        }
    }
}
</script>

<template>
    <section class="box-breadcrumb">
        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <breadcrumb-item v-for="(item, index) in items" :key="index" :label="item.label" :url="item.url"
                                :is-active="index === activeIndex" />
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </section>
</template>
  
<script>
import BreadcrumbItem from '@/components/BreadcrumbItem.vue';

export default {
    name: 'BreadcrumbsComponent',
    components: {
        BreadcrumbItem
    },
    props: {
        items: {
            type: Array,
            required: true
        },
        activeIndex: {
            type: Number,
            default: -1
        }
    }
}
</script>
  
<template>
    <div class="dsac-col-12 dsac-col-lg-4">
        <div class="dsac-row">
            <div class="dsac-col-12 dsac-mb-3 dsac-mb-sm-4">
                <div class="dsac-card-white" id="js-resumo">
                    <h4 class="dsac-mb-1">Resumo do pedido</h4>
                    <div class="dsac-card-white-list">
                        <h5 class="dsac-pe-sm-3">Valor dos produtos</h5>
                        <h5 class="dsac-text-nowrap"><strong>R$ 153,70</strong></h5>
                    </div>
                    <div class="dsac-card-white-list" v-if="resumoPedido.frete.active">
                        <h5 class="dsac-pe-sm-3">Frete</h5>
                        <h5 class="dsac-text-nowrap dsac-js-valor-frete"><strong>+ R$
                                {{ resumoPedido.frete.value }}</strong></h5>
                    </div>
                    <div class="dsac-card-white-list">
                        <h5 class="dsac-pe-sm-3">Desconto Pagamento</h5>
                        <h5 class="dsac-text-nowrap dsac-text-success dsac-"><strong>R$ -4,64</strong></h5>
                    </div>
                    <div class="dsac-card-white-list">
                        <h3 class="dsac-pe-sm-3">Total</h3>
                        <h3 class="dsac-text-nowrap dsac-js-resumo-total">R$ 150,06</h3>
                    </div>
                    <div class="dsac-alert dsac-alert-info dsac-text-center dsac-mt-2 dsac-mt-sm-3 dsac-dsac-alert dsac-dsac-alert-info dsac-dsac-text-center dsac-dsac-mt-2 dsac-dsac-mt-sm-3"
                        id="js-alerta-endereco">Seu pedido será entregue na <strong>Praça da Sé, 1 - Sé, São Paulo -
                            SP</strong></div>
                    <div class="dsac-alert dsac-alert-info dsac-text-center dsac-mt-2 dsac-mt-sm-3 dsac-dsac-alert dsac-dsac-alert-info dsac-dsac-text-center dsac-dsac-mt-2 dsac-dsac-mt-sm-3 dsac-dsac-dsac-alert dsac-dsac-dsac-alert-info dsac-dsac-dsac-text-center dsac-dsac-dsac-mt-2 dsac-dsac-dsac-mt-sm-3"
                        id="js-alerta-agendamento">Sua entrega foi agendada para o
                        dia <strong>02/04/2024 Terça-feira</strong> no período das <strong>17:00h às 21:00h</strong>
                    </div>

                    <div class="dsac-alert dsac-alert-info dsac-text-center dsac-mt-2 dsac-mt-sm-3 dsac-d-none"
                        id="js-alerta-agendamento"></div>
                    <div class="dsac-alert dsac-text-center dsac-mt-2 dsac-mt-sm-3" id="js-alerta-desconto-frete">
                        Faltam
                        <strong>R$ 244,30</strong> para ganhar <strong>100% de desconto</strong> no frete.
                    </div>
                </div>
            </div>
            <div class="dsac-col-12 dsac-text-sm-end dsac-mt-2">
                <div class="dsac-d-sm-inline-flex dsac-align-items-center dsac-justify-content-end dsac-w-100">
                    <button id="btnContinuar" @click="setActiveStepComponent('StepShedule')"
                        class="dsac-btn dsac-btn-primary dsac-btn-xs-fixed">
                        Continuar<i class="dsac-fa dsac-la-arrow-right dsac-ms-1"></i>
                    </button>
                    <div class="dsac-text-center dsac-mt-4 dsac-d-sm-none">
                        <router-link :to="{ path: '/shopping-cart' }" class="dsac-btn-link">Voltar para o
                            Carrinho</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';

export default {
    methods: {
        ...mapActions('checkout', ['setActiveStepComponent'])
    },
    computed: {
        ...mapState('checkout', ['modalEnderecoVisivel', 'modalEnderecoIntVisivel', 'activeStepComponent', 'resumoPedido'])
    }
};
</script>

<template>
    <header class="dsac-header dsac-bg-primary dsac-text-white">
        <div class="dsac-container">
            <div class="dsac-row">
                <div class="dsac-col-12">
                    <div class="dsac-d-inline-flex dsac-align-items-center dsac-justify-content-between dsac-w-100">
                        <router-link :to="{ path: '/' }" class="dsac-logo" aria-label="Juliette Comidas Congeladas">
                            <img class="dsac-img-fluid" :src="logoSrc" alt="Juliette Comidas Congeladas">
                        </router-link>
                        <div class="dsac-d-inline-flex dsac-align-items-center dsac-justify-content-end">
                            <h5 class="dsac-me-2 dsac-d-none dsac-d-sm-block dsac-text-white"><span>{{
                            getQuantityFromCart }}
                                    item</span> no seu carrinho</h5>
                            <a href="javascript:;" @click="abrirCart" class="dsac-btn-icon" role="button"
                                aria-label="Carrinho">
                                <i class="fa-solid fa-cart-shopping dsac-text-white"></i>
                                <span class="dsac-badge dsac-bg-light dsac-text-primary">{{ getQuantityFromCart
                                    }}</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    data() {
        return {
            logoSrc: '/assets/img/logo-dsa.png',
        };
    },
    methods: {
        ...mapActions('checkout', ['abrirCart'])
    },
    computed: {
        ...mapGetters('cart', ['getQuantityFromCart'])
    }
};
</script>

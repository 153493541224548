import { database, dbRef, dbGet } from '@/firebase/index';


export const getCategoriesByShopId = async (shopUid) => {
    try {
        const categoriesSnapshot = await dbGet(dbRef(database, `shops/${shopUid}/categories`));
        const categoriesObject = categoriesSnapshot.val();
        // Verifica se os dados não são nulos antes de tentar acessá-los
        if (categoriesObject !== null) {
            // Transforma o objeto em um array
            const categoriesArray = categoriesObject;
            return categoriesArray;
        } else {
            // Se os dados forem nulos, retorna um array vazio
            return [];
        }
    } catch (error) {
        console.error("Erro ao buscar os pedidos:", error);
        throw error;
    }
}

export const getCategoryByCategoryId = async (idShop, categoryId) => {
    try {
        const categoryRef = dbRef(database, `shops/${idShop}/categories/${categoryId}`);
        const snapshot = await dbGet(categoryRef);

        if (snapshot.exists()) {
            return snapshot.val();
        }

        throw new Error('Categoria não encontrada.');
    } catch (error) {
        console.error('Erro ao buscar categoria:', error);
        throw error;
    }
}

import {
    auth,
    database,
    dbRef,
    dbSet,
    dbGet,
    authSignOut,
    authUpdatePassword,
    authSignInWithEmailAndPassword,
    authCreateUserWithEmailAndPassword,
} from '@/firebase';


export default {
    namespaced: true,
    state: {
        user: null,
        isLoggedIn: true
    },
    mutations: {
        SET_USER(state, user) {
            state.user = user;
        },
        SET_IS_LOGGED_IN(state, isLoggedIn) {
            state.isLoggedIn = isLoggedIn;
        }
    },
    actions: {
        register({ commit }, userData) {
            return new Promise((resolve, reject) => {
                authCreateUserWithEmailAndPassword(auth, userData.email, userData.password)
                    .then(response => {
                        const userId = response.user.uid;
                        userData.password = null;
                        // Salvar dados do usuário no Realtime Database
                        const data = {
                            myCadastre: {
                                uid: userId,
                                ...userData
                            },
                            myCredits: [
                                {
                                    history: [
                                        ""
                                    ],
                                    total: 100
                                }
                            ],
                            myFavorites: [
                                {
                                    idProduct: 1
                                }
                            ],
                            myIndications: [
                                {
                                    code: 1234,
                                    name: "Maria"
                                }
                            ],
                            myOrders: [
                                {
                                    listProduct: [
                                        null,
                                        {
                                            idProduct: 111
                                        }
                                    ]
                                }
                            ]
                        };

                        dbSet(dbRef(database, 'users/' + userId), data)
                            .then(() => {
                                commit('SET_USER', {
                                    uid: userId,
                                    email: userData.email,
                                    // Outros dados do usuário
                                });
                                resolve();
                            })
                            .catch(error => {
                                reject(error);
                            });
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        login2({ commit }, { email, password }) {
            return new Promise((resolve, reject) => {
                authSignInWithEmailAndPassword(auth, email, password)
                    .then(response => {
                        const userId = response.user.uid;
                        // Obter dados do usuário do Realtime Database
                        const userRef = dbRef(database, 'users/' + userId);
                        dbGet(userRef)
                            .then(snapshot => {
                                const userData = snapshot.val();
                                commit('SET_USER', userData);
                                resolve();
                            })
                            .catch(error => {
                                reject(error);
                            });
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        login({ commit, dispatch }, { email, password }) {
            return new Promise((resolve, reject) => {
                authSignInWithEmailAndPassword(auth, email, password)
                    .then(response => {
                        const userId = response.user.uid;
                        dispatch('user/loadUserData', userId, { root: true }) // Chama a ação 'loadUserData' do módulo 'user'
                            .then(() => {
                                commit('SET_IS_LOGGED_IN', true)
                                resolve(true);
                            })
                            .catch(error => {
                                commit('SET_IS_LOGGED_IN', false)
                                reject(error);
                            });
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        logout({ commit }) {
            return new Promise((resolve, reject) => {
                authSignOut(auth)
                    .then(() => {
                        commit('SET_USER', null);
                        resolve(true);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        changePassword({ state }, newPassword) {
            return new Promise((resolve, reject) => {
                const user = auth.currentUser;
                console.log(state)
                if (user) {
                    authUpdatePassword(user, newPassword)
                        .then(() => {
                            resolve();
                        })
                        .catch(error => {
                            reject(error);
                        });
                } else {
                    reject(new Error('Usuário não autenticado.'));
                }
            });
        }
    }
};

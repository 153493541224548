import { authSignInWithGoogle, authSignOutGoogle } from '@/firebase/index';
import { createUser, getUser } from '@/services/auth';

export default {
    namespaced: true,
    state: {
        user: null,
        error: null,
        accessToken: null,
        isLoggedIn: false,
        redirectRoute: null
    },
    mutations: {
        setUser(state, payload) {
            state.user = payload;
        },
        setError(state, payload) {
            state.error = payload;
        },
        SET_ACCESS_TOKEN(state, token) {
            state.accessToken = token;
        },
        SET_LOGGED_IN(state, status) {
            state.isLoggedIn = status;
        },
        SET_REDIRECT_ROUTE(state, route) {
            state.redirectRoute = route;
        }
    },
    actions: {
        async signInWithGoogle({ commit, state, dispatch }, router) {
            try {
                const result = await authSignInWithGoogle();
                const user = result.user;

                // Verifique se o usuário já existe no banco de dados
                const userSnapshot = await getUser(user.uid, '1');

                if (!userSnapshot.exists()) {
                    // Se o usuário não existir, crie-o no banco de dados
                    await createUser(user.uid, '1', {
                        user: {
                            displayName: user.displayName,
                            email: user.email,
                            photoURL: user.photoURL,
                            emailVerified: user.emailVerified,
                            metadata: user.metadata,
                            uid: user.uid
                        }
                    });
                }

                // Após o login bem-sucedido, redireciona para a rota armazenada
                const redirectRoute = state.redirectRoute || { name: 'Home' };
                router.push(redirectRoute);

                commit('SET_LOGGED_IN', true);
                commit('SET_ACCESS_TOKEN', user.accessToken);
                commit('setUser', {
                    displayName: user.displayName,
                    email: user.email,
                    photoURL: user.photoURL,
                    emailVerified: user.emailVerified,
                    metadata: user.metadata,
                    uid: user.uid
                });
                commit('setError', null);
                dispatch('user/getLikesUser', null, { root: true })
            } catch (error) {
                console.log(error);
                commit('setError', error.message);
            }
        },
        async signOut({ commit }) {
            try {
                await authSignOutGoogle();
                commit('setUser', null); // Define o usuário como null após logout
                commit('setError', null); // Define o erro como null após logout
                commit('SET_LOGGED_IN', false);
                commit('SET_ACCESS_TOKEN', null);
                commit('setUser', null);
            } catch (error) {
                console.log(error);
                // Lidar com erros, se necessário
            }
        }
    },
    getters: {
        currentUser(state) {
            return state.user;
        },
        error(state) {
            return state.error;
        }
    }
};

<template>
    <div id="meuCarrinho" :class="{ 'overlay-menu': true, 'overlay-right': true, 'active': isCarrinhoOpen }">
        <a @click="closeCarrinhoRight" class="btn-close"><i class="fa fa-times"></i></a>
        <div class="overlay-content">
            <div id="cesta-topo1">
                <div class="icon-topo">
                    <!-- Modificação do link -->
                    <router-link :to="{ path: '/shopping-cart' }" class="dropdown-toggle" @click="closeCarrinhoRight">R$
                        21,90</router-link>
                    <h4 style="display: none;">Meu Carrinho</h4>
                </div>
                <ul class="dropdown-menu">
                    <li class="box-checkout-top hidden">
                        <div class="box-checkout">
                            <!-- Modificação do link -->
                            <router-link :to="{ path: '/shopping-cart' }" class="btn"
                                @click="closeCarrinhoRight">Finalizar Compra</router-link>
                        </div>
                    </li>
                    <!-- Add v-bind:key directive here -->
                    <CartItemComponent v-for="item in getCartItens" :key="item.id" :item="item" />
                    <li class="group-btn" v-if="getCartItens.length">
                        <div class="box-subtotal">
                            Subtotal: <span class="gtag-value">{{ getSubtotalFormatted }}</span>
                        </div>
                        <div class="box-checkout">
                            <div class="flex-space" style="display: none;">
                                <!-- Modificação do link -->
                                <router-link :to="{ path: '/shopping-cart' }"
                                    class="btn icon-left gtag-btn-begincheckout" @click="closeCarrinhoRight">
                                    <i class="fa fa-check"></i>Finalizar Compra
                                </router-link>
                            </div>
                            <!-- Modificação do link -->
                            <router-link :to="{ path: '/shopping-cart' }" class="btn gtag-btn-begincheckout"
                                @click="closeCarrinhoRight">Finalizar Compra</router-link>
                        </div>
                    </li>
                    <li v-else>
                        <div class="box-subtotal">
                            <p class="mens">Seu carrinho está vazio.</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        {{ console.log(getCartItensToCheckout) }}
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import CartItemComponent from './CartItemComponent.vue';

export default {
    components: {
        CartItemComponent
    },
    computed: {
        ...mapGetters('cart', ['getCartItens', 'getSubtotalFormatted','getCartItensToCheckout']),
        ...mapState('cart', ['isCarrinhoOpen'])
    },
    methods: {
        ...mapActions('cart', ['openCarrinhoRight', 'closeCarrinhoRight']),
    }
}
</script>

<!-- MainLayout.vue -->
<template>
    <div>
        <section id="buscaFull" class="box-busca-full">
            <div class="group">
                <a href="javascript:" class="btn-fechar js-fechar-busca">
                    <i class="fa fa-times"></i><span class="sr-only">Fechar busca</span>
                </a>
                <div class="busca mod-full flex-center">
                    <form action="https://jullietcongelados.com.br/busca" method="get">
                        <span class="item-titulo flex-center">
                            <h2>Digite o nome do produto</h2>
                        </span>
                        <input type="text" class="form-control autocompletar" name="palavra" id="busca">
                        <h6 class="hidden-xs">Digite o nome do produto e aperte o <span>Enter</span> ou selecione um
                            produto
                            da lista.</h6>
                        <h6 class="visible-xs">Digite o nome do produto e toque em <span>Ir</span> ou selecione um
                            produto
                            da lista.</h6>
                    </form>
                </div>
            </div>
        </section>

        <CartComponent />

        <div id="menuTopo" class="collapse navbar-collapse">
            <div class="css-mobile">

                <aside class="aside-left">
                    <div class="box-nav-submenu">
                        <a href="javascript:" class="css-mobile navbar-toggle-close"><i class="fa fa-times"></i><span
                                class="sr-only">Fechar</span></a>

                        <div class="group-title menu-categorias">
                            <h4>Categorias</h4>
                        </div>
                        <ul class="nav menu-categorias">
                            <li class="dropdown  ">
                                <a href="https://jullietcongelados.com.br/promocoes" class="dropdown-toggle flex-icon"
                                    data-toggle="dropdown">
                                    <picture>
                                        <source
                                            srcset="https://jullietcongelados.com.br/lojas/juliettecongelados/conteudo/uploads/icons8-desconto-64-645a7e33bedf3.webp"
                                            type="image/webp" />
                                        <img class="icon-menu"
                                            src="https://jullietcongelados.com.br/lojas/juliettecongelados/conteudo/uploads/icons8-desconto-64-645a7e33bedf3.png"
                                            alt="Promoções">
                                    </picture> Promoções<i class="fa fa-caret-right"></i>
                                </a>
                                <ul class="dropdown-menu" role="menu">
                                </ul>
                            </li>
                            <li class="dropdown  link">
                                <a href="https://jullietcongelados.com.br/kits" class="dropdown-toggle flex-icon"
                                    data-toggle="linkdropdown">
                                    <picture>
                                        <source
                                            srcset="https://jullietcongelados.com.br/lojas/juliettecongelados/conteudo/uploads/icon-kits-612fbf564c522-1-628542a329f8c.webp"
                                            type="image/webp" />
                                        <img class="icon-menu"
                                            src="https://jullietcongelados.com.br/lojas/juliettecongelados/conteudo/uploads/icon-kits-612fbf564c522-1-628542a329f8c.png"
                                            alt="Kits">
                                    </picture> Kits<i class="fa fa-caret-right"></i>
                                </a>
                                <ul class="dropdown-menu" role="menu">
                                </ul>
                            </li>
                            <li class="dropdown  link">
                                <a href="https://jullietcongelados.com.br/pratos-prontos"
                                    class="dropdown-toggle flex-icon" data-toggle="linkdropdown">
                                    <picture>

                                        <img class="icon-menu"
                                            src="https://jullietcongelados.com.br/lojas/juliettecongelados/conteudo/uploads/icon-pratos-prontos-612fbf7a46726.png"
                                            alt="Pratos">
                                    </picture> Pratos<i class="fa fa-caret-right"></i>
                                </a>
                                <ul class="dropdown-menu" role="menu">
                                </ul>
                            </li>
                            <li class="dropdown  link">
                                <a href="https://jullietcongelados.com.br/fit" class="dropdown-toggle flex-icon"
                                    data-toggle="linkdropdown">
                                    <picture>

                                        <img class="icon-menu"
                                            src="https://jullietcongelados.com.br/lojas/juliettecongelados/conteudo/uploads/icon-fit-low-carb-612fc16bda702.png"
                                            alt="Fit">
                                    </picture> Fit<i class="fa fa-caret-right"></i>
                                </a>
                                <ul class="dropdown-menu" role="menu">
                                </ul>
                            </li>
                            <li class="dropdown  link">
                                <a href="https://jullietcongelados.com.br/vegetariano-e-vegano"
                                    class="dropdown-toggle flex-icon" data-toggle="linkdropdown">
                                    <picture>

                                        <img class="icon-menu"
                                            src="https://jullietcongelados.com.br/lojas/juliettecongelados/conteudo/uploads/icon-vegetariano-vegano-612fbfc756ed7.png"
                                            alt="Vegetariano">
                                    </picture> Vegetariano<i class="fa fa-caret-right"></i>
                                </a>
                                <ul class="dropdown-menu" role="menu">
                                </ul>
                            </li>
                            <li class="dropdown  link">
                                <a href="https://jullietcongelados.com.br/sopas" class="dropdown-toggle flex-icon"
                                    data-toggle="linkdropdown">
                                    <picture>

                                        <img class="icon-menu"
                                            src="https://jullietcongelados.com.br/lojas/juliettecongelados/conteudo/uploads/icon-sopas-612fc086f1d57.png"
                                            alt="Sopas">
                                    </picture> Sopas<i class="fa fa-caret-right"></i>
                                </a>
                                <ul class="dropdown-menu" role="menu">
                                </ul>
                            </li>
                            <li class="dropdown  link">
                                <a href="https://jullietcongelados.com.br/massas-e-molhos"
                                    class="dropdown-toggle flex-icon" data-toggle="linkdropdown">
                                    <picture>

                                        <img class="icon-menu"
                                            src="https://jullietcongelados.com.br/lojas/juliettecongelados/conteudo/uploads/icon-massas-molhos-612fc053b1205.png"
                                            alt="Massas">
                                    </picture> Massas<i class="fa fa-caret-right"></i>
                                </a>
                                <ul class="dropdown-menu" role="menu">
                                </ul>
                            </li>
                            <li class="dropdown  link">
                                <a href="https://jullietcongelados.com.br/variados" class="dropdown-toggle flex-icon"
                                    data-toggle="linkdropdown">
                                    <picture>
                                        <source
                                            srcset="https://jullietcongelados.com.br/lojas/juliettecongelados/conteudo/uploads/icon-sobremesas-sorvetes-614b7980b9d20.webp"
                                            type="image/webp" />
                                        <img class="icon-menu"
                                            src="https://jullietcongelados.com.br/lojas/juliettecongelados/conteudo/uploads/icon-sobremesas-sorvetes-614b7980b9d20.png"
                                            alt="Sobremesas">
                                    </picture> Sobremesas<i class="fa fa-caret-right"></i>
                                </a>
                                <ul class="dropdown-menu" role="menu">
                                </ul>
                            </li>
                        </ul>
                    </div>

                    <!-- Banner lateral -->
                    <div class="box-sidebar css-desktop">
                        <div class="box-banner">
                        </div>
                    </div>

                </aside>
            </div>
        </div>

        <section class="indique-amigo-header">
            <i class="fa fa-thumbs-o-up"></i>
            <a href="https://jullietcongelados.com.br/login-indique-um-amigo" class="indique-amigo-header-item">Indique
                amigos e ganhe créditos em nossa loja!</a>
        </section>

        <header class="header">
            <nav class="navbar navbar-default">
                <div class="box-top css-desktop">
                    <div class="container">
                        <div class="row">
                            <div class="flex">
                                <div class="col-xs-5">
                                    <div class="flex">
                                        <div class="rede-social-topo">
                                            <a href="https://www.facebook.com/tesalva" target="_blank" rel="noopener"><i
                                                    class="fa fa-facebook"></i><span
                                                    class="sr-only">Facebook</span></a><a
                                                href="https://www.instagram.com/donasolajuda/" target="_blank"
                                                rel="noopener"><i class="fa fa-instagram"></i><span
                                                    class="sr-only">Instagram</span></a>
                                            <a class="desktop"
                                                href="https://api.whatsapp.com/send?phone=5521966752302&text=Olá!"
                                                target="_blank" rel="noopener">
                                                <i class="fa fa-whatsapp" data-placement="bottom"
                                                    data-original-title="WhatsApp (11) 97702-0353"
                                                    data-toggle="tooltip"><span class="sr-only">WhatsApp</span></i>
                                            </a>
                                            <a class="mobile"
                                                href="https://api.whatsapp.com/send?phone=5521966752302&text=Olá!"
                                                target="_blank" rel="noopener">
                                                <i class="fa fa-whatsapp"><span class="sr-only">WhatsApp</span></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-7">
                                    <div class="flex-end">
                                        <a href="javascript:" onclick="verificaEntregaCep()"
                                            class="btn-consultar-entrega js-entrega-open">
                                            <div class="flex">
                                                <i class="fa fa-map-marker"></i>
                                                <div class="js-entrega-msg" style="display:block">
                                                    <h5>Será que entrega?</h5>
                                                </div>
                                                <div class="js-entrega-msg-ok" style="display:none">
                                                    <h6>Entregar em</h6>
                                                    <h6><span class="js-entrega-msg-endereco"></span></h6>
                                                </div>
                                            </div>
                                        </a>
                                        <div class="busca mod-full">
                                            <a href="javascript:" class="btn-abrir js-abrir-busca"><span
                                                    class="sr-only">Abrir busca</span></a>
                                        </div>
                                        <div class="carrinho-topo hidden-xs">
                                            <ul class="nav nav-pills">

                                                <li id="cesta-topo1" class="dropdown valor-compra hidden-xs">
                                                    <div class="box-carrinho-mobile clickable"
                                                        @click="openCarrinhoRight">

                                                        <router-link to="/shopping-cart" class="btn-open">
                                                            <span class="badge js-cesta-total-produtos-notext">
                                                                {{ getQuantityFromCart }} </span>
                                                        </router-link>
                                                    </div>
                                                </li>



                                                <li class="dropdown entrar dropdown-logado" v-if="isLoggedIn">
                                                    <a href="#!" class="dropdown-toggle"><i
                                                            class="icon-entrar fa fa-user"></i>Olá, {{ user.displayName
                                                        }}</a>
                                                    <ul class="dropdown-menu">
                                                        <li class="menu-meus-pedidos">
                                                            <router-link to="/orders"><i
                                                                    class="fa fa-shopping-basket hidden-xs hidden-sm"></i>Meus
                                                                pedidos</router-link>
                                                        </li>

                                                        <!--<li class="menu-minhas-assinaturas">
                                                                <a href="https://jullietcongelados.com.br/login-assinaturas"><i class="fa fa-file-text hidden-xs hidden-sm"></i>Minhas assinaturas</a>
                                                            </li>-->
                                                        <!-- 
                                                        <li class="menu-meu-cadastro">
                                                            <router-link to="/registed"><i
                                                                    class="fa fa-user hidden-xs hidden-sm"></i>Meu
                                                                cadastro</router-link>
                                                        </li> -->

                                                        <li class="menu-favoritos">
                                                            <router-link to="/favorites"><i
                                                                    class="fa fa-heart hidden-xs hidden-sm"></i>Favoritos
                                                            </router-link>
                                                        </li>

                                                        <li class="menu-meus-creditos">
                                                            <router-link to="/credit"><i
                                                                    class="fa fa-money hidden-xs hidden-sm"></i>Meus
                                                                créditos</router-link>
                                                        </li>

                                                        <li class="menu-indique-um-amigo">
                                                            <router-link to="/refer-friend"><i
                                                                    class="fa fa-user-plus hidden-xs hidden-sm"></i>Indique
                                                                um amigo</router-link>
                                                        </li>

                                                        <!-- <li class="menu-alterar-senha">
                                                            <router-link to="password-change"><i
                                                                    class="fa fa-lock hidden-xs hidden-sm"></i>Alterar
                                                                senha</router-link>
                                                        </li> -->

                                                        <li class="menu-sair">
                                                            <router-link to="/logout"><i
                                                                    class="fa fa-sign-out hidden-xs hidden-sm"></i>Sair</router-link>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li class="entrar" v-else>
                                                    <router-link to="/login" class="entrar">
                                                        <i class="icon-entrar fa fa-share"></i>
                                                        Login
                                                    </router-link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="js-fixed">
                    <div class="container">
                        <div class="row">
                            <div class="flex no-flex-sm header-center">
                                <div class="col-xs-12 col-md-3">
                                    <div class="navbar-header">
                                        <div class="flex-space">
                                            <div class="logo">
                                                <router-link to="/">
                                                    <picture>
                                                        <img class="img-responsive" src="/assets/img/logo-dsa.png"
                                                            alt="Dona Sol Ajuda Comidas Congeladas" title="">
                                                    </picture>
                                                </router-link>

                                            </div>
                                            <div class="box-right css-mobile">
                                                <a href="javascript:" onclick="verificaEntregaCep()"
                                                    class="btn-consultar-entrega js-entrega-open">
                                                    <div class="flex">
                                                        <i class="fa fa-map-marker"></i>
                                                        <div class="js-entrega-msg" style="display:block">
                                                            <h5>Será que entrega?</h5>
                                                        </div>
                                                        <div class="js-entrega-msg-ok" style="display:none">
                                                            <h6>Entregar em</h6>
                                                            <h6><span class="js-entrega-msg-endereco"></span></h6>
                                                        </div>
                                                    </div>
                                                </a>
                                                <div class="flex-end">
                                                    <a href="javascript:"
                                                        class="item-busca btn-abrir js-abrir-busca"><span
                                                            class="sr-only">Busca</span></a>
                                                    <div class="dropdown item-logado" v-if="isLoggedIn">
                                                        <a href="#" class="item-login dropdown-toggle"
                                                            data-toggle="dropdown">
                                                            <span class="sr-only">Logado</span>
                                                        </a>
                                                        <ul class="dropdown-menu">
                                                            <li><span>Olá, {{ user.displayName }}</span></li>
                                                            <li class="menu-meus-pedidos">
                                                                <router-link to="/orders"><i
                                                                        class="fa fa-shopping-basket hidden-xs hidden-sm"></i>Meus
                                                                    pedidos</router-link>
                                                            </li>

                                                            <!--<li class="menu-minhas-assinaturas">
                                                                <a href="https://jullietcongelados.com.br/login-assinaturas"><i class="fa fa-file-text hidden-xs hidden-sm"></i>Minhas assinaturas</a>
                                                            </li>-->

                                                            <!-- <li class="menu-meu-cadastro">
                                                                <router-link to="/registed"><i
                                                                        class="fa fa-user hidden-xs hidden-sm"></i>Meu
                                                                    cadastro</router-link>
                                                            </li> -->

                                                            <li class="menu-favoritos">
                                                                <router-link to="/favorites"><i
                                                                        class="fa fa-heart hidden-xs hidden-sm"></i>Favoritos
                                                                </router-link>
                                                            </li>

                                                            <li class="menu-meus-creditos">
                                                                <router-link to="/credit"><i
                                                                        class="fa fa-money hidden-xs hidden-sm"></i>Meus
                                                                    créditos</router-link>
                                                            </li>

                                                            <li class="menu-indique-um-amigo">
                                                                <router-link to="/refer-friend"><i
                                                                        class="fa fa-user-plus hidden-xs hidden-sm"></i>Indique
                                                                    um amigo</router-link>
                                                            </li>

                                                            <!-- <li class="menu-alterar-senha">
                                                                <router-link to="password-change"><i
                                                                        class="fa fa-lock hidden-xs hidden-sm"></i>Alterar
                                                                    senha</router-link>
                                                            </li> -->

                                                            <li class="menu-sair">
                                                                <router-link to="/logout"><i
                                                                        class="fa fa-sign-out hidden-xs hidden-sm"></i>Sair</router-link>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <router-link :to="{ path: '/login' }" class="item-login"
                                                        v-else><span class="sr-only">Entrar</span></router-link>



                                                    <div class="box-carrinho-mobile">
                                                        <span @click="openCarrinhoRight" class="btn-open">
                                                            <span class="badge js-cesta-total-produtos-notext">
                                                                {{ getQuantityFromCart }} </span>
                                                        </span>
                                                    </div>
                                                    <button type="button" class="navbar-toggle"
                                                        data-toggle="no-collapse" data-target="#menu-topo">
                                                        <span class="icon-bar"></span>
                                                        <span class="icon-bar"></span>
                                                        <span class="icon-bar"></span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-9 col-menu-full">
                                    <div class="menu-principal css-desktop">
                                        <ul class="nav navbar-nav">
                                            <li class="menu-contato">
                                                <a href="https://api.whatsapp.com/send?phone=551197702-0353&text=Olá!"
                                                    target="">Contato</a>
                                            </li>
                                            <li class="menu-quero-revender">
                                                <a href="https://api.whatsapp.com/send?phone=5511976662412"
                                                    target="_blank">Quero Revender</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </header>

        <main>
            <slot></slot>
        </main>

        <footer class="footer">
            <div class="container">
                <div class="row">

                    <div class="col-xs-12 col-sm-3 col-md-2 col-lg-3 boxHeight hidden-xs">
                        <div class="logo">
                            <router-link to="/">
                                <picture>
                                    <img class="img-responsive" src="/assets/img/logo-dsa.png"
                                        alt="Dona Sol Ajuda Comidas Congeladas" title="">
                                </picture>
                            </router-link>
                        </div>
                    </div>

                    <div class="col-xs-12 col-sm-9 col-md-2 boxHeight">
                        <div class="forma-pagamento">
                            <h5>Formas de pagamento</h5>
                            <div class="group-icon">
                                <i class="oct icon-pix"></i>
                            </div>
                        </div>
                    </div>

                    <div class="col-xs-12 col-sm-4 col-md-3 boxHeight">
                        <div class="menu-footer">
                            <h5>Institucional</h5>
                            <ul class="list-unstyled footer-links">
                                <li class=""><a target=""
                                        href="https://jullietcongelados.com.br/perguntas-frequentes">Perguntas
                                        Frequentes</a></li>
                                <li class=""><a target=""
                                        href="https://jullietcongelados.com.br/politicas-de-privacidade">Política de
                                        Privacidade</a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-xs-12 col-sm-4 col-md-3 col-lg-2 boxHeight">
                        <div class="menu-contato">
                            <h5>Contato</h5>
                            <ul class="list-unstyled footer-links">
                                <li><a href="https://api.whatsapp.com/send?phone=21966752302&amp;text=Ol%C3%A1!">(21)
                                        9 9667-52302</a></li>
                                <li>test@test.com
                                </li>
                            </ul>

                            <div class="rede-social-topo">
                                <a href="https://www.facebook.com/tesalva" target="_blank" rel="noopener"><i
                                        class="fa fa-facebook"></i><span class="sr-only">Facebook</span></a><a
                                    href="https://www.instagram.com/jullietcongelados/" target="_blank"
                                    rel="noopener"><i class="fa fa-instagram"></i><span
                                        class="sr-only">Instagram</span></a>
                                <a class="desktop" href="https://api.whatsapp.com/send?phone=5521966752302&text=Olá!"
                                    target="_blank" rel="noopener">
                                    <i class="fa fa-whatsapp" data-placement="bottom"
                                        data-original-title="WhatsApp (11) 97702-0353" data-toggle="tooltip"><span
                                            class="sr-only">WhatsApp</span></i>
                                </a>
                                <a class="mobile" href="https://api.whatsapp.com/send?phone=5521966752302&text=Olá!"
                                    target="_blank" rel="noopener">
                                    <i class="fa fa-whatsapp"><span class="sr-only">WhatsApp</span></i>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="col-xs-12 col-sm-4 col-md-2 boxHeight">
                        <div class="site-seguro">
                            <h5>Site seguro</h5>
                            <a
                                href="https://transparencyreport.google.com/safe-browsing/search?url=https://jullietcongelados.com.br/">
                                <img class="img-responsive" src="/assets/img/google-safe-browsing.svg"
                                    alt="Você está em uma navegação segura." title="Google Safe Browsing"></a>
                            <img class="img-responsive" src="/assets/img/selo-rapidssl.svg"
                                alt="Você está em um site seguro." title="Site Seguro">
                        </div>
                    </div>

                </div>
            </div>

            <div class="copyright">
                <div class="container">
                    <div class="row">
                        <div class="col-xs-12">
                            <div class="flex-center">
                                <p>&copy; 2021-2024 Dona Sol Ajuda&nbsp;Comidas Congeladas - Todos os direitos
                                    reservados
                                </p>

                                <a class="logo-by" href="https://www.bigfishdigital.com.br" target="_blank"
                                    rel="noopener">
                                    <img src="/assets/img/logo-dsa.png"
                                        alt="Agência de comunicação especializada na criação de sites, e-commerce, logotipos, impressos e SEO."
                                        title="É Xis ou Agá? - Agência de Comunicação." width="35" height="25">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>


    </div>
</template>
<script>
import CartComponent from '@/components/CartComponent.vue';
import { mapGetters, mapActions, mapState } from 'vuex';
export default {
    components: {
        CartComponent
    },
    methods: {
        ...mapActions('cart', ['openCarrinhoRight'])
    },
    computed: {
        ...mapGetters('cart', ['getQuantityFromCart']),
        ...mapState('auth_google', ['isLoggedIn', 'user'])
    },
    // mounted() {
    //     // Header fixo
    //     document.querySelectorAll('.js-fixed').forEach(element => {
    //         new IntersectionObserver(entries => {
    //             entries.forEach(entry => {
    //                 if (entry.isIntersecting) {
    //                     // Lógica quando o elemento está visível
    //                 } else {
    //                     // Lógica quando o elemento está fora de vista
    //                 }
    //             });
    //         }).observe(element);
    //     });

    //     // Toggle Menu Mobile
    //     const navbarToggle = document.querySelector('.navbar-toggle');
    //     const navbarClose = document.querySelector('.navbar-toggle-close');
    //     const navCollapse = document.querySelector('.nav-collapse');

    //     navbarToggle.addEventListener('click', () => {
    //         document.documentElement.classList.add('js-overlay-html', 'js-overlay-toggle');
    //         navCollapse.classList.add('show');
    //     });

    //     navbarClose.addEventListener('click', () => {
    //         document.documentElement.classList.remove('js-overlay-html', 'js-overlay-toggle');
    //         navCollapse.classList.remove('show');
    //     });

    //     if (window.innerWidth < 991) {
    //         // Touch dropdown mobile
    //         const dropdownToggle = document.querySelectorAll('.dropdown-toggle');
    //         dropdownToggle.forEach(element => {
    //             element.addEventListener('click', function (e) {
    //                 setTimeout(() => {
    //                     if ('ontouchstart' in document.documentElement) {
    //                         element.nextElementSibling.removeEventListener('click', removeDropdownBackdrop);
    //                         element.nextElementSibling.remove();
    //                     }
    //                 }, 0);
    //             });
    //         });

    //         document.querySelectorAll('[data-toggle="dropdown"]').forEach(element => {
    //             element.parentNode.classList.remove('open');
    //         });

    //         // Header fixo mobile
    //         new IntersectionObserver(entries => {
    //             entries.forEach(entry => {
    //                 if (entry.isIntersecting) {
    //                     // Lógica quando o elemento está visível
    //                 } else {
    //                     // Lógica quando o elemento está fora de vista
    //                 }
    //             });
    //         }, { rootMargin: '1px' }).observe(document.querySelector('.header'));
    //     } else {
    //         // Não fechar o menu ao clicar fora desktop
    //         document.addEventListener('hide.bs.dropdown', e => {
    //             const activeElement = e.currentTarget.activeElement;
    //             if (activeElement.classList.contains('dropdown-toggle')) {
    //                 activeElement.parentNode.classList.remove('open');
    //                 return true;
    //             }
    //             return false;
    //         });
    //     }

    //     // Scroll quando clicar no radio
    //     document.querySelectorAll('.box-tipo-pagamento label').forEach(label => {
    //         label.addEventListener('click', () => {
    //             const targetElement = document.querySelector(label.dataset.href);
    //             window.scrollTo({
    //                 top: targetElement.offsetTop,
    //                 behavior: 'smooth'
    //             });
    //         });
    //     });

    //     // Click Menu Principal Full
    //     document.querySelectorAll('.menu-principal .submenu-full').forEach(submenu => {
    //         submenu.addEventListener('click', e => {
    //             e.stopPropagation();
    //             submenu.classList.toggle('active');
    //             Array.from(submenu.parentNode.children).forEach(sibling => {
    //                 if (sibling !== submenu) {
    //                     sibling.classList.remove('active');
    //                 }
    //             });
    //             // Lógica adicional aqui se necessário
    //         });
    //     });

    //     document.addEventListener('click', () => {
    //         document.querySelectorAll('.menu-principal .submenu-full').forEach(submenu => {
    //             submenu.classList.remove('active');
    //         });
    //     });

    //     // Hover Menu Principal Default
    //     document.querySelectorAll('.menu-principal .submenu-default').forEach(submenu => {
    //         submenu.addEventListener('mouseenter', () => {
    //             submenu.classList.add('active');
    //         });

    //         submenu.addEventListener('mouseleave', () => {
    //             submenu.classList.remove('active');
    //         });
    //     });

    //     document.querySelectorAll('.menu-principal .submenu-default').forEach(submenu => {
    //         submenu.addEventListener('mouseenter', () => {
    //             document.querySelectorAll('.menu-principal .submenu-full').forEach(submenu => {
    //                 submenu.classList.remove('active');
    //             });
    //         });
    //     });

    //     // Slider Home v2
    //     document.querySelectorAll('.js-banner-principal').forEach(banner => {
    //         const owlCarousel = new OwlCarousel(banner, {
    //             animateOut: 'fadeOut',
    //             animateIn: 'fadeIn',
    //             items: 1,
    //             smartSpeed: 800,
    //             autoplay: true,
    //             autoplayTimeout: 5000,
    //             nav: true,
    //             dots: true,
    //             loop: true,
    //             autoHeight: false,
    //             lazyLoad: true,
    //             video: true,
    //             mouseDrag: false,
    //             margin: 0,
    //             stagePadding: 0,
    //             navText: [
    //                 '<div class="nav-prev"><i class="fa fa-chevron-left"></i></div>',
    //                 '<div class="nav-next"><i class="fa fa-chevron-right"></i></div>'
    //             ]
    //         });

    //         // Atualizar o carrossel quando as imagens forem carregadas
    //         banner.addEventListener('loaded.owl.lazy', () => {
    //             owlCarousel.refresh();
    //         });
    //     });

    //     // Slider Blog
    //     document.querySelectorAll('.js-banner-blog').forEach(banner => {
    //         const owlCarousel = new OwlCarousel(banner, {
    //             smartSpeed: 800,
    //             autoplay: true,
    //             autoplayTimeout: 6000,
    //             nav: false,
    //             loop: false,
    //             autoHeight: true,
    //             lazyLoad: true,
    //             margin: 0,
    //             stagePadding: 0,
    //             autoHeight: true,
    //             autoHeightClass: 'owl-height',
    //             navText: [
    //                 '<div class="nav-prev"><i class="fa fa-chevron-left"></i></div>',
    //                 '<div class="nav-next"><i class="fa fa-chevron-right"></i></div>'
    //             ],
    //             items: 1
    //         });

    //         // Atualizar o carrossel quando as imagens forem carregadas
    //         banner.addEventListener('loaded.owl.lazy', () => {
    //             owlCarousel.refresh();
    //         });
    //     });

    //     // Carrossel Vitrine: Erro Instagram Home iOS
    //     setTimeout(() => {
    //         document.querySelectorAll('.js-carousel-new').forEach(carousel => {
    //             carousel.dispatchEvent(new Event('refresh.owl.carousel'));
    //         });
    //         document.querySelectorAll('.js-box-produto').forEach(boxProduto => {
    //             boxProduto.style.height = 'auto';
    //         });
    //         console.log('Recarregou VITRINE');
    //     }, 1000);

    //     // Carrossel Vitrine New
    //     document.querySelectorAll('.js-carousel-new').forEach((carousel, i) => {
    //         const delayCarousel = i * 100;
    //         setTimeout(() => {
    //             new OwlCarousel(carousel, {
    //                 smartSpeed: 800,
    //                 autoplay: false,
    //                 autoplayTimeout: 4000,
    //                 nav: true,
    //                 dots: false,
    //                 loop: false,
    //                 autoHeight: false,
    //                 margin: 30,
    //                 stagePadding: 0,
    //                 navText: [
    //                     '<div class="nav-prev"><i class="fa fa-chevron-left"></i></div>',
    //                     '<div class="nav-next"><i class="fa fa-chevron-right"></i></div>'
    //                 ],
    //                 responsive: {
    //                     0: {
    //                         items: 1,
    //                         margin: -50,
    //                         nav: false,
    //                         loop: false
    //                     },
    //                     421: {
    //                         items: 2,
    //                         margin: 15
    //                     },
    //                     767: {
    //                         margin: 15,
    //                         items: 3
    //                     },
    //                     1200: {
    //                         items: 3
    //                     }
    //                 }
    //             });
    //         }, delayCarousel);
    //     });

    //     // Zoom galeria imagem produto
    //     const galeriaProdutos = document.getElementById('galeriaProdutos');
    //     if (galeriaProdutos) {
    //         const elevateZoom = new ElevateZoom(galeriaProdutos, {
    //             gallery: 'galeria_01',
    //             galleryActiveClass: 'active',
    //             imageCrossfade: true,
    //             loadingIcon: '/assets/img/ico_loading.svg',
    //             cursor: 'crosshair',
    //             zoomWindowFadeIn: 500,
    //             zoomWindowFadeOut: 300,
    //             lensFadeIn: 800,
    //             zoomWindowPosition: 1,
    //             easing: true,
    //             zoomWindowWidth: 500,
    //             zoomWindowHeight: 500
    //         });

    //         galeriaProdutos.addEventListener('touchstart', () => {
    //             galeriaProdutos.removeEventListener('touchmove', removeTouchMove);
    //         });

    //         galeriaProdutos.addEventListener('click', e => {
    //             const ez = elevateZoom.getGalleryList();
    //             $.fancybox(ez);
    //             e.preventDefault();
    //         });
    //     }

    //     // Modal novidades home - automático
    //     window.addEventListener('load', () => {
    //         const modal = getCookie('modal-news');

    //         // LGPD
    //     });
    // }

}
</script>

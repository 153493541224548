<template>
    <div class="dsac-tab-pane dsac-fade"
        :class="{ 'dsac-active dsac-show': activeStepChoiceTypeComponent === 'StepChoiceTypeDelivery' }"
        id="StepChoiceTypeDelivery">
        <div class="dsac-card-white">
            <h4>Endereço de entrega <span class="dsac-text-danger">*</span></h4>
            <p class="dsac-small dsac-mt-2" id="js-endereco" v-if="enderecoSelecionado">{{
            pegarEnderecoSelecionado.endereco }}, {{
            pegarEnderecoSelecionado.numero }} - {{ pegarEnderecoSelecionado.bairro }}, {{
            pegarEnderecoSelecionado.cidade }}- {{ pegarEnderecoSelecionado.estado }}</p>
            <button data-bs-toggle="modal" id="btn-endereco" data-bs-target="#modalEndereco" @click="abrirModalEndereco"
                class="dsac-btn dsac-btn-outline-primary dsac-w-xs-100 dsac-mt-3" role="button">
                <i class="dsac-fa dsac-la-home dsac-me-1"></i>Selecione o endereço
            </button>
            <div class="dsac-d-block">
                <hr class="dsac-mt-4 dsac-mb-3">
                <h4 class="dsac-mb-3">Agora selecione uma opção de envio <span class="dsac-text-danger">*</span></h4>
                <div id="js-lista-fretes" class="dsac-row"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
export default {
    computed: {
        ...mapState('checkout', ['activeStepChoiceTypeComponent', 'enderecoSelecionado']),
        ...mapGetters('checkout', ['pegarEnderecoSelecionado'])
    },
    methods: {
        ...mapActions('checkout', ['abrirModalEndereco', 'abrirModalEnderecoInt', 'setActiveStepComponent'])
    }
};
</script>

import { initializeApp } from 'firebase/app';
import {
    getAuth,
    createUserWithEmailAndPassword,
    updatePassword,
    signInWithEmailAndPassword,
    signOut,
    GoogleAuthProvider,
    signInWithPopup
} from "firebase/auth";
import { getDatabase, ref, set, get, push, remove } from 'firebase/database';
import { query, equalTo } from 'firebase/database';
import firebaseConfig from './firebaseConfig.js';

// Inicialize o Firebase com as configurações
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const database = getDatabase(app);

export const dbRef = ref;
export const dbSet = set;
export const dbGet = get;
export const dbPush = push;
export const dbRemove = remove;
export const dbQuery = query;
export const dbEqualTo = equalTo;

export const authSignOut = signOut;
export const authUpdatePassword = updatePassword;
export const authSignInWithEmailAndPassword = signInWithEmailAndPassword;
export const authCreateUserWithEmailAndPassword = createUserWithEmailAndPassword;

const googleProvider = new GoogleAuthProvider();

export const authSignInWithGoogle = () => {
    return signInWithPopup(auth, googleProvider);
};
export const authSignOutGoogle = async () => {
    return await signOut(auth);
};

/* 

// Função para criar um UID do Firebase
const generateFirebaseUid = () => {
    const tempRef = dbPush(ref(database)); // Cria uma referência temporária com push()
    const uid = tempRef.key; // Obtém a chave gerada pela referência temporária
    return uid;
};

// Função para adicionar um produto ao Firebase Realtime Database
const addProductToDatabase = async (product) => {
    try {
        const uid = generateFirebaseUid();
        product.productId = uid; // Modifica o ID do produto
        const productRef = dbRef(database, `shops/1/products/${uid}`);
        await dbSet(productRef, product);
        console.log(`Produto adicionado com sucesso ao Firebase: ${uid}`);
    } catch (error) {
        console.error('Erro ao adicionar produto ao Firebase:', error);
        throw error;
    }
};// Função para adicionar um produto ao Firebase Realtime Database
const addSealToDatabase = async (seal) => {
    try {
        const uid = generateFirebaseUid();
        seal.sealId = uid; // Modifica o ID do produto
        const sealRef = dbRef(database, `shops/1/seals/${uid}`);
        await dbSet(sealRef, seal);
        console.log(`Produto adicionado com sucesso ao Firebase: ${uid}`);
    } catch (error) {
        console.error('Erro ao adicionar produto ao Firebase:', error);
        throw error;
    }
};// Função para adicionar um produto ao Firebase Realtime Database
const addProductsSameToDatabase = async (product) => {
    try {
        const uid = generateFirebaseUid();
        delete product.id
        product.productSameId = uid; // Modifica o ID do produto
        const productRef = dbRef(database, `shops/1/productsSame/${uid}`);
        await dbSet(productRef, product);
        console.log(`Produto adicionado com sucesso ao Firebase: ${uid}`);
    } catch (error) {
        console.error('Erro ao adicionar produto ao Firebase:', error);
        throw error;
    }
};// Função para adicionar um produto ao Firebase Realtime Database
const addCategoryToDatabase = async (category) => {
    try {
        const uid = generateFirebaseUid();
        delete category.id
        category.categoryId = uid; // Modifica o ID do produto
        const categoryRef = dbRef(database, `shops/1/categories/${uid}`);
        await dbSet(categoryRef, category);
        console.log(`Produto adicionado com sucesso ao Firebase: ${uid}`);
    } catch (error) {
        console.error('Erro ao adicionar produto ao Firebase:', error);
        throw error;
    }
};

const products = [
    {
        "productId": 110,
        "isFavorite": true,
        "link": "/details/123456",
        "lazySrcset": "/assets/img/logo-dsa.png",
        "dataSrcset": "/assets/img/logo-dsa.png",
        "lazyImg": "/assets/img/logo-dsa.png",
        "dataSrc": "/assets/img/logo-dsa.png",
        "name": "Quibe assado com purê de abóbora, arroz e feijão - 300g",
        "selected": false,
        "price": {
            "value": 14.95,
            "discount": {
                "active": true,
                "value": 0.2
            }
        },
        "text": "300g",
        "tag": {
            "active": true,
            "image": "/assets/img/conteudo/uploads/etiqueta-promo.jpg",
            "type": "new",
            "alt": "Promoção",
            "title": ""
        },
        "seals": [
            {
                "sealId": 1
            },
            {
                "sealId": 2
            }
        ]
    },
    {
        "productId": 1100,
        "isFavorite": true,
        "link": "/details/123456",
        "lazySrcset": "/assets/img/logo-dsa.png",
        "dataSrcset": "/assets/img/logo-dsa.png",
        "lazyImg": "/assets/img/logo-dsa.png",
        "dataSrc": "/assets/img/logo-dsa.png",
        "name": "Quibe assado com purê de abóbora, arroz e feijão - 500g",
        "selected": true,
        "price": {
            "value": 30,
            "discount": {
                "active": true,
                "value": 0.5
            }
        },
        "text": "500g",
        "tag": {
            "active": true,
            "image": "/assets/img/conteudo/uploads/etiqueta-promo.jpg",
            "type": "new",
            "alt": "Promoção",
            "title": ""
        },
        "seals": [
            {
                "sealId": 1
            },
            {
                "sealId": 2
            }
        ]
    },
    {
        "productId": 2001,
        "isFavorite": true,
        "link": "/details/123474",
        "lazySrcset": "/assets/img/logo-dsa.png",
        "dataSrcset": "/assets/img/logo-dsa.png",
        "lazyImg": "/assets/img/logo-dsa.png",
        "dataSrc": "/assets/img/logo-dsa.png",
        "name": "Strogonoff de Carne - 300g",
        "selected": false,
        "price": {
            "value": 17.95,
            "discount": {
                "active": true,
                "value": 0.2
            }
        },
        "text": "300g",
        "tag": {
            "active": true,
            "image": "/assets/img/conteudo/uploads/etiqueta-promo.jpg",
            "type": "new",
            "alt": "Promoção",
            "title": ""
        },
        "seals": [
            {
                "sealId": 1
            },
            {
                "sealId": 2
            }
        ]
    },
    {
        "productId": 2002,
        "isFavorite": true,
        "link": "/details/123474",
        "lazySrcset": "/assets/img/logo-dsa.png",
        "dataSrcset": "/assets/img/logo-dsa.png",
        "lazyImg": "/assets/img/logo-dsa.png",
        "dataSrc": "/assets/img/logo-dsa.png",
        "name": "Strogonoff de Carne - 500g",
        "selected": true,
        "price": {
            "value": 32,
            "discount": {
                "active": true,
                "value": 0.25
            }
        },
        "text": "500g",
        "tag": {
            "active": true,
            "image": "/assets/img/conteudo/uploads/etiqueta-promo.jpg",
            "type": "new",
            "alt": "Promoção",
            "title": ""
        },
        "seals": [
            {
                "sealId": 1
            },
            {
                "sealId": 2
            }
        ]
    },
    {
        "productId": 2011,
        "isFavorite": true,
        "link": "/details/123475",
        "lazySrcset": "/assets/img/logo-dsa.png",
        "dataSrcset": "/assets/img/logo-dsa.png",
        "lazyImg": "/assets/img/logo-dsa.png",
        "dataSrc": "/assets/img/logo-dsa.png",
        "name": "Feijoada Completa - 300g",
        "selected": false,
        "price": {
            "value": 17.95,
            "discount": {
                "active": true,
                "value": 0.2
            }
        },
        "text": "300g",
        "tag": {
            "active": true,
            "image": "/assets/img/conteudo/uploads/etiqueta-promo.jpg",
            "type": "new",
            "alt": "Promoção",
            "title": ""
        },
        "seals": [
            {
                "sealId": 1
            },
            {
                "sealId": 2
            }
        ]
    },
    {
        "productId": 2012,
        "isFavorite": true,
        "link": "/details/123475",
        "lazySrcset": "/assets/img/logo-dsa.png",
        "dataSrcset": "/assets/img/logo-dsa.png",
        "lazyImg": "/assets/img/logo-dsa.png",
        "dataSrc": "/assets/img/logo-dsa.png",
        "name": "Feijoada Completa - 500g",
        "selected": true,
        "price": {
            "value": 32,
            "discount": {
                "active": true,
                "value": 0.25
            }
        },
        "text": "500g",
        "tag": {
            "active": true,
            "image": "/assets/img/conteudo/uploads/etiqueta-promo.jpg",
            "type": "new",
            "alt": "Promoção",
            "title": ""
        },
        "seals": [
            {
                "sealId": 1
            },
            {
                "sealId": 2
            }
        ]
    },
    {
        "productId": 2021,
        "isFavorite": true,
        "link": "/details/123476",
        "lazySrcset": "/assets/img/logo-dsa.png",
        "dataSrcset": "/assets/img/logo-dsa.png",
        "lazyImg": "/assets/img/logo-dsa.png",
        "dataSrc": "/assets/img/logo-dsa.png",
        "name": "Lasanha de Frango - 300g",
        "selected": false,
        "price": {
            "value": 15.95,
            "discount": {
                "active": true,
                "value": 0.15
            }
        },
        "text": "300g",
        "tag": {
            "active": true,
            "image": "/assets/img/conteudo/uploads/etiqueta-promo.jpg",
            "type": "new",
            "alt": "Promoção",
            "title": ""
        },
        "seals": [
            {
                "sealId": 1
            },
            {
                "sealId": 2
            }
        ]
    },
    {
        "productId": 2022,
        "isFavorite": true,
        "link": "/details/123476",
        "lazySrcset": "/assets/img/logo-dsa.png",
        "dataSrcset": "/assets/img/logo-dsa.png",
        "lazyImg": "/assets/img/logo-dsa.png",
        "dataSrc": "/assets/img/logo-dsa.png",
        "name": "Lasanha de Frango - 500g",
        "selected": true,
        "price": {
            "value": 28,
            "discount": {
                "active": true,
                "value": 0.2
            }
        },
        "text": "500g",
        "tag": {
            "active": true,
            "image": "/assets/img/conteudo/uploads/etiqueta-promo.jpg",
            "type": "new",
            "alt": "Promoção",
            "title": ""
        },
        "seals": []
    },
    {
        "productId": 2031,
        "isFavorite": true,
        "link": "/details/123477",
        "lazySrcset": "/assets/img/logo-dsa.png",
        "dataSrcset": "/assets/img/logo-dsa.png",
        "lazyImg": "/assets/img/logo-dsa.png",
        "dataSrc": "/assets/img/logo-dsa.png",
        "name": "Risoto de Camarão - 300g",
        "selected": false,
        "price": {
            "value": 19.95,
            "discount": {
                "active": true,
                "value": 0.1
            }
        },
        "text": "300g",
        "tag": {
            "active": true,
            "image": "/assets/img/conteudo/uploads/etiqueta-promo.jpg",
            "type": "new",
            "alt": "Promoção",
            "title": ""
        },
        "seals": []
    },
    {
        "productId": 2032,
        "isFavorite": true,
        "link": "/details/123477",
        "lazySrcset": "/assets/img/logo-dsa.png",
        "dataSrcset": "/assets/img/logo-dsa.png",
        "lazyImg": "/assets/img/logo-dsa.png",
        "dataSrc": "/assets/img/logo-dsa.png",
        "name": "Risoto de Camarão - 500g",
        "selected": true,
        "price": {
            "value": 34,
            "discount": {
                "active": true,
                "value": 0.3
            }
        },
        "text": "500g",
        "tag": {
            "active": true,
            "image": "/assets/img/conteudo/uploads/etiqueta-promo.jpg",
            "type": "new",
            "alt": "Promoção",
            "title": ""
        },
        "seals": []
    },
    {
        "productId": 2041,
        "isFavorite": true,
        "link": "/details/123478",
        "lazySrcset": "/assets/img/logo-dsa.png",
        "dataSrcset": "/assets/img/logo-dsa.png",
        "lazyImg": "/assets/img/logo-dsa.png",
        "dataSrc": "/assets/img/logo-dsa.png",
        "name": "Frango à Parmegiana - 300g",
        "selected": false,
        "price": {
            "value": 16.95,
            "discount": {
                "active": true,
                "value": 0.15
            }
        },
        "text": "300g",
        "tag": {
            "active": true,
            "image": "/assets/img/conteudo/uploads/etiqueta-promo.jpg",
            "type": "new",
            "alt": "Promoção",
            "title": ""
        },
        "seals": []
    },
    {
        "productId": 2042,
        "isFavorite": true,
        "link": "/details/123478",
        "lazySrcset": "/assets/img/logo-dsa.png",
        "dataSrcset": "/assets/img/logo-dsa.png",
        "lazyImg": "/assets/img/logo-dsa.png",
        "dataSrc": "/assets/img/logo-dsa.png",
        "name": "Frango à Parmegiana - 500g",
        "selected": true,
        "price": {
            "value": 30,
            "discount": {
                "active": true,
                "value": 0.25
            }
        },
        "text": "500g",
        "tag": {
            "active": true,
            "image": "/assets/img/conteudo/uploads/etiqueta-promo.jpg",
            "type": "new",
            "alt": "Promoção",
            "title": ""
        },
        "seals": []
    },
    {
        "productId": 2051,
        "isFavorite": true,
        "link": "/details/123479",
        "lazySrcset": "/assets/img/logo-dsa.png",
        "dataSrcset": "/assets/img/logo-dsa.png",
        "lazyImg": "/assets/img/logo-dsa.png",
        "dataSrc": "/assets/img/logo-dsa.png",
        "name": "Macarrão à Carbonara - 300g",
        "selected": false,
        "price": {
            "value": 14.95,
            "discount": {
                "active": true,
                "value": 0.1
            }
        },
        "text": "300g",
        "tag": {
            "active": true,
            "image": "/assets/img/conteudo/uploads/etiqueta-promo.jpg",
            "type": "new",
            "alt": "Promoção",
            "title": ""
        },
        "seals": []
    },
    {
        "productId": 2052,
        "isFavorite": true,
        "link": "/details/123479",
        "lazySrcset": "/assets/img/logo-dsa.png",
        "dataSrcset": "/assets/img/logo-dsa.png",
        "lazyImg": "/assets/img/logo-dsa.png",
        "dataSrc": "/assets/img/logo-dsa.png",
        "name": "Macarrão à Carbonara - 500g",
        "selected": true,
        "price": {
            "value": 27,
            "discount": {
                "active": true,
                "value": 0.2
            }
        },
        "text": "500g",
        "tag": {
            "active": true,
            "image": "/assets/img/conteudo/uploads/etiqueta-promo.jpg",
            "type": "new",
            "alt": "Promoção",
            "title": ""
        },
        "seals": []
    },
    {
        "productId": 2001,
        "isFavorite": true,
        "link": "/details/123474",
        "lazySrcset": "/assets/img/logo-dsa.png",
        "dataSrcset": "/assets/img/logo-dsa.png",
        "lazyImg": "/assets/img/logo-dsa.png",
        "dataSrc": "/assets/img/logo-dsa.png",
        "name": "Strogonoff de Carne - 300g",
        "selected": false,
        "price": {
            "value": 17.95,
            "discount": {
                "active": true,
                "value": 0.2
            }
        },
        "text": "300g",
        "tag": {
            "active": true,
            "image": "/assets/img/conteudo/uploads/etiqueta-promo.jpg",
            "type": "new",
            "alt": "Promoção",
            "title": ""
        },
        "seals": []
    },
    {
        "productId": 20078787,
        "isFavorite": true,
        "link": "/details/123474",
        "lazySrcset": "/assets/img/logo-dsa.png",
        "dataSrcset": "/assets/img/logo-dsa.png",
        "lazyImg": "/assets/img/logo-dsa.png",
        "dataSrc": "/assets/img/logo-dsa.png",
        "name": "Strogonoff de Carne - 500g",
        "selected": true,
        "price": {
            "value": 32,
            "discount": {
                "active": true,
                "value": 0.25
            }
        },
        "text": "500g",
        "tag": {
            "active": true,
            "image": "/assets/img/conteudo/uploads/etiqueta-promo.jpg",
            "type": "new",
            "alt": "Promoção",
            "title": ""
        },
        "seals": []
    },
    {
        "productId": 2054541,
        "isFavorite": true,
        "link": "/details/123475",
        "lazySrcset": "/assets/img/logo-dsa.png",
        "dataSrcset": "/assets/img/logo-dsa.png",
        "lazyImg": "/assets/img/logo-dsa.png",
        "dataSrc": "/assets/img/logo-dsa.png",
        "name": "Feijoada Completa - 300g",
        "selected": false,
        "price": {
            "value": 17.95,
            "discount": {
                "active": true,
                "value": 0.2
            }
        },
        "text": "300g",
        "tag": {
            "active": true,
            "image": "/assets/img/conteudo/uploads/etiqueta-promo.jpg",
            "type": "new",
            "alt": "Promoção",
            "title": ""
        },
        "seals": []
    },
    {
        "productId": 2545401,
        "isFavorite": true,
        "link": "/details/123475",
        "lazySrcset": "/assets/img/logo-dsa.png",
        "dataSrcset": "/assets/img/logo-dsa.png",
        "lazyImg": "/assets/img/logo-dsa.png",
        "dataSrc": "/assets/img/logo-dsa.png",
        "name": "Feijoada Completa - 500g",
        "selected": true,
        "price": {
            "value": 32,
            "discount": {
                "active": true,
                "value": 0.25
            }
        },
        "text": "500g",
        "tag": {
            "active": true,
            "image": "/assets/img/conteudo/uploads/etiqueta-promo.jpg",
            "type": "new",
            "alt": "Promoção",
            "title": ""
        },
        "seals": []
    },
    {
        "productId": 110,
        "isFavorite": true,
        "link": "/details/123456",
        "lazySrcset": "/assets/img/logo-dsa.png",
        "dataSrcset": "/assets/img/logo-dsa.png",
        "lazyImg": "/assets/img/logo-dsa.png",
        "dataSrc": "/assets/img/logo-dsa.png",
        "name": "BOLO ALEGRIA",
        "selected": false,
        "price": {
            "value": 14.95,
            "discount": {
                "active": true,
                "value": 0.2
            }
        },
        "text": "300g",
        "tag": {
            "active": true,
            "image": "/assets/img/conteudo/uploads/etiqueta-promo.jpg",
            "type": "new",
            "alt": "Promoção",
            "title": ""
        },
        "seals": [
            {
                "sealId": 1
            },
            {
                "sealId": 2
            }
        ]
    },
    {
        "productId": 120,
        "isFavorite": false,
        "link": "/details/789012",
        "lazySrcset": "/assets/img/logo-dsb.png",
        "dataSrcset": "/assets/img/logo-dsb.png",
        "lazyImg": "/assets/img/logo-dsb.png",
        "dataSrc": "/assets/img/logo-dsb.png",
        "name": "BOLO BEM CASADO",
        "selected": false,
        "price": {
            "value": 20,
            "discount": {
                "active": false,
                "value": 0
            }
        },
        "text": "500g",
        "tag": {
            "active": false,
            "image": "",
            "alt": ""
        },
        "seals": []
    },
    {
        "productId": 130,
        "isFavorite": true,
        "link": "/details/789013",
        "lazySrcset": "/assets/img/logo-dsc.png",
        "dataSrcset": "/assets/img/logo-dsc.png",
        "lazyImg": "/assets/img/logo-dsc.png",
        "dataSrc": "/assets/img/logo-dsc.png",
        "name": "BOLO BRIGADEIRO",
        "selected": false,
        "price": {
            "value": 18.5,
            "discount": {
                "active": true,
                "value": 0.15
            }
        },
        "text": "400g",
        "tag": {
            "active": true,
            "image": "/assets/img/conteudo/uploads/etiqueta-promo.jpg",
            "type": "new",
            "alt": "Promoção",
            "title": ""
        },
        "seals": [
            {
                "sealId": 3
            }
        ]
    },
    {
        "parentId": 23,
        "productId": 2031,
        "isFavorite": true,
        "link": "/details/123477",
        "lazySrcset": "/assets/img/logo-dsa.png",
        "dataSrcset": "/assets/img/logo-dsa.png",
        "lazyImg": "/assets/img/logo-dsa.png",
        "dataSrc": "/assets/img/logo-dsa.png",
        "name": "Risoto de Camarão - 300g",
        "selected": false,
        "price": {
            "value": 19.95,
            "discount": {
                "active": true,
                "value": 0.1
            }
        },
        "text": "300g",
        "tag": {
            "active": true,
            "image": "/assets/img/conteudo/uploads/etiqueta-promo.jpg",
            "type": "new",
            "alt": "Promoção",
            "title": ""
        },
        "seals": []
    },
    {
        "parentId": 23,
        "productId": 2032,
        "isFavorite": true,
        "link": "/details/123477",
        "lazySrcset": "/assets/img/logo-dsa.png",
        "dataSrcset": "/assets/img/logo-dsa.png",
        "lazyImg": "/assets/img/logo-dsa.png",
        "dataSrc": "/assets/img/logo-dsa.png",
        "name": "Risoto de Camarão - 500g",
        "selected": true,
        "price": {
            "value": 34,
            "discount": {
                "active": true,
                "value": 0.3
            }
        },
        "text": "500g",
        "tag": {
            "active": true,
            "image": "/assets/img/conteudo/uploads/etiqueta-promo.jpg",
            "type": "new",
            "alt": "Promoção",
            "title": ""
        },
        "seals": []
    }
]
products.forEach(product => {
    //addProductToDatabase(product);
});

const productsSame = [
    {
        "id": "1",
        "selectedOption": 0,
        "measurementsOptions": {
            "-NvJ3m_M6p7K_l3mgjjC": {
                "productId": "-NvJ3m_M6p7K_l3mgjjC",
                "active": true
            },
            "-NvJ3m_SVVf5-AlZXTsZ": {
                "productId": "-NvJ3m_SVVf5-AlZXTsZ",
                "active": true
            }
        }
    },
    {
        "id": "2",
        "selectedOption": 0,
        "measurementsOptions": {
            "-NvJ3m_VTG6Pi_vcIENB": {
                "productId": "-NvJ3m_VTG6Pi_vcIENB",
                "active": true
            },
            "-NvJ3m_ZnRmpc9_OJrOD": {
                "productId": "-NvJ3m_ZnRmpc9_OJrOD",
                "active": true
            },
            "-NvJ3m_h9qlqRi6jRAmm": {
                "productId": "-NvJ3m_h9qlqRi6jRAmm",
                "active": true
            },
            "-NvJ3m_iJGjTx33nu6_s": {
                "productId": "-NvJ3m_iJGjTx33nu6_s",
                "active": true
            }
        }
    },
    {
        "id": "3",
        "selectedOption": 0,
        "measurementsOptions": {
            "-NvJ3m__Jt4JlTa8lvF8": {
                "productId": "-NvJ3m__Jt4JlTa8lvF8",
                "active": true
            },
            "-NvJ3m_ageiW_M6MAmz7": {
                "productId": "-NvJ3m_ageiW_M6MAmz7",
                "active": true
            },
            "-NvJ3m_jSEyrqEw-tw73": {
                "productId": "-NvJ3m_jSEyrqEw-tw73",
                "active": true
            },
            "-NvJ3m_l4H0Tstqm3v0e": {
                "productId": "-NvJ3m_l4H0Tstqm3v0e",
                "active": true
            }
        }
    },
    {
        "id": "4",
        "selectedOption": 0,
        "measurementsOptions": {
            "-NvJ3m_bjuBjCb5DxQjd": {
                "productId": "-NvJ3m_bjuBjCb5DxQjd",
                "active": true
            },
            "-NvJ3m_cXW41-WX8mjpj": {
                "productId": "-NvJ3m_cXW41-WX8mjpj",
                "active": true
            }
        }
    },
    {
        "id": "5",
        "selectedOption": 0,
        "measurementsOptions": {
            "-NvJ3m_dOyLbq2HbU-HM": {
                "productId": "-NvJ3m_dOyLbq2HbU-HM",
                "active": true
            },
            "-NvJ3m_eaUHYwj1TAid6": {
                "productId": "-NvJ3m_eaUHYwj1TAid6",
                "active": true
            },
            "-NvJ3m_rXtIO7hRhI4dm": {
                "productId": "-NvJ3m_rXtIO7hRhI4dm",
                "active": true
            },
            "-NvJ3m_sKWr_JtC_Us98": {
                "productId": "-NvJ3m_sKWr_JtC_Us98",
                "active": true
            }
        }
    },
    {
        "id": "6",
        "selectedOption": 0,
        "measurementsOptions": {
            "-NvJ3m_eaUHYwj1TAid7": {
                "productId": "-NvJ3m_eaUHYwj1TAid7",
                "active": true
            },
            "-NvJ3m_fYcpLmD5RQKhX": {
                "productId": "-NvJ3m_fYcpLmD5RQKhX",
                "active": true
            }
        }
    },
    {
        "id": "7",
        "selectedOption": 0,
        "measurementsOptions": {
            "-NvJ3m_gUA5zOgfFFI4S": {
                "productId": "-NvJ3m_gUA5zOgfFFI4S",
                "active": true
            },
            "-NvJ3m_gUA5zOgfFFI4T": {
                "productId": "-NvJ3m_gUA5zOgfFFI4T",
                "active": true
            }
        }
    },
    {
        "id": "8",
        "selectedOption": 0,
        "measurementsOptions": {
            "-NvJ3m_mjhtI5CfwYCWb": {
                "productId": "-NvJ3m_mjhtI5CfwYCWb",
                "active": true
            }
        }
    },
    {
        "id": "9",
        "selectedOption": 0,
        "measurementsOptions": {
            "-NvJ3m_por7uNiI1VklK": {
                "productId": "-NvJ3m_por7uNiI1VklK",
                "active": true
            }
        }
    },
    {
        "id": "10",
        "selectedOption": 0,
        "measurementsOptions": {
            "-NvJ3m_por7uNiI1VklL": {
                "productId": "-NvJ3m_por7uNiI1VklL",
                "active": true
            }
        }
    }
]

const categories = [
    {
        "categoryId": "1",
        "description": "Confira os queridinhos de nossos Clientes.",
        "image": "/assets/img/conteudo/uploads/icon-vitrine-1-612fb6a71bbdd.png",
        "link": {
            "image": {
                "png": "/assets/img/conteudo/uploads/icon-kits-612fbf564c522-1-628542a329f8c.png",
                "webp": "/assets/img/conteudo/uploads/icon-kits-612fbf564c522-1-628542a329f8c.webp"
            },
            "path": "/categories/1",
            "title": "Promoção"
        },
        "productsSame": {
            "-NvJBUFqyVZDGe5dchSH": {
                "productSameId": "-NvJBUFqyVZDGe5dchSH"
            },
            "-NvJBUG4nVDCRClH8fDg": {
                "productSameId": "-NvJBUG4nVDCRClH8fDg"
            },
            "-NvJBUGKhVB4MqTZngGa": {
                "productSameId": "-NvJBUGKhVB4MqTZngGa"
            },
            "-NvJBUGMo7MDMGaNDLnV": {
                "productSameId": "-NvJBUGMo7MDMGaNDLnV"
            }
        },
        "title": "Promoção"
    },
    {
        "categoryId": "100000",
        "description": "Clássicos que nunca saem de moda.",
        "image": "/assets/img/conteudo/uploads/icon-vitrine-1-612fb6a71bbdd.png",
        "link": {
            "image": {
                "png": "/assets/img/conteudo/uploads/icon-pratos-prontos-612fbf7a46726.png"
            },
            "path": "/categories/100000",
            "title": "Pratos"
        },
        "productsSame": {
            "-NvJBUFqyVZDGe5dchSH": {
                "productSameId": "-NvJBUFqyVZDGe5dchSH"
            },
            "-NvJBUG4nVDCRClH8fDg": {
                "productSameId": "-NvJBUG4nVDCRClH8fDg"
            },
            "-NvJBUGKhVB4MqTZngGa": {
                "productSameId": "-NvJBUGKhVB4MqTZngGa"
            },
            "-NvJBUGMo7MDMGaNDLnV": {
                "productSameId": "-NvJBUGMo7MDMGaNDLnV"
            }
        },
        "title": "Menu Tradicional"
    },
    {
        "bannerImageUrl": "/assets/img/conteudo/uploads/icon-vitrine-1-612fb6a71bbdd.png",
        "categoryId": "10",
        "description": "Clássicos que nunca saem de moda.",
        "link": {
            "image": {
                "png": "/assets/img/conteudo/uploads/icon-fit-low-carb-612fc16bda702.png"
            },
            "path": "/categories/10",
            "title": "Fit"
        },
        "productsSame": {
            "-NvJBUFqyVZDGe5dchSH": {
                "productSameId": "-NvJBUFqyVZDGe5dchSH"
            },
            "-NvJBUG4nVDCRClH8fDg": {
                "productSameId": "-NvJBUG4nVDCRClH8fDg"
            },
            "-NvJBUGKhVB4MqTZngGa": {
                "productSameId": "-NvJBUGKhVB4MqTZngGa"
            },
            "-NvJBUGMo7MDMGaNDLnV": {
                "productSameId": "-NvJBUGMo7MDMGaNDLnV"
            }
        },
        "title": "Menu Tradicional"
    }
]
productsSame.forEach(product => {
    console.log({ product })
    // addProductsSameToDatabase(product);
});
categories.forEach(category => {
    console.log({ category })
    // addCategoryToDatabase(category);
});

const seals = [
    {
        "image": "/assets/img/conteudo/uploads/selo-semlactose-612fbe206f72f.png",
        "sealId": 1,
        "title": "Sem lactose"
    },
    {
        "image": "/assets/img/conteudo/uploads/selo-aniversario-desconto-632dea9f542bd.png",
        "sealId": 2,
        "title": "Cashback progressivo Dona Sol Ajuda"
    },
    {
        "image": "/assets/img/conteudo/uploads/selo-semgluten-612fbe206f72f.png",
        "sealId": 3,
        "title": "Sem glúten"
    }
]

seals.forEach(seal => {
    console.log({ seal })
    addSealToDatabase(seal);
});
 */

/* eslint-disable */
//import '../public/assets/css/icon-fonts-octoshop.min.css';
import '../public/assets/css/style-extend.min.css';
import '../public/assets/css/checkout.css';
import '../public/assets/css/style.min.css';
import '../public/assets/js/loja-function.min.js';
import '../public/assets/js/loja-validations.min.js';
import '../public/assets/js/loja-script.min.js';
import '../public/assets/js/script-js.js';
import '../public/assets/js/tags-textos.min.js';
// import '../public/assets/js/loja-filtros.min.js';

import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import { auth, database } from './firebase';

//Vue.config.productionTip = false


const app = createApp(App)
app
  .use(store)
  .use(router)
  .mount('#app');

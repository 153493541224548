<template>
    <div class="dsac-row">
        <div class="dsac-col-12 dsac-mb-3 dsac-mb-sm-4">
            <h1 class="dsac-h2">Agende a sua
                <span v-if="resumoPedido.tipo.retirada.active">retirada</span>
                <span v-if="resumoPedido.tipo.entrega.active">entrega</span>
            </h1>
            <p class="dsac-small dsac-mt-2">Campos com <span class="dsac-text-danger">*</span> são obrigatórios.</p>
        </div>

        <!-- Calendário -->
        <div class="dsac-col-12 dsac-col-lg-6 dsac-mb-4 dsac-mb-lg-0">
            <div class="dsac-row">
                <StepSheduleCalendar />
            </div>
        </div>

        <!-- Período -->
        <div class="dsac-col-12 dsac-col-lg-6">
            <div class="dsac-row">
                <div class="dsac-col-12 dsac-mb-3 dsac-mb-sm-4">
                    <div class="dsac-card-white">
                        <h4 class="dsac-mb-1">Selecione o período que deseja
                            <span v-if="resumoPedido.tipo.retirada.active">retirar</span>
                            <span v-if="resumoPedido.tipo.entrega.active">receber</span> <span
                                class="text-danger">*</span>
                        </h4>

                        <StepShedulePeriod />
                     <!--    <div
                            class="dsac-d-inline-flex dsac-align-items-center dsac-w-100 dsac-mt-2 dsac-mt-sm-3 dsac-dsac-d-inline-flex dsac-dsac-align-items-center dsac-dsac-w-100 dsac-dsac-mt-2 dsac-dsac-mt-sm-3">
                            <label class="dsac-switch dsac-dsac-switch">
                                <input type="checkbox" name="portaria" id="portaria" value="1">
                                <span class="dsac-slider dsac-round dsac-dsac-slider dsac-dsac-round"></span>
                            </label>
                            <div
                                class="dsac-d-block dsac-ms-3 dsac-ms-sm-2 dsac-dsac-d-block dsac-dsac-ms-3 dsac-dsac-ms-sm-2">
                                <h5>Pode deixar na portaria?</h5>
                                <h6 class="dsac-mt-1 dsac-dsac-mt-1">Alguns itens podem ser congelados. Avise na
                                    portaria sobre
                                    a entrega.</h6>
                            </div>
                        </div>
                        <div
                            class="dsac-d-inline-flex dsac-align-items-center dsac-w-100 dsac-mt-4 dsac-dsac-d-inline-flex dsac-dsac-align-items-center dsac-dsac-w-100 dsac-dsac-mt-4">
                            <label class="dsac-switch dsac-dsac-switch">
                                <input type="checkbox" name="termosEntrega" id="termosEntrega" checked="">
                                <span class="dsac-slider dsac-round dsac-dsac-slider dsac-dsac-round"></span>
                            </label>
                            <div
                                class="dsac-d-block dsac-ms-3 dsac-ms-sm-2 dsac-dsac-d-block dsac-dsac-ms-3 dsac-dsac-ms-sm-2">
                                <h5>Estou ciente sobre as condições de entrega <span
                                        class="dsac-text-danger dsac-dsac-text-danger">*</span></h5>
                                <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#modalTermosEntrega"
                                    class="dsac-btn-link dsac-mt-1 dsac-dsac-btn-link dsac-dsac-mt-1" role="button"><i
                                        class="dsac-las dsac-la-file-alt dsac-dsac-las dsac-dsac-la-file-alt"></i>Ver
                                    condições</a>
                            </div>
                        </div> -->
                    </div>
                </div>
                <div class="dsac-col-12 dsac-text-sm-end dsac-mt-2">
                    <div class="dsac-d-sm-inline-flex dsac-align-items-center dsac-justify-content-end dsac-w-100">
                        <button @click="setActiveStepComponent('StepCupom')"
                        :disabled="verifyIfIsButtonDisabledNextInCheckoutStepShedule"
                            class="dsac-btn dsac-btn-primary dsac-btn-xs-fixed dsac-order-sm-2 dsac-">
                            Continuar<i class="dsac-fa dsac-la-arrow-right dsac-ms-1"></i>
                        </button>
                        <button @click="setActiveStepComponent('StepChoiceType')"
                            class="dsac-btn dsac-btn-white dsac-w-xs-100 dsac-mt-3 dsac-mt-sm-0 dsac-me-sm-3 dsac-order-sm-1"
                            role="button">
                            <i class="dsac-fa dsac-la-arrow-left dsac-me-1"></i>Voltar
                        </button>
                        <div class="dsac-text-center dsac-mt-4 dsac-d-sm-none">
                            <a href="https://jullietcongelados.com.br/cesta" class="dsac-btn-link" role="button">Voltar
                                para
                                o Carrinho</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState , mapGetters} from 'vuex';
import StepShedulePeriod from './StepShedulePeriod.vue'
import StepSheduleCalendar from './StepSheduleCalendar.vue'
export default {
    components: {
        StepShedulePeriod,
        StepSheduleCalendar
    },
    methods: {
        ...mapActions('checkout', ['setActiveStepComponent'])
    },
    computed: {
        ...mapState('checkout', ['resumoPedido']),
        ...mapGetters('checkout', ['verifyIfIsButtonDisabledNextInCheckoutStepShedule'])
    }
};
</script>

const persistorLocalStorage = store => {
    // Verifica se há dados salvos no localStorage e carrega, se houver
    if (localStorage.getItem('vuex')) {
        try {
            const savedState = JSON.parse(localStorage.getItem('vuex'));
            store.replaceState(savedState);
        } catch (error) {
            console.error('Erro ao carregar o estado Vuex do localStorage:', error);
        }
    }

    // Salva o estado da store no localStorage após cada mutação
    store.subscribe((mutation, state) => {
        try {
            localStorage.setItem('vuex', JSON.stringify(state));
        } catch (error) {
            console.error('Erro ao salvar o estado Vuex no localStorage:', error);
        }
    });
};

export default persistorLocalStorage;

<template>
    <div>
        <section class="banner-interna" :style="bannerStyle">
            <div class="pg-titulo">
                <div class="container">
                    <div class="row">
                        <div class="col-xs-12">
                            <h1 class="animated fadeIn">{{ pageTitle }}</h1>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
  
<script>
export default {
    name: 'BannerComponent',
    props: {
        bannerImageUrl: {
            type: String,
            required: true,
        },
        pageTitle: {
            type: String,
            required: true,
        },
    },
    computed: {
        bannerStyle() {
            return {
                'background-image': `url(${this.bannerImageUrl})`,
            };
        },
    },
};
</script>
  
<style scoped>
/* Adicione seus estilos específicos do componente aqui */
</style>
  
<template>
    <div class="dsac-tab-pane dsac-fade"
        :class="{ 'dsac-active dsac-show': activeStepChoiceTypeComponent === 'StepChoiceTypePickup' }"
        id="StepChoiceTypePickup" role="tabpanel">
        <div class="dsac-card-white">
            <div class="dsac-js-endereco-retirada">
                <h4>Dona Sol Ajuda Congelados <span class="dsac-text-danger">*</span></h4>
                <p class="dsac-small dsac-mt-2">{{ origem }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    computed: {
        ...mapState('checkout', ['activeStepChoiceTypeComponent', 'origem'])
    }
};
</script>

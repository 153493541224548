<template>
  <main class="pg-internas bg-loja" v-if="getOrderDetailsSelected">
    <div class="container">
      <div class="row">

        <!-- Status -->
        <div class="col-xs-12 animated fadeIn">
          <div class="row">

            <div class="flex no-flex-xs top-tabela">
              <div class="col-xs-12 col-md-6">
                <h2>{{ getOrderDetailsSelected?.pedido.numero }}</h2>
                <h4>{{ getOrderDetailsSelected?.pedido.status }}</h4>
              </div>

              <!-- Repetir pedido -->
              <div class="col-xs-12 col-md-3"></div>

              <div class="col-xs-12 col-md-3">
                <div class="box-btn-top">
                  <span @click="repetePedido(getOrderDetailsSelected?.registro3.itens)" class="btn btn-wide"
                    id="repete-pedido">Repetir pedido<i class="fa fa-spinner fa-spin ml-1"
                      style="display: none"></i></span>
                </div>
              </div>
            </div>

            <div class="col-xs-12">
              <div class="tabela-4">

                <!-- Registro 01 -->
                <table class="tabela-padrao">
                  <thead>
                    <tr>
                      <th width="16.66%">Data Novo</th>
                      <th width="16.66%">Data Pendente</th>
                      <th width="16.66%">Data Pago</th>
                      <th width="16.66%">Data Enviado</th>
                      <th width="16.66%">Data Entregue</th>
                      <th width="16.66%">Data Cancelado</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{ getOrderDetailsSelected?.registro1.dataNovo }}</td>
                      <td>{{ getOrderDetailsSelected?.registro1.dataPendente }}</td>
                      <td>{{ getOrderDetailsSelected?.registro1.dataPago }}</td>
                      <td>{{ getOrderDetailsSelected?.registro1.dataEnviado }}</td>
                      <td>{{ getOrderDetailsSelected?.registro1.dataEntregue }}</td>
                      <td>{{ getOrderDetailsSelected?.registro1.dataCancelado }}</td>
                    </tr>
                  </tbody>
                </table>

                <!-- Registro 02 -->
                <table class="tabela-padrao margin-top">
                  <thead>
                    <tr>
                      <th width="50%">Entrega:</th>
                      <th width="50%">Pagamento:</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <p>
                          Data / Período: {{ getOrderDetailsSelected?.registro2.data }} / {{
    getOrderDetailsSelected?.registro2.periodo }}<br>
                          Nome: {{ getOrderDetailsSelected?.registro2.nome }}<br>
                          Endereço: {{ getOrderDetailsSelected?.registro2.endereco }}<br>
                          Cep: {{ getOrderDetailsSelected?.registro2.cep }} - {{
    getOrderDetailsSelected?.registro2.localidade
  }} - {{
      getOrderDetailsSelected?.registro2.uf }}
                        </p>
                      </td>
                      <td>
                        <div v-if="getOrderDetailsSelected?.registro2.tipoPagamento">
                          <div class="item">
                            <p class="titulo"><span>Tipo</span></p>
                            <p>{{ getOrderDetailsSelected?.registro2.tipoPagamento }}</p>
                          </div>
                          <div class="item">
                            <p class="titulo"><span>Data</span></p>
                            <p>{{ getOrderDetailsSelected?.registro2.dataPagamento }}</p>
                          </div>
                          <div class="item">
                            <p class="titulo"><span>Status</span></p>
                            <p>{{ getOrderDetailsSelected?.registro2.statusPagamento }}</p>
                          </div>
                          <div class="col-xs-12">
                            <span class="btn" @click="abrirModalPix">
                              <i class="fa fa-qrcode"></i> Clique aqui para ver o QRcode
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <!-- Registro 03 -->
                <table class="tabela-padrao margin-top">
                  <thead>
                    <tr>
                      <th width="20%">Ref.</th>
                      <th width="20%">Descrição</th>
                      <th width="20%">Quant.</th>
                      <th width="20%">Valor unitário</th>
                      <th width="20%">Valor Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in getOrderDetailsSelected?.registro3.itens" :key="index">
                      <td>{{ item.codigo }}</td>
                      <td>{{ item.descricao }}</td>
                      <td>{{ item.quantidade }}</td>
                      <td>{{ item.valorUnitario }}</td>
                      <td>{{ item.valorTotal }}</td>
                    </tr>
                  </tbody>
                </table>

                <!-- Registro 04 -->
                <table class="tabela-padrao" style="margin-top: -1px;">
                  <tbody>
                    <tr>
                      <td width="50%" class="text-right txt-color">Subtotal:</td>
                      <td width="50%" class="text-right">{{ getOrderDetailsSelected?.registro4.subtotal }}</td>
                    </tr>
                    <tr>
                      <td width="50%" class="text-right txt-color">Frete:</td>
                      <td width="50%" class="text-right">{{ getOrderDetailsSelected?.registro4.frete }}</td>
                    </tr>
                    <tr>
                      <td width="50%" class="text-right txt-color">Desconto pagamento:</td>
                      <td width="50%" class="text-right">{{ getOrderDetailsSelected?.registro4.desconto }}</td>
                    </tr>
                    <tr>
                      <td width="50%" class="text-right txt-color">Total Geral:</td>
                      <td width="50%" class="text-right">{{ getOrderDetailsSelected?.registro4.totalGeral }}</td>
                    </tr>
                  </tbody>
                </table>

              </div>
              <!-- .tabela-4-->

            </div>
            <!-- .col-xs-12 -->


            <!-- Voltar bottom -->
            <div class="margin-top-30">
              <div class="col-xs-12">
                <div class="box-voltar-bottom">
                  <router-link :to="{ path: '/orders' }" class="btn-voltar icon-left">
                    <i class="fa fa-arrow-left"></i> Voltar
                  </router-link>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>
    <ModalPix :show="modalPixVisible" />

  </main>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import ModalPix from './ModalPix.vue';
export default {
  components: {
    ModalPix
  },
  computed: {
    ...mapState('user', ['modalPixVisible']),
    ...mapGetters('user', ['getOrderDetailsSelected'])
  },
  methods: {
    ...mapActions('user', ['abrirModalPix', 'repetirPedido']),
    async repetePedido(itens) {
      await this.repetirPedido(itens)
      this.$router.push({
        path: "/shopping-cart"
      })
    }
  }
};
</script>

<style scoped>
/* Estilos podem ser adicionados aqui, se necessário */
</style>

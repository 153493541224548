<template>
    <div class="dsac-col-12">
        <div class="dsac-card-white">
            <h4 class="dsac-mb-1">Selecione a data de                 
                <span v-if="resumoPedido.tipo.retirada.active">retirada</span> 
                <span v-if="resumoPedido.tipo.entrega.active">entrega</span> 
                 <span class="dsac-text-danger">*</span></h4>
            <p class="dsac-small dsac-mt-2">Sua 
                <span v-if="resumoPedido.tipo.retirada.active">retirada</span> 
                <span v-if="resumoPedido.tipo.entrega.active">entrega</span>  está agendada para <span class="dsac-text-primary"
                    id="texto-data"><strong>{{ selectedDate }}</strong></span>.</p>
            <div class="dsac-box-calendar dsac-mt-3 dsac-mt-xxl-4">
                <div id="dateCalendarEntrega">
                    <div class="dsac-tui-datepicker dsac-tui-hidden">
                        <div class="dsac-tui-datepicker-body dsac-tui-datepicker-type-date">
                            <div class="dsac-tui-calendar-container">
                                <div class="dsac-tui-calendar">
                                    <div class="dsac-tui-calendar-header">
                                        <div class="dsac-tui-calendar-header-inner">
                                            <button class="dsac-tui-calendar-btn dsac-tui-calendar-btn-prev-month"
                                                :disabled="isPrevMonthDisabled" @click="prevMonth"><i
                                                    class="fas fa-chevron-left"></i></button>
                                            <em class="dsac-tui-calendar-title dsac-tui-calendar-title-month">
                                                {{ currentMonth }} {{ currentYear }}
                                            </em>
                                            <button class="dsac-tui-calendar-btn dsac-tui-calendar-btn-next-month"
                                                @click="nextMonth"><i class="fas fa-chevron-right"></i></button>
                                        </div>
                                        <div class="dsac-tui-calendar-header-info">
                                            <p class="dsac-tui-calendar-title-today">{{ today }}</p>
                                        </div>
                                    </div>
                                    <div class="dsac-tui-calendar-body">
                                        <table class="dsac-tui-calendar-body-inner" cellspacing="0" cellpadding="0">
                                            <thead class="dsac-tui-calendar-body-header">
                                                <tr>
                                                    <th class="dsac-tui-sun" scope="col">Dom</th>
                                                    <th scope="col">Seg</th>
                                                    <th scope="col">Ter</th>
                                                    <th scope="col">Qua</th>
                                                    <th scope="col">Qui</th>
                                                    <th scope="col">Sex</th>
                                                    <th class="dsac-tui-sat" scope="col">Sáb</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr class="dsac-tui-calendar-week" v-for="(week, index) in calendar"
                                                    :key="index">
                                                    <td v-for="(day, dIndex) in week" :key="dIndex"
                                                        :class="{ 'tui-calendar-date': true, 'tui-is-selectable': day.selectable, 'active': new Date(day.date).getTime() === new Date(dateSelected.year, dateSelected.month, dateSelected.day).getTime(), 'today': day.today }"
                                                        @click="selectDate(day)">
                                                        {{ day.day }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped="">
.dsac-tui-calendar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dsac-tui-calendar-btn {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
}

.dsac-tui-calendar-btn i {
    color: #333;
}

.dsac-tui-calendar-body-header th {
    padding: 10px;
    background-color: #f8f8f8;
    border-bottom: 1px solid #ccc;
}

.dsac-tui-calendar-body-inner td {
    padding: 8px;
    text-align: center;
    font-size: 14px;
}

.active {
    background-color: #441B15!important;
    color: #FFF;
    cursor: pointer;
    border-radius: 50%;
    padding: 0px;
}

.today {
    background-color: #e0d1d1;
    color: #aa0707;
    cursor: pointer;
}

.tui-calendar-date.tui-is-blocked {
    color: #ccc;
}

.disabled {
    opacity: 0.5;
    /* Deixa os dias anteriores ao dia atual mais fracos */
    cursor: not-allowed;
}

/* Estilos adicionais para responsividade */
@media (max-width: 768px) {
    .dsac-tui-calendar-body-inner td {
        font-size: 12px;
    }
}
</style>


<script>
import { mapActions, mapState } from 'vuex';


export default {
    data() {
        return {
            currentMonth: '',
            currentYear:'',
            today: '',
            selectedDate: '',
            calendar: [],
            isPrevMonthDisabled: false,
            currentDate: new Date() // Adicionado para rastrear o mês atual
        };
    },
    mounted() {
        this.setupCalendar();
    },
    computed: {
        ...mapState('checkout', ['dateSelected','resumoPedido'])
    },
    methods: {
        ...mapActions('checkout', ['setDate']),
        setupCalendar() {
            this.updateCalendar(this.currentDate); // Mudança para usar a data atual
        },
        prevMonth() {
            this.currentDate.setMonth(this.currentDate.getMonth() - 1); // Atualiza o mês atual
            this.updateCalendar(this.currentDate);
            this.isPrevMonthDisabled = this.isPrevMonthDisabledCheck(this.currentDate);
        },
        nextMonth() {
            this.currentDate.setMonth(this.currentDate.getMonth() + 1); // Atualiza o mês atual
            this.updateCalendar(this.currentDate);
            this.isPrevMonthDisabled = this.isPrevMonthDisabledCheck(this.currentDate);
        },
        updateCalendar(now) {
            this.calendar = [];
            this.selectedDate = '';
            this.currentMonth = now.toLocaleString('default', { month: 'long' });

            const todayDate = now.getDate(); // Adicionado para obter o dia atual
            const currentMonth = now.getMonth(); // Adicionado para obter o mês atual
            const currentYear = now.getFullYear(); // Adicionado para obter o ano atual
            const firstDayOfMonth = new Date(currentYear, currentMonth, 1).getDay();
            const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();

            this.currentYear = currentYear
            let dayCounter = 1;
            let week = [];

            for (let i = 0; i < 6; i++) {
                for (let j = 0; j < 7; j++) {
                    if (i === 0 && j < firstDayOfMonth) {
                        week.push({ day: '', date: '', selectable: false, active: false, today: false });
                    } else if (dayCounter > daysInMonth) {
                        break;
                    } else {
                        const date = new Date(currentYear, currentMonth, dayCounter);
                        week.push({ day: dayCounter, date: date.toDateString(), selectable: dayCounter === todayDate || date > now, active: false, today: dayCounter === todayDate });
                        dayCounter++;
                    }
                }
                this.calendar.push(week);
                week = [];
            }
        },
        isPrevMonthDisabledCheck(now) {
            const currentDate = new Date();
            return now.getMonth() === currentDate.getMonth() && now.getFullYear() <= currentDate.getFullYear();
        },
        selectDate(day) {
            this.calendar.forEach(week => {
                week.forEach(d => {
                    d.active = false;
                });
            });
            day.active = true;
            this.selectedDate = new Date(day.date).toLocaleDateString('default', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
            const dayDateStr = day.date;
            const dayDate = new Date(dayDateStr);
            const formattedDate = `${dayDate.getDate()}/${dayDate.getMonth() + 1}/${dayDate.getFullYear()}`;
            const dayOfWeek = ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'][dayDate.getDay()];
            const result = { date: formattedDate, day: dayOfWeek };

            this.setDate(result);
        },
    },
};
</script>

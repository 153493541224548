import { createStore } from 'vuex';
import persistorLocalStorage from '@/plugins/persistorLocalStorage';
import auth from '@/store/modules/auth';
import cart from '@/store/modules/cart';
import user from '@/store/modules/user';
import filter from '@/store/modules/filter';
import index_db from '@/store/modules/index_db';
import checkout from '@/store/modules/checkout';
import shop from '@/store/modules/shop';
import auth_google from '@/store/modules/auth_google';
import timer from '@/store/modules/timer';

const store = createStore({
    modules: {
        auth,
        cart,
        user,
        filter,
        index_db,
        checkout,
        shop,
        auth_google,
        timer
    },
    plugins: [persistorLocalStorage]
});

export default store;

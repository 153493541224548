<template>
    <div class="dsac-row">
        <div class="dsac-col-12 dsac-mb-3 dsac-mb-sm-4">
            <h1 class="dsac-h2">Selecione qual é a melhor forma de pagamento para você.</h1>
        </div>

        <!-- Pagamentos -->
        <div class="dsac-col-12 dsac-col-xl-8 dsac-mb-2 dsac-mb-xl-0">
            <div class="dsac-row">
                <div id="js-lista-pagamentos" class="dsac-row">
                    <PaymentFormItem v-for="(payment, index) in paymentMethods" :key="payment.id"
                        :item="{ index, payment }" />
                </div>
            </div>
        </div>

        <!-- Resumo -->
        <!-- Resumo -->
        <div class="dsac-col-12 dsac-col-lg-4">
            <div class="dsac-row">
                <div class="dsac-col-12 dsac-mb-3 dsac-mb-sm-4">
                    <div class="dsac-card-white" id="js-resumo">
                        <h4 class="dsac-mb-1">Resumo do pedido</h4>
                        <div class="dsac-card-white-list">
                            <h5 class="dsac-pe-sm-3">Valor dos produtos</h5>
                            <h5 class="dsac-text-nowrap"><strong>{{ formattedValorProdutos }}</strong></h5>
                        </div>
                        <div class="dsac-card-white-list" v-if="resumoPedido.frete.active">
                            <h5 class="dsac-pe-sm-3">Frete</h5>
                            <h5 class="dsac-text-nowrap dsac-js-valor-frete"><strong>+ {{ formattedFrete }}</strong>
                            </h5>
                        </div>
                        <div class="dsac-card-white-list" v-if="resumoPedido.coupon.active">
                            <h5 class="dsac-pe-sm-3">Cupom de Desconto</h5>
                            <h5 class="dsac-text-nowrap dsac-text-success dsac-">
                                <strong>{{ formattedDescontoCupom }}</strong>
                            </h5>
                        </div>
                        <div class="dsac-card-white-list" v-if="resumoPedido.descontoPagamento.active">
                            <h5 class="dsac-pe-sm-3">Desconto Pagamento</h5>
                            <h5 class="dsac-text-nowrap dsac-text-success dsac-">
                                <strong>{{ formattedDescontoPagamento }}</strong>
                            </h5>
                        </div>
                        <div class="dsac-card-white-list">
                            <h3 class="dsac-pe-sm-3">Total</h3>
                            <h3 class="dsac-text-nowrap dsac-js-resumo-total">{{ formattedTotalCheckout }}</h3>
                        </div>

                        <div class="dsac-alert dsac-alert-info dsac-text-center dsac-mt-2 dsac-mt-sm-3"
                            v-if="resumoPedido.tipo.retirada.active">Seu pedido será
                            retirado na <strong>{{ resumoPedido.tipo.retirada.endereco }}</strong></div>
                        <div class="dsac-alert dsac-alert-info dsac-text-center dsac-mt-2 dsac-mt-sm-3"
                            v-if="resumoPedido.tipo.retirada.active">Sua retirada
                            foi agendada para o dia <strong>{{ resumoPedido.tipo.retirada.date }}
                                {{ resumoPedido.tipo.retirada.day }}</strong> no período das
                            <strong>{{ resumoPedido.tipo.retirada.period }}</strong>
                        </div>

                        <div class="dsac-alert dsac-alert-info dsac-text-center dsac-mt-2 dsac-mt-sm-3"
                            v-if="resumoPedido.tipo.entrega.active">Seu pedido será
                            entregue na <strong>{{ resumoPedido.tipo.entrega.endereco }}</strong></div>
                        <div class="dsac-alert dsac-alert-info dsac-text-center dsac-mt-2 dsac-mt-sm-3"
                            v-if="resumoPedido.tipo.entrega.active">Sua entrega
                            foi agendada para o dia <strong>{{ resumoPedido.tipo.entrega.date }}
                                {{ resumoPedido.tipo.entrega.day }}</strong> no período das
                            <strong>{{ resumoPedido.tipo.entrega.period }}</strong>
                        </div>

                        <div class="dsac-alert dsac-text-center dsac-mt-2 dsac-mt-sm-3" id="js-alerta-desconto-frete">
                            Faltam
                            <strong>{{ formattedDescontoFreteGratis }}</strong> para ganhar <strong>100% de
                                desconto</strong> no frete.
                        </div>
                    </div>
                </div>
                <div class="dsac-col-12 dsac-text-sm-end dsac-mt-2">
                    <div class="dsac-d-sm-inline-flex dsac-align-items-center dsac-justify-content-end dsac-w-100">
                        <button @click="setActiveStepComponent('StepCupom')"
                            class="dsac-btn dsac-btn-white dsac-w-xs-100 dsac-mt-3 dsac-mt-sm-0 dsac-me-sm-3 dsac-order-sm-1"
                            role="button">
                            <i class="dsac-fa dsac-la-arrow-left dsac-me-1"></i>Voltar
                        </button>
                        <button id="btnPagamento" @click="setActiveStepComponent('StepPixChosen')"
                            class="dsac-btn dsac-btn-primary dsac-btn-xs-fixed dsac-order-sm-2 ">
                            Finalizar <span class="dsac-ps-2 dsac-js-finalizar"><strong>{{ formattedTotalCheckout
                                    }}</strong></span>
                        </button>
                        <div class="dsac-text-center dsac-mt-4 dsac-d-sm-none">
                            <router-link :to="{ path: '/shopping-cart' }" class="dsac-btn-link">Voltar para o
                                Carrinho</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import PaymentFormItem from './PaymentFormItem.vue';
export default {
    components: {
        PaymentFormItem
    },
    methods: {
        ...mapActions('checkout', ['setActiveStepComponent'])
    },
    computed: {
        ...mapState('checkout', ['paymentMethods', 'resumoPedido']),
        ...mapGetters('checkout', [
            'formattedValorProdutos',
            'formattedFrete',
            'formattedDescontoPagamento',
            'formattedTotal',
            'formattedTotalCheckout',
            'formattedDescontoFreteGratis',
            'formattedDescontoCupom']),
    }
};
</script>

<!-- LoginPage.vue -->
<template>
    <MainLayout>
        <!-- Banner internas -->
        <section class="banner-interna small"
            style="background-image: url(https://jullietcongelados.com.br/lojas/juliettecongelados/theme/assets/img/ban-interna-1.jpg);">
            <div class="pg-titulo">
                <div class="container">
                    <div class="row">
                        <div class="col-xs-12">
                            <h1 class="animated fadeIn">Meu Cadastro</h1>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <main class="pg-internas form-cadastro-new">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12 col-lg-6 col-lg-offset-3">

                        <div class="d-inline mb-3">
                            <div class="flex-space">
                                <div class="group">
                                    <h3>Meu Cadastro</h3>
                                </div>
                                <h6 class="clear"><span>*</span> Campos obrigatórios.</h6>
                            </div>
                        </div>


                        <form method="post" name="frm_cadastro" id="form-cadastro"
                            action="https://jullietcongelados.com.br/login-cadastro" onsubmit="return false">

                            <div class="d-inline mb-5">
                                <div class="row">
                                    <div class="col-xs-12 col-sm-7">
                                        <div class="form-group">
                                            <label for="">Seu e-mail <span>*</span></label>
                                            <input type="email" name="email" value="albatroiz@gmail.com"
                                                class="form-control js-campo-email" maxlength="100" autocomplete="off">


                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="d-inline ">


                                <!-- Física -->
                                <div id="form_fisica" style="display: block;">
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-4">
                                            <div class="form-group">
                                                <label for="">Nome <span>*</span></label>
                                                <input type="text" name="nome1" value="Albatroiz" class="form-control"
                                                    maxlength="100" autocomplete="off">
                                                <input type="hidden" name="nome" id="nome" value="">
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-4">
                                            <div class="form-group">
                                                <label for="">Sobrenome <span>*</span></label>
                                                <input type="text" name="sobrenome1" value="Test" class="form-control"
                                                    maxlength="100" autocomplete="off">
                                            </div>
                                        </div>
                                        <div class="col-xs-6 col-sm-4">
                                            <div class="form-group">
                                                <label for="">CPF <span>*</span></label>
                                                <input type="tel" name="cpf" value="12935505743" readonly="readonly"
                                                    class="form-control js-cpf js-campo-cpf" placeholder="___.___.___-__"
                                                    autocomplete="off">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Jurídica -->
                            </div>

                            <div class="d-inline mb-5">
                                <div class="row">
                                    <div class="col-xs-6 col-sm-4">
                                        <div class="form-group">
                                            <label for="">Sexo <span>*</span></label>
                                            <input type="hidden" name="sexo_obrigatorio" value="1">
                                            <select name="sexo" id="sexo" class="form-control" autocomplete="off">
                                                <option value="">...</option>
                                                <option value="f">Feminino</option>
                                                <option value="m" selected="">Masculino</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-xs-6 col-sm-4">
                                        <div class="form-group">
                                            <label for="">Data de nascimento <span>*</span></label>
                                            <input type="hidden" name="data_nascimento_obrigatorio" value="1">
                                            <input type="tel" name="data_nascimento" value="01/01/1979"
                                                class="form-control js-date-mask" placeholder="__/__/____"
                                                autocomplete="off">
                                        </div>
                                    </div>
                                    <div class="col-xs-6 col-sm-4">
                                        <div class="form-group">
                                            <label for="">Celular ou telefone fixo<span> *</span></label>
                                            <input type="tel" name="telefone" value="(11) 111111111"
                                                class="form-control js-phone-mask" placeholder="(__) ____-____"
                                                autocomplete="off">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="d-inline ">
                                <h3 class="mb-2">Seu endereço</h3>
                                <div class="row">
                                    <div class="col-xs-12 col-sm-4">
                                        <div class="form-group">
                                            <div class="flex-space">
                                                <label for="">CEP <span>*</span></label>
                                                <a href="http://www.buscacep.correios.com.br/sistemas/buscacep/default.cfm"
                                                    target="_blank" class="btn-link">Não sei meu CEP</a>
                                            </div>
                                            <div class="group">
                                                <input type="tel" name="cep" value="01001-001"
                                                    class="form-control js-cep js-input-loading" placeholder="_____-___"
                                                    autocomplete="off">
                                                <span class="loading"><i class="fa fa-spinner fa-spin"
                                                        style="display: none;"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div id="box_endereco" class="row mt-2" style="display: block;">
                                    <div class="col-xs-12 col-sm-6">
                                        <div class="form-group">
                                            <label for="">Endereço <span>*</span></label>
                                            <input type="text" name="endereco" value="Praça da Sé" class="form-control"
                                                maxlength="60" autocomplete="off">
                                        </div>
                                    </div>
                                    <div class="col-xs-4 col-sm-2">
                                        <div class="form-group">
                                            <label for="">Número <span>*</span></label>
                                            <input type="tel" name="numero" value="1" class="form-control" maxlength="5"
                                                autocomplete="off">
                                        </div>
                                    </div>
                                    <div class="col-xs-8 col-sm-4">
                                        <div class="form-group">
                                            <label for="">Complemento</label>
                                            <input type="text" name="complemento" value="" class="form-control"
                                                maxlength="40" autocomplete="off">
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-sm-4">
                                        <div class="form-group">
                                            <label for="">Bairro <span>*</span></label>
                                            <input type="text" name="bairro" value="Sé" class="form-control" maxlength="60"
                                                autocomplete="off">
                                        </div>
                                    </div>
                                    <div class="col-xs-6 col-sm-4">
                                        <div class="form-group">
                                            <label for="">Cidade <span>*</span></label>
                                            <input type="text" name="cidade" value="São Paulo" class="form-control"
                                                maxlength="60" autocomplete="off">
                                            <input type="hidden" name="codigo_ibge" value="3550308">
                                        </div>
                                    </div>
                                    <div class="col-xs-6 col-sm-4">
                                        <div class="form-group">
                                            <label for="">Estado <span>*</span></label>
                                            <select name="estado" id="estado" class="form-control" autocomplete="off">
                                                <option value="">...</option>
                                                <option value="AC">Acre</option>
                                                <option value="AL">Alagoas</option>
                                                <option value="AP">Amapá</option>
                                                <option value="AM">Amazonas</option>
                                                <option value="BA">Bahia</option>
                                                <option value="CE">Ceará</option>
                                                <option value="DF">Distrito Federal</option>
                                                <option value="ES">Espírito Santo</option>
                                                <option value="GO">Goias</option>
                                                <option value="MA">Maranhão</option>
                                                <option value="MT">Mato Grosso</option>
                                                <option value="MS">Mato Grosso do Sul</option>
                                                <option value="MG">Minas Gerais</option>
                                                <option value="PA">Pará</option>
                                                <option value="PB">Paraíba</option>
                                                <option value="PR">Paraná</option>
                                                <option value="PE">Pernambuco</option>
                                                <option value="PI">Piauí</option>
                                                <option value="RJ">Rio de Janeiro</option>
                                                <option value="RN">Rio Grande do Norte</option>
                                                <option value="RS">Rio Grande do Sul</option>
                                                <option value="RO">Rondônia</option>
                                                <option value="RR">Roraima</option>
                                                <option value="SC">Santa Catarina</option>
                                                <option value="SP" selected="">São Paulo</option>
                                                <option value="SE">Sergipe</option>
                                                <option value="TO">Tocantins</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-inline mt-3">
                                <hr>
                                <div class="check-custom mb-4">
                                    <label>
                                        <input type="checkbox" id="newsletter" name="newsletter" value="1" checked="">
                                        <i class="fa fa-square-o"></i><i class="fa fa-check-square-o"></i> Desejo assinar a
                                        newsletter e receber mensagens de lançamentos e promoções no meu e-mail.
                                    </label>
                                </div>


                                <button type="button" class="btn btn-large" onclick="cadastroAltera()">Alterar
                                    cadastro</button>
                                <input type="hidden" name="tipopessoa" value="fisica">
                                <input type="hidden" name="acao" value="alterar">

                            </div>
                        </form>


                    </div>
                </div>
            </div>
        </main>
    </MainLayout>
</template>
  
<script>
import MainLayout from '@/layouts/MainLayout.vue';

export default {
    components: {
        MainLayout
    },
    data() {
        return {
            email: 'usuario@exemplo.com',
            password: 'senha123'
        }
    },
    methods: {
        login() {
            // Chamar a action de login da store Vuex
            this.$store.dispatch('login', { email: this.email, password: this.password })
        }
    }
}
</script>
  
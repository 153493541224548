<template>
    <li class="dropdown link">
        <router-link :to="{ path: item.path }" class="dropdown-toggle flex-icon" data-toggle="linkdropdown">
            <picture>
                <source :srcset="item.image.webp" type="image/png" />
                <img class="icon-menu" :src="item.image.png" :alt="item.title" width="35" height="35" />
            </picture>
            {{ item.title }}
            <i class="fa fa-caret-right"></i>
        </router-link>
        <ul class="dropdown-menu" role="menu">
            <!-- Conteúdo do submenu -->
        </ul>
    </li>
</template>

<script>
export default {
    name: 'SubCategory',
    props: {
        item: {
            type: Object,
            required: true
        }
    }
};
</script>

<style scoped>
/* Seus estilos específicos do componente aqui */
</style>

<template>
    <div class="group-box">
        <h2 class="titulo-desc">Forma de Aquecer - Embalagem 450g</h2>
        <div class="row">
            <div class="box-forma-aquecer">
                <div class="col-xs-12 col-sm-6">
                    <div class="group-item boxHeight" style="height: 289px;"><img alt="Microondas" class="img-responsive"
                            src="/assets/img/conteudo/uploads/_icon-detalhe-2.png" width="130" height="130">
                        <div class="detalhes">
                            <h5>MICROONDAS</h5>

                            <p>Faça uma pequena abertura nas extremidades&nbsp;da
                                embalagem, ou pequenos furos na
                                superficie de cada compartimento.</p>

                            <p>Leve ao forno de</p>

                            <p>micro-ondas em potência média
                                e&nbsp;deixe&nbsp;<strong>aquecer</strong>&nbsp;de 5 à 7
                                minutos.&nbsp;</p>
                        </div>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-6">
                    <div class="group-item boxHeight" style="height: 289px;">
                        <div class="detalhes">
                            <h5>DICAS PARA O AQUECIMENTO</h5>

                            <p>O tempo de aquecimento depende da potência do</p>

                            <p>micro-ondas que você está utilizando. Caso seja
                                necessário, aqueça por mais alguns
                                minutos.</p>

                            <p>&nbsp;</p>

                            <p>A embalagem não pode ir ao forno convencional.</p>

                            <p>&nbsp;</p>

                            <p>&nbsp;</p>

                            <p>&nbsp;</p>

                            <p>&nbsp;</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<template>
    <div class="app-select">
        <select v-model="selectedPeriodIndex" @change="handleSelectChange" class="app- form-control filtro">
            <option v-for="(periodo, index) in periodosEntrega" :key="index"
                :value="index" class="app-select__option">
                {{ periodo.title }} - {{ periodo.description }}
            </option>
        </select>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
    data() {
        return {
            selectedPeriodIndex: "0"
        };
    },
    methods: {
        ...mapActions('checkout', ['setPeriod']),
        handleSelectChange() {
            this.setPeriod(this.selectedPeriodIndex);
        },

    },
    computed: {
        ...mapState('checkout', ['periodosEntrega', 'selectedPeriod'])
    },
    mounted() {

        this.selectedPeriodIndex = this.selectedPeriod
        this.setPeriod(this.selectedPeriodIndex);

    }
};
</script>

<style scoped>
.app-select {
    position: relative;
    width: 100%;
}

.app-select__input {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    background-color: #f0f0f0;
    cursor: pointer;
    /* Estilo do item selecionado */
    color: #333;
}

.app-select__input:focus {
    outline: none;
}

.app-select__option {
    padding: 10px;
}

.app-select__input option:checked {
    background-color: #441B15;
    color: #fff;
}

/* Personalize a aparência da seta do select (opcional) */
.app-select__input::after {
    content: "\25BC";
    /* Triângulo para baixo */
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    pointer-events: none;
}
</style>

<template>

    <div class="container">
        <div class="row">

            <!-- Filtro v2 -->
            <FilterComponent />
            <!-- Sidebar Left -->
            <SidebarLeftComponent />

            <!-- Content Right -->

            <!-- Content Right -->
            <div class="col-xs-12 col-md-9">

                <div class="row">


                </div>

                <div class="row lista-produtos">
                    <ProductComponent v-for="(product, index) in getCategoryFiltered" :key="index" :product="product" />
                </div>

            </div>


        </div>

        <div class="row">
            <div class="col-xs-12">
                <nav>
                    <ul class="pagination pull-right">
                    </ul>
                </nav>
            </div>
        </div>

    </div>



</template>


<script>
import SidebarLeftComponent from '@/components/SidebarLeftComponent.vue';
import FilterComponent from '@/components/FilterComponent.vue'
import ProductComponent from '@/components/ProductComponent.vue'
import { mapGetters, mapActions } from 'vuex';
export default {
    props: {},
    components: {
        SidebarLeftComponent,
        FilterComponent,
        ProductComponent
    },
    data() {
        return {
            breadcrumbItems: [
                { label: 'Home', url: '/' },
                { label: 'Promoções' }
            ],
            activeIndex: 1,
            bannerImageUrl: 'https://jullietcongelados.com.br/lojas/juliettecongelados/conteudo/uploads/6-63d855478c3e7-645a7da87fecb.png',
            pageTitle: 'Promoções',
            isActiveFilter: false,
            products: []
        }
    },
    watch: {
        '$route.params.categoryId': {
            immediate: true, // Chama a função de retorno de chamada imediatamente após a montagem do componente
            handler(newCategoryId) {
                this.setCategory(newCategoryId)
                this.setProducts()
            }
        }
    }
    ,
    computed: {
        ...mapGetters('shop', ['getCategorySelectedProducts', 'getCategoryFiltered'])
    },
    methods: {
        ...mapActions('shop', ['setCategory']),
        setProducts() {
            this.products = this.getCategorySelectedProducts
        }
    }
}
</script>


// timer.js
const state = {
    startTime: null, // Timestamp de início do temporizador
    duration: 0, // Duração do temporizador em milissegundos
    intervalId: null,
    isRunning: false,
    isExpired: false, // Indica se o temporizador já expirou
    elapsedTime: 0 // Tempo decorrido após o temporizador expirar
  };
  
  const mutations = {
    SET_START_TIME(state, startTime) {
      state.startTime = startTime;
    },
    SET_DURATION(state, duration) {
      state.duration = duration;
    },
    SET_INTERVAL_ID(state, intervalId) {
      state.intervalId = intervalId;
    },
    SET_IS_RUNNING(state, isRunning) {
      state.isRunning = isRunning;
    },
    SET_IS_EXPIRED(state, isExpired) {
      state.isExpired = isExpired;
    },
    SET_ELAPSED_TIME(state, elapsedTime) {
      state.elapsedTime = elapsedTime;
    }
  };
  
  const actions = {
    startTimer({ commit, dispatch }, { startDate, duration }) {
      // Verificar se há um temporizador em execução antes de iniciar um novo
      if (!state.isRunning) {
        const startTime = new Date(startDate).getTime();
        commit('SET_START_TIME', startTime);
        commit('SET_DURATION', duration);
        commit('SET_IS_EXPIRED', false); // Reseta o estado de expiração
        dispatch('tick'); // Inicia imediatamente, sem esperar pelo próximo segundo
        const intervalId = setInterval(() => {
          dispatch('tick');
          if (state.duration <= 0) {
            dispatch('stopTimer');
          }
        }, 1000);
        commit('SET_INTERVAL_ID', intervalId);
        commit('SET_IS_RUNNING', true);
      }
    },
    stopTimer({ commit }) {
      clearInterval(state.intervalId);
      commit('SET_INTERVAL_ID', null);
      commit('SET_IS_RUNNING', false);
    },
    tick({ commit, state }) {
      const elapsedTime = Date.now() - state.startTime;
      if (state.duration - elapsedTime <= 0) {
        commit('SET_DURATION', 0);
        commit('SET_IS_RUNNING', false);
        commit('SET_IS_EXPIRED', true);
        commit('SET_ELAPSED_TIME', elapsedTime - state.duration);
      } else {
        commit('SET_DURATION', state.duration - elapsedTime);
      }
    }
  };
  
  export default {
    state,
    mutations,
    actions
  };
  
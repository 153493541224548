<template>
    <div class="js-overlay-html" v-show="show">
        <div class="dsac-modal dsac-fade" :class="{ 'show': show }" id="modalEndereco" data-bs-backdrop="static"
            data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
            <div
                class="dsac-modal-dialog dsac-modal-dialog-centered dsac-modal-dialog-scrollable dsac-modal-fullscreen-md-down dsac-modal-lg">
                <div class="dsac-modal-content">
                    <div class="dsac-modal-header">
                        <button type="button" class="dsac-btn-close" @click="fecharModalPix" data-bs-dismiss="modal"
                            aria-label="Fechar">X
                        </button>
                    </div>
                    <div class="dsac-modal-body">
                        <div class="box-pix">
                            <p>Aponte sua câmera para ler o <strong>QR Code</strong> mostrado para fazer seu pagamento.
                            </p><img style="max-width:250px; max-height:250px; width: auto;height: auto;"
                                src="https://jullietcongelados.com.br/lojas/juliettecongelados/conteudo/uploads_externos/qrcodepix-ped-9881.png">
                            <div class="tag tag-warning">Data de expiração: 06/04/2024 ás 00:00:00</div>
                            <p>Ou copie o codigo abaixo</p>
                            <div class="group-copy"><input id="qrcode" class="form-control" name="qrcode"
                                    value="00020101021226820014br.gov.bcb.pix2560pix.stone.com.br/pix/v2/5cdf8e6b-d2e4-4ed8-aa6a-a239960c82995204000053039865406129.015802BR5925Terca-feira servicos de A6014RIO DE JANEIRO622905254b7ca52fb5d480b9bf59b433d6304C970"
                                    readonly=""><button class="btn btn-2" id="copiar">Copiar</button></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    props: {
        show: Boolean
    },
    methods: {
        ...mapActions('user', ['fecharModalPix']),
    }
};
</script>

<style scoped>
.dsac-modal-dialog-centered {
    top: 10%;
}
</style>

/* eslint-disable */
// Header fixo
$('.js-fixed').affix({ offset: { top: 60 } });


// Toggle Menu Mobile
$(document).ready(function () {
    $(".navbar-toggle").on("click", function () {
        $('html').addClass("js-overlay-html js-overlay-toggle");
        $('.nav-collapse').collapse('show');
    });
    $('.navbar-toggle-close').click(function () {
        $('html').removeClass("js-overlay-html js-overlay-toggle");
        $('.nav-collapse').collapse('hide');
    });
});



// Scroll quando clicar no radio
$('.box-tipo-pagamento label').click(function () {
    $('html, body').animate({
        scrollTop: $($(this).data('href')).offset().top
    }, 800);
});


// Click Menu Principal Full
$(document).ready(function () {
    $(".menu-principal .submenu-full").click(function (e) {
        var e = window.event || e;
        $(this).toggleClass('active').siblings().removeClass('active');
        $('.boxHeight').matchHeight();
        $('.boxHeight2').matchHeight();
        $('.boxHeight3').matchHeight();
        e.stopPropagation();
    });
    $(document).click(function (e) {
        $('.menu-principal .submenu-full').removeClass('active');
    });
});

// Hover Menu Principal Default
$('.menu-principal .submenu-default').hover(
    function () { $(this).addClass('active') },
    function () { $(this).removeClass('active') }
);
$('.menu-principal .submenu-default').hover(function () {
    $('.menu-principal .submenu-full').removeClass('active');
});
// Slider Home v2
$('.js-banner-principal').imagesLoaded(function () {
    $('.js-banner-principal').owlCarousel({
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        items: 1,
        smartSpeed: 800,
        autoplay: true,
        autoplayTimeout: 5000,
        nav: true,
        dots: true,
        loop: true,
        autoHeight: false,
        lazyLoad: true,
        video: true,
        mouseDrag: false,
        margin: 0,
        stagePadding: 0,
        navText: ['<div class="nav-prev"><i class="fa fa-chevron-left"></i></div>', '<div class="nav-next"><i class="fa fa-chevron-right"></i></div>']
    });
});

// Slider Blog
$('.js-banner-blog').imagesLoaded(function () {
    $('.js-banner-blog').owlCarousel({
        smartSpeed: 800,
        autoplay: true,
        autoplayTimeout: 6000,
        nav: false,
        loop: false,
        autoHeight: true,
        lazyLoad: true,
        margin: 0,
        stagePadding: 0,
        autoHeight: true,
        autoHeightClass: 'owl-height',
        navText: ['<div class="nav-prev"><i class="fa fa-chevron-left"></i></div>', '<div class="nav-next"><i class="fa fa-chevron-right"></i></div>'],

        items: 1,


    });
});

//Carrossel Vitrine: Erro Instagram Home iOS
setTimeout(function () {
    $('.js-carousel-new').trigger('refresh.owl.carousel');
    $('.js-box-produto').matchHeight();
    console.log('Recarregou VITRINE');
}, 1000);

//Carrossel Vitrine New
$('.js-carousel-new').each(function (i, elm) {
    var delayCarousel = i * 100;
    setTimeout((function () {
        var $elm = $(elm);
        return function () {
            $elm.owlCarousel({
                smartSpeed: 800,
                autoplay: false,
                autoplayTimeout: 4000,
                nav: true,
                dots: false,
                loop: false,
                autoHeight: false,
                margin: 30,
                stagePadding: 0,
                navText: ['<div class="nav-prev"><i class="fa fa-chevron-left"></i></div>', '<div class="nav-next"><i class="fa fa-chevron-right"></i></div>'],
                responsive: {
                    0: {
                        items: 1,
                        margin: -50, // cortar prox slide css
                        nav: false,
                        loop: false
                    },
                    421: {
                        items: 2,
                        margin: 15
                    },
                    767: {
                        margin: 15,
                        items: 3
                    },
                    1200: {
                        items: 3
                    }
                }
            });
        }
    })(), delayCarousel);
});

// Zoom galeria imagem produto
$("#galeriaProdutos").elevateZoom({
    gallery: 'galeria_01',
    galleryActiveClass: 'active',
    imageCrossfade: true,
    loadingIcon: '/assets/img/ico_loading.svg',
    cursor: "crosshair",
    zoomWindowFadeIn: 500,
    zoomWindowFadeOut: 300,
    lensFadeIn: 800,
    zoomWindowPosition: 1, //2 para menu lateral
    easing: true,
    zoomWindowWidth: 500,
    zoomWindowHeight: 500
});
$("#galeriaProdutos").bind('touchstart', function () {
    $("#galeriaProdutos").unbind('touchmove');
});
$("#galeriaProdutos").bind("click", function (e) {
    var ez = $('#galeriaProdutos').data('elevateZoom');
    $.fancybox(ez.getGalleryList());
    return false;
});
/* 
// Modal novidades home - automatico
$(window).load(function () {

    var modal = getCookie('modal-news');


    // LGPD

}); */

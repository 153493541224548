<!-- LoginPage.vue -->
<template>
    <MainLayout>
        <!-- Banner internas -->
        <InternalBanner :pageTitle="getCategorySelected.link.title"
            bannerImageUrl="https://img.freepik.com/vetores-gratis/ilustracao-plana-de-alimentos-saudaveis-vs-nao-saudaveis_74855-18384.jpg?w=740&t=st=1712539860~exp=1712540460~hmac=31d222154b4567822a191a44e1795ee458caf23f6a8fd1c4723e43d2f393e36c" />
        <!-- Breadcrumbs -->
        <BreadcrumbsComponent :items="breadcrumbItems" :activeIndex="activeIndex" />
        <!-- Content -->
        <!-- Vitrine -->
        <section class="vitrine-home">
            <CategoryContent />

        </section>
    </MainLayout>
</template>

<script>
import MainLayout from '@/layouts/MainLayout.vue';
import CategoryContent from '@/components/Categories/CategoryContent.vue';
import BreadcrumbsComponent from '@/components/BreadcrumbsComponent.vue'
import { mapGetters, mapActions } from 'vuex';
import InternalBanner from '@/components/InternalBanner.vue';

export default {
    data() {
        return {
            breadcrumbItems: [
                { label: 'Home', url: '/' },
            ],
            activeIndex: 1,
        }
    },
    components: {
        MainLayout,
        InternalBanner,
        CategoryContent,
        BreadcrumbsComponent,
    },
    computed: {
        ...mapGetters('shop', ['getCategorySelected'])
    },
    async mounted() {
        // Chama setCategory imediatamente após a montagem do componente
        await this.setCategory(this.$route.params.categoryId)
    },
    watch: {
        '$route.params.categoryId': {
            immediate: true, // Chama a função de retorno de chamada imediatamente após a montagem do componente
            async handler(newCategoryId) {
                await this.setCategory(newCategoryId)
                const [home] = this.breadcrumbItems
                this.breadcrumbItems = [home, { label: this.getCategorySelected.link.title }]
            }
        }
    },
    methods: {
        ...mapActions('shop', ['setCategory'])
    }
}

</script>

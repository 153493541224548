<!-- LoginPage.vue -->
<template>
    <MainLayout>
        <!-- Banner internas -->
        <section class="banner-interna small"
            style="background-image: url(https://jullietcongelados.com.br/lojas/juliettecongelados/theme/assets/img/ban-interna-1.jpg);">
            <div class="pg-titulo">
                <div class="container">
                    <div class="row">
                        <div class="col-xs-12">
                            <h1 class="animated fadeIn">Cadastre-se</h1>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <main class="pg-internas form-cadastro-new">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12 col-lg-6 col-lg-offset-3">

                        <div class="d-inline mb-3">
                            <div class="flex-space">
                                <div class="group">
                                    <h3>Crie sua conta</h3>
                                    <h6 class="clear">Preencha os dados abaixo para se cadastrar no site</h6>
                                </div>
                                <h6 class="clear"><span>*</span> Campos obrigatórios.</h6>
                            </div>
                        </div>


                        <form method="post" name="frm_cadastro" id="form-cadastro"
                            action="https://jullietcongelados.com.br/cadastro" onsubmit="return false">

                            <div class="d-inline mb-5">
                                <div class="row">
                                    <div class="col-xs-12 col-sm-7">
                                        <div class="form-group">
                                            <label for="">Seu e-mail <span>*</span></label>
                                            <input type="email" name="email" v-model="email"
                                                class="form-control js-campo-email" maxlength="100">

                                            <small class="form-text">A confirmação e o acompanhamento de seu pedido serão
                                                enviados ao seu e-mail cadastrado.</small>

                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-sm-5">
                                        <div class="form-group">
                                            <label for="">Sua senha <span>*</span></label>
                                            <input type="text" name="senha" v-model="password"
                                                class="form-control js-password" maxlength="100">
                                            <i class="fa fa-eye password"></i>
                                            <small class="form-text">Use oito ou mais caracteres com uma combinação de
                                                letras, números e símbolos para uma senha mais segura.</small>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="d-inline ">

                                <h3 class="mb-2">Qual seu perfil?</h3>
                                <!-- Radio buttons para selecionar o tipo de pessoa -->
                                <div class="flex-check mb-2 js-toggle-radio">
                                    <div class="check-custom mod-2 check-fisica">
                                        <label :class="{ active: form.tipopessoa === 'fisica' }">
                                            <input type="radio" name="tipopessoa" value="fisica" v-model="form.tipopessoa">
                                            <i class="fa fa-user"></i>Pessoa Física
                                        </label>
                                    </div>
                                    <div class="check-custom mod-2 check-juridica">
                                        <label :class="{ active: form.tipopessoa === 'juridica' }">
                                            <input type="radio" name="tipopessoa" value="juridica"
                                                v-model="form.tipopessoa">
                                            <i class="fa fa-building"></i>Pessoa Jurídica
                                        </label>
                                    </div>
                                </div>

                                <!-- Formulário para Pessoa Física -->
                                <div id="form_fisica" v-if="form.tipopessoa === 'fisica'">
                                    <!-- Campos do formulário para Pessoa Física -->
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-4">
                                            <div class="form-group">
                                                <label for="">Nome <span>*</span></label>
                                                <input type="text" name="nome" v-model="form.nome" class="form-control"
                                                    maxlength="100">
                                                <input type="hidden" name="nome" id="nome">
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-4">
                                            <div class="form-group">
                                                <label for="">Sobrenome <span>*</span></label>
                                                <input type="text" name="sobrenome" v-model="form.sobrenome"
                                                    class="form-control" maxlength="100">
                                            </div>
                                        </div>
                                        <div class="col-xs-6 col-sm-4">
                                            <div class="form-group">
                                                <label for="">CPF <span>*</span></label>
                                                <input type="tel" name="cpf" v-model="form.cpf"
                                                    class="form-control js-cpf js-campo-cpf" placeholder="___.___.___-__">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Formulário para Pessoa Jurídica -->
                                <div id="form_juridica" v-else-if="form.tipopessoa === 'juridica'">
                                    <!-- Campos do formulário para Pessoa Jurídica -->
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-4">
                                            <div class="form-group">
                                                <label for="">Nome da empresa <span>*</span></label>
                                                <input type="text" name="nome_empresa" v-model="form.nome_empresa"
                                                    class="form-control" maxlength="100">
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-4">
                                            <div class="form-group">
                                                <label for="">Nome responsável <span>*</span></label>
                                                <input type="text" name="responsavel" v-model="form.responsavel"
                                                    class="form-control" maxlength="100">
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-4">
                                            <div class="form-group">
                                                <label for="">Sobrenome <span>*</span></label>
                                                <input type="text" name="sobrenome_empresa" v-model="form.sobrenome_empresa"
                                                    class="form-control" maxlength="100">
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-4">
                                            <div class="form-group">
                                                <label for="">CNPJ <span>*</span></label>
                                                <input type="tel" name="cnpj" v-model="form.cnpj"
                                                    class="form-control js-cnpj">
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-8">
                                            <div class="form-group">
                                                <div class="flex-space">
                                                    <label for="">Inscrição estadual</label>
                                                    <div class="check-custom isento">
                                                        <label>
                                                            <input type="checkbox" id="isento" name="isento"
                                                                v-model="form.isento" value="1">
                                                            <i class="fa fa-square-o"></i><i
                                                                class="fa fa-check-square-o"></i> Isento
                                                        </label>
                                                    </div>
                                                </div>
                                                <input type="text" name="inscricao_estadual"
                                                    v-model="form.inscricao_estadual" class="form-control" maxlength="15">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="d-inline mb-5">
                                <div class="row">
                                    <div class="col-xs-6 col-sm-4">
                                        <div class="form-group">
                                            <label for="">Sexo <span>*</span></label>
                                            <input type="hidden" name="sexo_obrigatorio" value="1" />
                                            <select name="sexo" id="sexo" class="form-control">
                                                <option>...</option>
                                                <option value="f">Feminino</option>
                                                <option value="m">Masculino</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-xs-6 col-sm-4">
                                        <div class="form-group">
                                            <label for="">Data de nascimento <span>*</span></label>
                                            <input type="hidden" name="data_nascimento_obrigatorio" value="1" />
                                            <input type="tel" name="data_nascimento" class="form-control js-date-mask"
                                                placeholder="__/__/____">
                                        </div>
                                    </div>
                                    <div class="col-xs-6 col-sm-4">
                                        <div class="form-group">
                                            <label for="">Celular ou telefone fixo<span> *</span></label>
                                            <input type="tel" name="telefone" class="form-control js-phone-mask"
                                                placeholder="(__) ____-____">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="d-inline ">
                                <h3 class="mb-2">Seu endereço</h3>
                                <div class="row">
                                    <div class="col-xs-12 col-sm-4">
                                        <div class="form-group">
                                            <div class="flex-space">
                                                <label for="">CEP <span>*</span></label>
                                                <a href="http://www.buscacep.correios.com.br/sistemas/buscacep/default.cfm"
                                                    target="_blank" class="btn-link">Não sei meu CEP</a>
                                            </div>
                                            <div class="group">
                                                <input type="tel" name="cep" class="form-control js-cep js-input-loading"
                                                    placeholder="_____-___">
                                                <span class="loading"><i class="fa fa-spinner fa-spin"
                                                        style="display: none;"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div id="box_endereco" class="row mt-2" style="display: none;">
                                    <div class="col-xs-12 col-sm-6">
                                        <div class="form-group">
                                            <label for="">Endereço <span>*</span></label>
                                            <input type="text" name="endereco" class="form-control" maxlength="60">
                                        </div>
                                    </div>
                                    <div class="col-xs-4 col-sm-2">
                                        <div class="form-group">
                                            <label for="">Número <span>*</span></label>
                                            <input type="tel" name="numero" class="form-control" maxlength="5">
                                        </div>
                                    </div>
                                    <div class="col-xs-8 col-sm-4">
                                        <div class="form-group">
                                            <label for="">Complemento</label>
                                            <input type="text" name="complemento" class="form-control" maxlength="40">
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-sm-4">
                                        <div class="form-group">
                                            <label for="">Bairro <span>*</span></label>
                                            <input type="text" name="bairro" class="form-control" maxlength="60">
                                        </div>
                                    </div>
                                    <div class="col-xs-6 col-sm-4">
                                        <div class="form-group">
                                            <label for="">Cidade <span>*</span></label>
                                            <input type="text" name="cidade" class="form-control" maxlength="60">
                                            <input type="hidden" name="codigo_ibge">
                                        </div>
                                    </div>
                                    <div class="col-xs-6 col-sm-4">
                                        <div class="form-group">
                                            <label for="">Estado <span>*</span></label>
                                            <select name="estado" id="estado" class="form-control">
                                                <option>...</option>
                                                <option value="AC">Acre</option>
                                                <option value="AL">Alagoas</option>
                                                <option value="AP">Amapá</option>
                                                <option value="AM">Amazonas</option>
                                                <option value="BA">Bahia</option>
                                                <option value="CE">Ceará</option>
                                                <option value="DF">Distrito Federal</option>
                                                <option value="ES">Espírito Santo</option>
                                                <option value="GO">Goias</option>
                                                <option value="MA">Maranhão</option>
                                                <option value="MT">Mato Grosso</option>
                                                <option value="MS">Mato Grosso do Sul</option>
                                                <option value="MG">Minas Gerais</option>
                                                <option value="PA">Pará</option>
                                                <option value="PB">Paraíba</option>
                                                <option value="PR">Paraná</option>
                                                <option value="PE">Pernambuco</option>
                                                <option value="PI">Piauí</option>
                                                <option value="RJ">Rio de Janeiro</option>
                                                <option value="RN">Rio Grande do Norte</option>
                                                <option value="RS">Rio Grande do Sul</option>
                                                <option value="RO">Rondônia</option>
                                                <option value="RR">Roraima</option>
                                                <option value="SC">Santa Catarina</option>
                                                <option value="SP">São Paulo</option>
                                                <option value="SE">Sergipe</option>
                                                <option value="TO">Tocantins</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-inline mt-3">
                                <hr>

                                <div class="check-custom mb-4">
                                    <label>
                                        <input type="checkbox" id="newsletter" name="newsletter" value="1" checked>
                                        <i class="fa fa-square-o"></i><i class="fa fa-check-square-o"></i> Desejo assinar a
                                        newsletter e receber mensagens de lançamentos e promoções no meu e-mail.
                                    </label>
                                </div>

                                <h6 class="clear mb-4">Ao criar uma conta, você concorda com a nossa <a
                                        href="https://jullietcongelados.com.br/politicas-de-privacidade"
                                        target="_blank">Política de Privacidade</a>.</h6>
                                <button type="button" class="btn btn-large" @click="submitForm">Salvar
                                    cadastro</button>
                                <input type="hidden" name="url_redirect">
                                <input type="hidden" name="acao" value="inserir" />
                                <input type="hidden" name="g-recaptcha-response" id="g-recaptcha-response">

                            </div>
                        </form>


                    </div>
                </div>
            </div>
        </main>
    </MainLayout>
</template>
  
<script>
import MainLayout from '@/layouts/MainLayout.vue';
import { mapActions, mapState } from 'vuex';
export default {
    components: {
        MainLayout
    },
    data() {
        return {
            tipopessoa: "juridica",
            email: 'usuario@exemplo.com',
            password: 'senha123',
            form: {
                tipopessoa: 'fisica', // Defina o tipo de pessoa padrão como 'fisica'
                nome: '',
                sobrenome: '',
                cpf: '',
                nome_empresa: '',
                responsavel: '',
                sobrenome_empresa: '',
                cnpj: '',
                isento: false,
                inscricao_estadual: ''
            }
        }
    },
    computed: {
        ...mapState('auth', ['user']), // Mapeia as propriedades 'loading' e 'error' do estado 'auth'
    },
    methods: {
        ...mapActions('auth', ['register']), // Mapeia a ação 'register' do módulo 'auth'
        async submitForm() {
            try {
                console.log({
                    form: this.form
                })

                // Chama a ação 'register' do Vuex passando os dados do formulário
                const result = await this.register({
                    email: this.email,
                    password: this.password,
                    ...this.form
                });
                console.log({
                    result,
                    test: {
                        email: this.email,
                        password: this.password,
                        ...this.form
                    }
                })
            } catch (error) {
                console.log({
                    error
                })
            }
        },
    }

}
</script>
  
<template>
    <div class="carousel-container" ref="container">
        <div class="carousel" ref="carousel" @scroll="updateButtonVisibility">
            <div class="product-list">
                <ProductComponent v-for="(product, index) in products" :key="index" :product="product" />
            </div>
        </div>
        <div class="prev-wrapper">
            <button v-show="showPrev" class="prev" @click="prevProduct">&#10094;</button>
        </div>
        <div class="next-wrapper">
            <button v-show="showNext" class="next" @click="nextProduct">&#10095;</button>
        </div>
    </div>
</template>

<script>
import ProductComponent from '@/components/ProductComponent.vue';

export default {
    name: 'ShowcaseProductList',
    components: {
        ProductComponent
    },
    props: {
        products: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            prevVisible: false,
            nextVisible: true
        };
    },
    computed: {
        containerWidth() {
            const container = this.$refs.container;
            return container ? container.offsetWidth : 0;
        },
        carouselWidth() {
            const carousel = this.$refs.carousel;
            return carousel ? carousel.scrollWidth : 0;
        },
        scrollLeft() {
            const carousel = this.$refs.carousel;
            return carousel ? carousel.scrollLeft : 0;
        },
        showPrev() {
            return this.prevVisible;
        },
        showNext() {
            return this.nextVisible;
        }
    },
    methods: {
        prevProduct() {
            const container = this.$refs.carousel;
            const scrollAmount = container.offsetWidth;
            this.smoothScroll(container, container.scrollLeft - scrollAmount);
        },
        nextProduct() {
            const container = this.$refs.carousel;
            const scrollAmount = container.offsetWidth;
            this.smoothScroll(container, container.scrollLeft + scrollAmount);
        },
        updateButtonVisibility() {
            const container = this.$refs.carousel;
            this.prevVisible = container.scrollLeft > 0;
            this.nextVisible = container.scrollLeft < (this.carouselWidth - this.containerWidth);
        },
        adjustCarouselSize() {
            const container = this.$refs.container;
            const carousel = this.$refs.carousel;
            if (container && carousel) {
                const containerWidth = container.offsetWidth;
                carousel.style.width = containerWidth + 'px';
            }
        },
        smoothScroll(element, destination, duration = 0) {
            const start = element.scrollLeft;
            const distance = destination - start;
            const startTime = performance.now();

            const easeInOutQuad = (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t);

            const animateScroll = (currentTime) => {
                const elapsedTime = currentTime - startTime;
                element.scrollLeft = easeInOutQuad(elapsedTime / duration) * distance + start;

                if (elapsedTime < duration) {
                    requestAnimationFrame(animateScroll);
                } else {
                    element.scrollLeft = destination;
                }
            };

            requestAnimationFrame(animateScroll);
        }
    },
    mounted() {
        this.adjustCarouselSize();
        window.addEventListener('resize', this.adjustCarouselSize);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.adjustCarouselSize);
    }
};
</script>

<style>
.carousel-container {
    width: 100%;
    overflow: hidden;
    position: relative;
}

.carousel {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
    /* Suaviza o scroll */
}

.product-list {
    display: flex;
    flex-wrap: nowrap;
}

.product-list>* {
    flex: 0 0 auto;
    scroll-snap-align: start;
    /* Defina a largura máxima desejada para os produtos */
    max-width: 300px;
    min-width: 300px;
    min-height: 100%;
}

.prev-wrapper,
.next-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 30px;
    /* Ajuste conforme necessário */
    background-color: transparent;
    /* Tornar a div envolvente transparente */
}

.prev-wrapper {
    left: 0;
}

.next-wrapper {
    right: 0;
}

.prev,
.next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    z-index: 1;
    border-radius: 90%;
}

.prev-wrapper,
.next-wrapper {
    background-color: transparent;
}


</style>

import { database, dbRef, dbSet, dbGet, dbRemove } from '@/firebase/index';

// Função para criar um novo usuário no banco de dados
export const createUser = (uid, shopId, userData) => {
    const userRef = dbRef(database, `shops/${shopId}/users/${uid}`);
    return dbSet(userRef, userData);
};

// Função para ler os dados de um usuário do banco de dados
export const getUser = (uid, shopId) => {
    const userRef = dbRef(database, `shops/${shopId}/users/${uid}`);
    return dbGet(userRef);
};

// Função para atualizar os dados de um usuário no banco de dados
export const updateUser = (uid, shopId, updatedUserData) => {
    const userRef = dbRef(database, `shops/${shopId}/users/${uid}`);
    return dbSet(userRef, updatedUserData);
};

// Função para excluir os dados de um usuário do banco de dados
export const deleteUser = (uid, shopId) => {
    const userRef = dbRef(database, `shops/${shopId}/users/${uid}`);
    return dbRemove(userRef);
};

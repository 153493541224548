<template>
    <div class="js-overlay-html" v-show="show">
        <form @submit.prevent="cadastrarEndereco" name="frm_endereco_int" id="form-cadastro-endereco"
            autocomplete="off">
            <div class="dsac-modal dsac-fade" :class="{ 'show': show }" id="modalEnderecoInt" data-bs-backdrop="static"
                data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
                <div
                    class="dsac-modal-dialog dsac-modal-dialog-centered dsac-modal-dialog-scrollable dsac-modal-fullscreen-md-down dsac-modal-lg">
                    <div class="dsac-modal-content">
                        <div class="dsac-modal-header" id="js-modal-end-int">
                            <h2>Cadastrar novo endereço</h2>
                            <button type="button" @click="fecharModalEnderecoInt" class="dsac-btn-close"
                                data-bs-dismiss="modal" aria-label="Fechar">X</button>
                        </div>
                        <div class="dsac-modal-body">
                            <div class="dsac-row">
                                <div class="dsac-col-12 dsac-mb-3 dsac-mb-sm-4">
                                    <p class="dsac-small">Campos com <span class="dsac-text-danger">*</span> são
                                        obrigatórios.</p>
                                </div>
                                <div class="dsac-col-12 dsac-col-sm-6 dsac-col-md-4">
                                    <div class="dsac-form-group dsac-form-icon">
                                        <label for="cep" class="dsac-form-label">CEP <span>*</span></label>
                                        <div class="dsac-group">
                                            <input type="tel" id="cep" class="dsac-form-control dsac-js-cep"
                                                v-model="endereco.cep" @input="verificarCEP" autocomplete="off">
                                            <i
                                                class="dsac-fa dsac-la-spinner dsac-la-spin dsac-d-none dsac-js-icon-loading"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="dsac-col-12 dsac-col-sm-6 dsac-col-md-4">
                                    <div class="dsac-form-group">
                                        <label for="endereco" class="dsac-form-label">Endereço <span>*</span></label>
                                        <input type="text" id="endereco" class="dsac-form-control"
                                            v-model="endereco.endereco" autocomplete="off">
                                    </div>
                                </div>
                                <div class="dsac-col-12 dsac-col-sm-6 dsac-col-md-4">
                                    <div class="dsac-form-group">
                                        <label for="numero" class="dsac-form-label">Número <span>*</span></label>
                                        <input type="text" id="numero" class="dsac-form-control"
                                            v-model="endereco.numero" maxlength="5" autocomplete="off">
                                    </div>
                                </div>
                                <div class="dsac-col-12 dsac-col-sm-6 dsac-col-md-4">
                                    <div class="dsac-form-group">
                                        <label for="complemento" class="dsac-form-label">Complemento</label>
                                        <input type="text" id="complemento" class="dsac-form-control"
                                            v-model="endereco.complemento" autocomplete="off">
                                    </div>
                                </div>
                                <div class="dsac-col-12 dsac-col-sm-6 dsac-col-md-4">
                                    <div class="dsac-form-group">
                                        <label for="bairro" class="dsac-form-label">Bairro <span>*</span></label>
                                        <input type="text" id="bairro" class="dsac-form-control"
                                            v-model="endereco.bairro" autocomplete="off">
                                    </div>
                                </div>
                                <div class="dsac-col-12 dsac-col-sm-6 dsac-col-md-4">
                                    <div class="dsac-form-group">
                                        <label for="cidade" class="dsac-form-label">Cidade <span>*</span></label>
                                        <input type="text" id="cidade" class="dsac-form-control"
                                            v-model="endereco.cidade" autocomplete="off">
                                    </div>
                                </div>
                                <div class="dsac-col-12 dsac-col-sm-6 dsac-col-md-4">
                                    <div class="dsac-form-group">
                                        <label for="estado" class="dsac-form-label">Estado <span>*</span></label>
                                        <select id="estado" class="dsac-form-select" v-model="endereco.estado">
                                            <option value="">...</option>
                                            <option value="AC">Acre</option>
                                            <option value="AL">Alagoas</option>
                                            <option value="AP">Amapá</option>
                                            <option value="AM">Amazonas</option>
                                            <option value="BA">Bahia</option>
                                            <option value="CE">Ceará</option>
                                            <option value="DF">Distrito Federal</option>
                                            <option value="ES">Espírito Santo</option>
                                            <option value="GO">Goiás</option>
                                            <option value="MA">Maranhão</option>
                                            <option value="MT">Mato Grosso</option>
                                            <option value="MS">Mato Grosso do Sul</option>
                                            <option value="MG">Minas Gerais</option>
                                            <option value="PA">Pará</option>
                                            <option value="PB">Paraíba</option>
                                            <option value="PR">Paraná</option>
                                            <option value="PE">Pernambuco</option>
                                            <option value="PI">Piauí</option>
                                            <option value="RJ">Rio de Janeiro</option>
                                            <option value="RN">Rio Grande do Norte</option>
                                            <option value="RS">Rio Grande do Sul</option>
                                            <option value="RO">Rondônia</option>
                                            <option value="RR">Roraima</option>
                                            <option value="SC">Santa Catarina</option>
                                            <option value="SP">São Paulo</option>
                                            <option value="SE">Sergipe</option>
                                            <option value="TO">Tocantins</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="dsac-modal-footer">
                            <button type="submit"
                                class="dsac-btn dsac-btn-primary dsac-w-xs-100 dsac-order-sm-2">Cadastrar e
                                Selecionar</button>
                            <button type="button" class="dsac-btn dsac-btn-white dsac-w-xs-100 dsac-order-sm-1"
                                @click="fecharModalEnderecoInt">Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
    props: {
        show: Boolean
    },
    data() {
        return {
            endereco: {
                cep: '',
                endereco: '',
                numero: '',
                complemento: '',
                bairro: '',
                cidade: '',
                estado: ''
            },
            timer: null
        };
    },
    computed: {
        ...mapState('checkout', ['enderecoModalInt'])
    },
    mounted() {
        this.endereco = ""
        this.endereco = this.enderecoModalInt
        console.log(this.enderecoModalInt, 'mounted')

    },
    methods: {
        ...mapActions('checkout',
            [
                'fecharModalEnderecoInt',
                'adicionarEndereco',
                'pesquisarCEP'
            ]),
        verificarCEP() {
            clearTimeout(this.timer);
            if (this.endereco.cep.length === 8) {
                this.timer = setTimeout(async () => {
                    await this.pesquisarCEP(this.endereco.cep);
                    this.endereco = this.enderecoModalInt
                    console.log(this.enderecoModalInt, 'verificar cep')
                }, 1000); // Espera 1 segundo após o término da digitação para pesquisar o CEP
            }
        },
        async cadastrarEndereco() {
            await this.adicionarEndereco({ ...this.endereco });
            this.fecharModalEnderecoInt();
            this.resetForm()
        },
        resetForm() {
            this.endereco = {
                cep: '',
                endereco: '',
                numero: '',
                complemento: '',
                bairro: '',
                cidade: '',
                estado: ''
            }
        }
    }
};
</script>

<style scoped>
.dsac-modal-dialog-centered {
    top: 10%;
}
</style>

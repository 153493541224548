<template>
    <div class="dsac-col-12 dsac-col-lg-4 dsac-mb-4 dsac-mb-lg-0">
        <div class="dsac-card-white">
            <h4 class="dsac-mb-1">Cupom de desconto</h4>
            <div class="dsac-d-sm-inline-flex dsac-align-items-center dsac-w-100 dsac-my-3">
                <div class="dsac-mb-2 dsac-mb-sm-0 dsac-w-100 dsac-pe-sm-2">
                    <label for="cupom" class="dsac-sr-only">Cupom</label>
                    <input type="text" class="dsac-form-control" id="cupom" v-model="cupom"
                        placeholder="Digite seu cupom" autocomplete="off">
                </div>
                <button class="dsac-btn dsac-btn-primary dsac-btn-auto dsac-w-xs-100 dsac-ml-sm-2"
                    @click="submitCoupon">Aplicar</button>
            </div>
            <div id="boxCupom" class="dsac-js-item-cupom-txt">{{ cupomStatus }}</div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
    data() {
        return {
            cupom: '',
        };
    },
    computed: {
        ...mapState('checkout', ['cupomValido']),
        cupomStatus() {
            if (this.cupomValido) {
                return 'Cupom válido';
            } else {
                return 'Cupom inválido';
            }
        },
    },
    methods: {
        ...mapActions('checkout', ['aplicarCupom']),
      async  submitCoupon() {
           await this.aplicarCupom(this.cupom)
        }
    },
};
</script>

<template>
    <div class="dsac-col-12 dsac-col-sm-6 dsac-mb-3 dsac-mb-xxl-4" :key="item.payment.value" @click="selectPaymentMethod(item.index)">
        <div class="dsac-form-check dsac-mod-card">
            <input class="dsac-form-check-input" type="radio" :value="item.payment.value" :name="item.payment.name" :checked="isChecked">
            <label class="dsac-form-check-label" :for="item.payment.id">
                <div class="dsac-card-white dsac-mod-pay">
                    <div class="dsac-pay-icon">
                        <img class="dsac-img-fluid" :src="item.payment.iconSrc" :alt="item.payment.iconAlt">
                    </div>
                    <div class="dsac-pay-content">
                        <h4 class="dsac-mb-2">{{ item.payment.title }}</h4>
                        <h6>{{ item.payment.subtitle }}</h6>
                        <h5 class="dsac-text-success dsac-mt-1" v-if="item.payment.discount.active"><strong>{{ item.payment.discount.value * 100 + " %" }}</strong></h5>
                    </div>
                </div>
            </label>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            isChecked: false // Propriedade de dados local para controlar o estado do input
        };
    },
    methods: {
        ...mapActions('checkout', ['selectPaymentMethod'])
    },
    beforeMount() {
        this.isChecked = true; 
        this.selectPaymentMethod(0)
    }
}
</script>

<style scoped>
/* Estilos do componente aqui */
</style>

<template>
    <div class="row">
        <div class="col-xs-12">
            <div class="box-calcular-frete">
                <h5>Calcular frete</h5>
                <div class="group-flex">
                    <input type="text" class="form-control js-cep-mask" name="produto_cep" placeholder="Digite seu CEP"
                        autocomplete="off">
                    <a href="javascript:" onclick="calculaFreteNoProduto()" class="btn btn-2 js-frete-btn">Calcular</a>
                    <img src="/assets/img/ico_loading.svg" alt="aquarde" style="display: none; margin-left: 5px;"
                        class="js-frete-load" width="38" height="38">
                </div>
                <div class="group">
                    <h6 class="title js-frete-titulo" style="display:none">Opções de Entrega
                    </h6>
                    <div class="js-frete-lista"></div>
                </div>
            </div>
        </div>
    </div>
</template>

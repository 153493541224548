<template>
    <li :class="{ 'breadcrumb-item': true, 'active': isActive }">
        <router-link v-if="!isActive" :to="{ path: url }">{{ label }}</router-link>
        <span v-else>{{ label }}</span>
    </li>
</template>

<script>
export default {
    name: 'BreadcrumbItem',
    props: {
        label: {
            type: String,
            required: true
        },
        url: {
            type: String,
            default: '#'
        },
        isActive: {
            type: Boolean,
            default: false
        }
    }
}
</script>

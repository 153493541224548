import { database, dbRef, dbSet, dbGet, dbPush } from '@/firebase/index';

export const createLike = (uid, shopId, likeData, productId) => {
    const likeRef = dbRef(database, `shops/${shopId}/users/${uid}/likes/${productId}`);
    return dbSet(likeRef, likeData);
}
export const createLike2 = (uid, shopId, likeData, productId) => {
    const likeRef = dbRef(database, `shops/${shopId}/users/${uid}/likes/${productId}`);
    return new Promise((resolve, reject) => {
        const newLikeRef = dbPush(likeRef);
        const newLikeUid = newLikeRef.key;
        if (newLikeUid) {
            resolve({
                [newLikeUid]: likeData
            });
        } else {
            reject("Erro ao gerar UID para o novo endereço.");
        }
    });
}
export const getLikes = async (uid, shopId) => {
    try {
        const likesRef = dbRef(database, `shops/${shopId}/users/${uid}/likes`);
        const snapshot = await dbGet(likesRef);

        if (snapshot.exists()) {
            // Converte o objeto de likes em um array
            const likesArray = (snapshot.val());
            return likesArray;
        }

    } catch (error) {
        console.error('Erro ao buscar favoritos:', error);
        throw error;
    }
}


export const getLike = (uid, shopId, likeId) => {
    const likeRef = dbRef(database, `shops/${shopId}/users/${uid}/likes/${likeId}`);
    return dbGet(likeRef);
}

export const updateLike = (uid, shopId, likeId, updatedLikeData) => {
    const likeRef = dbRef(database, `shops/${shopId}/users/${uid}/likes/${likeId}`);
    return dbSet(likeRef, updatedLikeData);
};

export const deleteLike = (uid, shopId, productId) => {
    const likeRef = dbRef(database, `shops/${shopId}/users/${uid}/likes/${productId}`);
    return dbSet(likeRef, null);

}

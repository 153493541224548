import { getCategoriesByShopId, getCategoryByCategoryId } from "@/services/categories";
import {
    getProductByProductId,
    getProductsByProductIdList,
    getProductsSameByProducSametId,
    getProductsSameByproductSameIdList,
    getSealBySealId,
    getSealsBySealIdList
} from "@/services/products";

const state = {
    categorySelected: {},
    categorySelectedProducts: [],
    categoriesTest: {},
    productsSameTest: {},
    productsTest: {},
    sealsTest: {},
    initalDataShop: null,
    sealsProcess: null,
    categoryWishTest: {},
    groupSeals: {},
    productsList: [],
    categoryFiltered: []
};

const mutations = {
    SET_CATEGORY(state, categoryId) {
        console.log(state.categoriesTest, categoryId)

        const category = state.categoriesTest[categoryId]
        state.categorySelected = category

    },
    SET_CATEGORY_PRODUCTS2(state) {
        state.categorySelectedProducts = state.categorySelected.products
    },
    SET_CATEGORY2(state, categoryId) {
        const index = state.categories.findIndex(c => c.id === categoryId);
        if (index !== -1) {
            const category = state.categories[index]
            state.categorySelected = category
            console.log(category, index)
        } else {
            alert(index)
        }
    },
    SET_CATEGORY_PRODUCTS(state) {
        state.categorySelectedProducts = state.categorySelected.products
    },
    SET_CATEGORIES_TEST(state, categories) {
        state.categoriesTest = categories
    },
    SET_PRODUCTS_SAME_TEST(state, productsSame) {
        state.productsSameTest = productsSame
    },
    SET_PRODUCTS_TEST(state, products) {
        state.productsTest = products
    },
    SET_SEALS_TEST(state, seals) {
        state.sealsTest = { ...state.sealsTest, ...seals }
    },
    SET_PRODUCTS_SAME_T(state, produtSame) {
        state.productsSameTest = { ...state.productsSameTest, ...produtSame }
    },
    SET_PRODUCTS_T(state, product) {
        state.productsTest = { ...state.productsTest, ...product }
    },
    SET_CATEGORIES_T(state, category) {
        state.categoriesTest = { ...state.categoriesTest, ...category }
    },
    SET_CATEGORY_WISH_T(state, categories) {
        state.categoryWishTest = categories//{ ...state.categoryWishTest, ...category }
    },
    SET_SEALS_PROCESS(state, seals) {
        state.sealsProcess = seals
    },
    SET_INITIAL_SHOP(state, initalDataShop) {
        if (state.initalDataShop) {
            state.initalDataShop = [...state.initalDataShop, ...initalDataShop]
        } else {
            state.initalDataShop = initalDataShop
        }
    },
    SET_GROUP_SEALS(state, groupSeals) {
        state.groupSeals = { ...state.groupSeals, ...groupSeals }
    },
    SET_PRODUCTS_LIST(state, productsList) {
        state.productsList = productsList
        console.log({
            'SET_PRODUCTS_LIST': state.productsList
        })
    },
    DELETE_PRODUCT_FROM_WISH_LIST(state, { categoryId, productSameId }) {
        delete state.categoryWishTest[categoryId].products[productSameId]
    },
    DELETE_CATEGORY_FROM_WISH_LIST(state, { categoryId }) {
        delete state.categoryWishTest[categoryId]
    },
    SET_FILTERED_CATEGORY_PRODUCTS(state, categoryFiltered) {
        state.categoryFiltered = categoryFiltered
    }

}

const actions = {
    setCategory({ commit }, categoryId) {
        commit('SET_CATEGORY', categoryId)
        commit('SET_CATEGORY_PRODUCTS')
    },
    async getCategories({ commit }) {
        try {
            const shopId = "1"
            const categories = await getCategoriesByShopId(shopId)
            commit('SET_CATEGORIES_TEST', categories)
            //dispatch('shop/getProductsSame', categories, { root: true })
        } catch (error) {
            console.error(error)
        }


    },
    async getCategoriesAndProductsSame({ commit, dispatch, state: { categoriesTest } }) {
        try {
            const shopId = "1"
            let categories = null
            if (!categoriesTest) {
                categories = await getCategoriesByShopId(shopId)
                commit('SET_CATEGORIES_TEST', categories)
                dispatch('shop/getProductsSame', categories, { root: true })
            }
        } catch (error) {
            console.error(error)
        }


    },
    async getProductsSame({ commit }, categories) {
        try {
            const shopId = "1"
            for (const category of Object.values(categories)) {
                const productsSame = await getProductsSameByproductSameIdList(shopId, category.productsSame)
                commit('SET_PRODUCTS_SAME_TEST', productsSame)
                //      dispatch('shop/getProducts', productsSame, { root: true })
            }
        } catch (error) {
            console.error(error)
        }
    },
    async getProducts({ commit }, productsSame) {
        try {
            const shopId = "1"
            const products = await getProductsByProductIdList(shopId, productsSame)
            commit('SET_PRODUCTS_TEST', products)
            //dispatch('shop/getSeals', products, { root: true })

        } catch (error) {
            console.error(error)
        }


    },
    async getSeals({ commit }, products) {
        try {
            const shopId = "1"
            const seals = await getSealsBySealIdList(shopId, products)
            commit('SET_SEALS_TEST', seals)

        } catch (error) {
            console.error(error)
        }
    },
    async getSealsBySealIdObjects({ commit, state: { sealsTest } }, sealIdObjects) {
        try {
            const shopId = "1"
            const seals = {}
            for (const s of Object.values(sealIdObjects)) {
                let seal = {}
                if (sealsTest[s.sealId]) {
                    seal = sealsTest[s.sealId]
                    seals[s.sealId] = seal
                } else {
                    seal = await getSealBySealId(shopId, s.sealId)
                    seals[s.sealId] = seal
                }

            }
            commit('SET_SEALS_TEST', seals)
            commit('SET_SEALS_PROCESS', Object.values(seals))

        } catch (error) {
            console.error(error)
        }
    },
    async initialShop2({ commit, dispatch, state: { categoriesTest, productsSameTest, sealsProcess } }) {
        const categories = []
        dispatch('shop/getCategoriesAndProductsSame', null, { root: true })
        for (const keyCategory in categoriesTest) {
            const category = categoriesTest[keyCategory]
            for (const keyProductSame in category.productsSame) {
                const { productSameId } = category.productsSame[keyProductSame]
                const productSame = productsSameTest[productSameId]
                const selectedProductId = productSame.selectedProduct
                const product = await getProductByProductId('1', selectedProductId)
                dispatch('shop/getSealsBySealIdObjects', product.seals, { root: true })
                const seals = sealsProcess
                productSame.measurementsOptions[selectedProductId] = {
                    ...product,
                    seals
                }
                const productMeasurementsOptions = {
                    ...productSame,
                    measurementsOptions: Object.values(productSame.measurementsOptions)
                }
                category.products = [productMeasurementsOptions]
            }
            categories.push(category)
        }
        commit('SET_INITIAL_SHOP', categories)
    },
    async initialShop({ commit, state: { categoriesTest, productsSameTest, productsTest, sealsTest } }) {
        let categoriesT = categoriesTest || await getCategoriesByShopId('1')
        for (const keyCategory in categoriesT) {
            const category = categoriesT[keyCategory]
            const categoryId = category.categoryId

            for (const keyProductSame in category.productsSame) {
                const { productSameId } = category.productsSame[keyProductSame]
                const productSame = productsSameTest[productSameId] || await getProductsSameByProducSametId('1', productSameId)
                commit('SET_PRODUCTS_SAME_T', { [productSameId]: productSame })
                const selectedProductId = productSame.selectedProduct
                const product = productsTest[selectedProductId] || await getProductByProductId('1', selectedProductId)
                commit('SET_PRODUCTS_T', { [selectedProductId]: product })
                const seals = []
                for (const sealKey in product.seals) {
                    const { sealId } = product.seals[sealKey]
                    const seal = sealsTest[sealKey] || await getSealBySealId('1', sealId)
                    seals.push(seal)
                    commit('SET_SEALS_TEST', { [sealId]: seal })
                }

                productSame.measurementsOptions[selectedProductId] = {
                    ...product,
                    seals
                }
                const productMeasurementsOptions = {
                    ...productSame,
                    measurementsOptions: Object.values(productSame.measurementsOptions)
                }
                category.products = [productMeasurementsOptions]
            }
            commit('SET_CATEGORIES_T', { [categoryId]: category })

        }
        console.log('fim')
    },
    async loadMisingProduct2({ commit, state: { categoriesTest, productsSameTest, productsTest, sealsTest } }) {
        try {
            const startTime = performance.now();
            let categoriesT = categoriesTest || await getCategoriesByShopId('1')
            for (const keyCategory in categoriesT) {
                const category = categoriesT[keyCategory]
                const categoryId = category.categoryId
                for (const keyProductSame in category.productsSame) {
                    const { productSameId } = category.productsSame[keyProductSame]
                    const productSame = productsSameTest[productSameId] || await getProductsSameByProducSametId('1', productSameId)
                    commit('SET_PRODUCTS_SAME_T', { [productSameId]: productSame })

                    for (const keyMeasurementsOptions in productSame.measurementsOptions) {
                        const { productId } = productSame.measurementsOptions[keyMeasurementsOptions]

                        const product = productsTest[productId] || await getProductByProductId('1', productId)
                        commit('SET_PRODUCTS_T', { [productId]: product })
                        const seals = []
                        for (const sealKey in product.seals) {
                            const { sealId } = product.seals[sealKey]
                            const seal = sealsTest[sealKey] || await getSealBySealId('1', sealId)
                            seals.push(seal)
                            commit('SET_SEALS_TEST', { [sealId]: seal })
                        }

                        productSame.measurementsOptions[productId] = {
                            ...product,
                            seals
                        }

                        const productMeasurementsOptions = {
                            ...productSame,
                            measurementsOptions: Object.values(productSame.measurementsOptions)
                        }
                        category.products = [productMeasurementsOptions]
                        console.log({
                            product,
                            productMeasurementsOptions,
                            productSame,
                            category,
                            test: 'tem ser'
                        })
                    }
                    commit('SET_CATEGORIES_T', { [categoryId]: category })
                }
            }
            const endTime = performance.now();
            const executionTime = endTime - startTime;
            console.log('Tempo de execução (milissegundos):', executionTime);

            console.log({
                msg: 'loadMissingProduct',
                categoriesT
            });
        } catch (error) {
            console.error(error)
        }
    },
    async deleteProductFromWishList({ commit, state: { categoryWishTest } }, product) {
        try {
            const { categoryId, productSameId } = product
            console.log({
                categoryWishTest
            })

            if (!categoryWishTest[categoryId]) {
                return
            }
            if (categoryWishTest[categoryId].products[productSameId]) {
                commit('DELETE_PRODUCT_FROM_WISH_LIST', { categoryId, productSameId })
            }
            if (Object.values(categoryWishTest[categoryId].products).length === 0) {
                commit('DELETE_CATEGORY_FROM_WISH_LIST', { categoryId })
            }
        } catch (error) {
            console.error(error)
        }
    },
    async loadMisingProduct({ commit, state: { categoriesTest, productsSameTest, productsTest, sealsTest } }) {
        try {
            const startTime = performance.now();
            let categoriesT = categoriesTest || await getCategoriesByShopId('1');

            for (const keyCategory in categoriesT) {
                const category = categoriesT[keyCategory];
                const { categoryId } = category;

                // Inicializa a lista de produtos para esta categoria
                const categoryProducts = [];

                // Itera sobre os produtos relacionados nesta categoria
                for (const keyProductSame in category.productsSame) {
                    const { productSameId } = category.productsSame[keyProductSame];

                    // Obtém os detalhes do produto ou recupera do cache
                    const productSame = productsSameTest[productSameId] || await getProductsSameByProducSametId('1', productSameId);
                    commit('SET_PRODUCTS_SAME_T', { [productSameId]: productSame });

                    // Inicializa a lista de opções de medição para este produto
                    const measurementsOptions = [];

                    // Itera sobre as opções de medição do produto
                    for (const keyMeasurementsOptions in productSame.measurementsOptions) {
                        const { productId } = productSame.measurementsOptions[keyMeasurementsOptions];

                        // Obtém os detalhes do produto ou recupera do cache
                        const product = productsTest[productId] || await getProductByProductId('1', productId);
                        commit('SET_PRODUCTS_T', { [productId]: { categoryId, productSameId, ...product } });

                        // Inicializa a lista de selos para este produto
                        const seals = [];

                        // Itera sobre os selos do produto
                        for (const sealKey in product.seals) {
                            const { sealId } = product.seals[sealKey];
                            const seal = sealsTest[sealKey] || await getSealBySealId('1', sealId);
                            seals.push(seal);
                            commit('SET_SEALS_TEST', { [sealId]: seal });
                        }

                        // Adiciona a opção de medição com os selos ao array
                        measurementsOptions.push({
                            ...product,
                            seals
                        });
                    }

                    // Adiciona as opções de medição ao produto comum
                    productSame.measurementsOptions = measurementsOptions;

                    // Adiciona o produto comum à lista de produtos da categoria
                    categoryProducts.push(productSame);
                }

                // Atualiza a categoria com os produtos montados
                console.log({ categoryId, category })
                category.products = categoryProducts;
                commit('SET_CATEGORIES_T', { [categoryId]: category });
            }

            const endTime = performance.now();
            const executionTime = endTime - startTime;
            console.log('Tempo de execução (milissegundos):', executionTime);

            console.log({
                msg: 'loadMissingProduct',
                categoriesT
            });
        } catch (error) {
            console.error(error)
        }
    },
    async loadProductsFromWishList({ commit, state: { categoriesTest, productsTest, productsSameTest, sealsTest } }, productsSameList) {
        try {
            console.log({ productsSameList, nao: false })
            const startTime = performance.now();
            const categoriesWish = {}
            for (const productSameObject of productsSameList) {
                const { categoryId, productSameId } = productSameObject
                const category = categoriesTest[categoryId] || await getCategoryByCategoryId('1', categoryId);
                //commit('SET_CATEGORIES_T', { [categoryId]: category })



                // Obtém os detalhes do produto ou recupera do cache
                const productSame = productsSameTest[productSameId] || await getProductsSameByProducSametId('1', productSameId);
                //commit('SET_PRODUCTS_SAME_T', { [productSameId]: categoryId, productSame });

                // Inicializa a lista de opções de medição para este produto
                const measurementsOptions = [];

                // Itera sobre as opções de medição do produto

                for (const keyMeasurementsOptions in productSame.measurementsOptions) {
                    const { productId } = productSame.measurementsOptions[keyMeasurementsOptions];

                    // Obtém os detalhes do produto ou recupera do cache
                    const product = productsTest[productId] || await getProductByProductId('1', productId);
                    commit('SET_PRODUCTS_T', { [productId]: { categoryId, productSameId, ...product } });

                    // Inicializa a lista de selos para este produto
                    const seals = [];
                    // Itera sobre os selos do produto
                    for (const sealKey in product.seals) {
                        const { sealId } = product.seals[sealKey];
                        const seal = sealsTest[sealKey] || await getSealBySealId('1', sealId);
                        seals.push(seal);
                        commit('SET_SEALS_TEST', { [sealId]: seal });
                    }

                    // Adiciona a opção de medição com os selos ao array
                    measurementsOptions.push({
                        ...product,
                        seals
                    });
                }

                // Adiciona as opções de medição ao produto comum
                productSame.measurementsOptions = measurementsOptions;
                categoriesWish[categoryId] = {
                    ...category,
                    products: {
                        ...categoriesWish[categoryId]?.products,
                        [productSameId]: productSame
                    }
                }

            }
            commit('SET_CATEGORY_WISH_T', categoriesWish);
            const endTime = performance.now();
            const executionTime = endTime - startTime;
            console.log('Tempo de execução (milissegundos):', executionTime);

            console.log({
                msg: 'loadProductsFromWishList',

            });
        } catch (error) {
            console.error(error)
        }
    },
    async setGroupSeals({ commit, rootGetters, state }) {
        const categories = rootGetters['shop/getProductsFromWishListShop'];
        const sealCount = {};

        // Função auxiliar para lidar com operações assíncronas dentro do loop
        const processSeals = async (seals) => {
            for (const s of seals) {
                // Verificar se o selo já existe no contador
                const seal = state.sealsTest[s.sealId] || await getSealBySealId('1', s.sealId);
                if (s.sealId in sealCount) {
                    // Se sim, incrementar a contagem
                    sealCount[s.sealId].quantity++;
                } else {
                    // Se não, iniciar a contagem em 1
                    sealCount[s.sealId] = {
                        quantity: 1,
                        ...seal
                    };
                }
            }
        };

        // Iterar sobre cada categoria
        for (const category of categories) {
            // Iterar sobre cada produto na categoria
            for (const productId in category.products) {
                const product = category.products[productId];
                // Iterar sobre as opções de medidas do produto
                for (const option of product.measurementsOptions) {
                    await processSeals(option.seals);
                }
            }
        }

        console.log({ sealCount });
        commit('SET_GROUP_SEALS', sealCount)
    },
    async getProductsByProductIds({ commit, state: { productsTest, sealsTest } }, productIdList) {
        try {
            const startTime = performance.now();
            console.log({
                productIdList
            })
            // Inicializa a lista de opções de medição para este produto
            const measurementsOptions = [];

            // Itera sobre as opções de medição do produto

            for (const keyMeasurementsOptions of productIdList) {
                const { codigo: productId, quantidade: quantity } = keyMeasurementsOptions

                // Obtém os detalhes do produto ou recupera do cache
                const product = productsTest[productId] || await getProductByProductId('1', productId);
                //commit('SET_PRODUCTS_T', { [productId]: { , ...product } });

                // Inicializa a lista de selos para este produto
                const seals = [];
                // Itera sobre os selos do produto
                for (const sealKey in product.seals) {
                    const { sealId } = product.seals[sealKey];
                    const seal = sealsTest[sealKey] || await getSealBySealId('1', sealId);
                    seals.push(seal);
                    commit('SET_SEALS_TEST', { [sealId]: seal });
                }

                // Adiciona a opção de medição com os selos ao array
                measurementsOptions.push({
                    ...product,
                    seals,
                    quantity
                });
            }

            console.log({
                measurementsOptions,
                msg: 'getProductsByProductIdList'
            })
            commit('SET_PRODUCTS_LIST', measurementsOptions)

            const endTime = performance.now();
            const executionTime = endTime - startTime;
            console.log('Tempo de execução (milissegundos):', executionTime);

            console.log({
                msg: 'loadProductsFromWishList',

            });
        } catch (error) {
            console.error(error)
        }
    },
    setProductsFilteredCategorySelected({ commit }, filteredProducts) {
        commit('SET_FILTERED_CATEGORY_PRODUCTS', filteredProducts)
    }
};

const getters = {
    getCategories({ categories }) {
        return categories
    },
    getCategorySelected({ categorySelected }) {
        return categorySelected
    },
    getCategorySelectedProducts({ categorySelectedProducts }) {
        return categorySelectedProducts
    },
    getCategoriesSideBar({ categoriesTest }) {
        return Object.values(categoriesTest).map(({ link }) => ({ ...link }))
    },
    getCategoriesT({ categoriesTest }) {
        console.log({
            categoriesTest
        })
        delete categoriesTest.undefined
        console.log({
            categoriesTest,
            after: true
        })
        return Object.values(categoriesTest)
    },
    getGroupSeals({ groupSeals }) {
        return Object.values(groupSeals)
    },
    getProductsFromWishListShop({ categoryWishTest }) {
        return Object.values(categoryWishTest).map(category => ({
            ...category,
            products: Object.values(category.products)
        }))
    },
    getProductsList({ productsList }) {
        return productsList
    },
    getCategoryFiltered({ categoryFiltered }) {
        return categoryFiltered
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

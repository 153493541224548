<template>
    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-7 galeria-fotos-produtos">
        <div class="row">
            <!-- Capa -->
            <div class="item-capa">
                <div class="col-xs-12">
                    <div style="height:230px;width:345px;" class="zoomWrapper">
                        <img id="galeriaProdutos" class="img-responsive" :src="capaImage" :data-zoom-image="capaImage"
                            :title="capaTitle" :alt="capaAlt" style="position: absolute; width: 345px; height: 230px;" />
                        <div v-show="loading"
                            style="background: url('/assets/img/ico_loading.svg') no-repeat center;height:230px;width:345px;z-index: 2000;position: absolute; background-position: center center;">
                        </div>
                    </div>
                </div>
            </div>

            <!-- Thumb -->
            <div class="col-xs-12">
                <div id="galeria_01" class="item-thumb">
                    <div v-for="(thumb, index) in thumbs" :key="index" class="item" :class="{ active: thumb.active }">
                        <a href="#" @click.prevent="updateCapaImage(thumb.image)" :data-image="thumb.image"
                            :data-zoom-image="thumb.image">
                            <img :src="thumb.image" class="img-responsive" :title="thumb.title" :alt="thumb.alt" width="51"
                                height="34" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    props: {
        capaImage: {
            type: String,
            required: true,
        },
        capaTitle: {
            type: String,
            required: true,
        },
        capaAlt: {
            type: String,
            required: true,
        },
        thumbs: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
        };
    },
    methods: {
        updateCapaImage(image) {
            this.loading = true;
            this.$emit('update:capaImage', image);
            setTimeout(() => {
                this.loading = false;
            }, 1000);
        },
    },
};
</script>
  
<!-- LoginPage.vue -->
<template>
    <MainLayout>
        <!-- Banner internas -->
        <section class="banner-interna"
            style="background-image: url(https://jullietcongelados.com.br/lojas/juliettecongelados/theme/assets/img/ban-interna-1.jpg);">
            <div class="pg-titulo">
                <div class="container">
                    <div class="row">
                        <div class="col-xs-12">
                            <h1 class="animated fadeIn">Meu Carrinho</h1>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- Content -->
        <ShoppingCartContent />


    </MainLayout>
</template>

<script>
import MainLayout from '@/layouts/MainLayout.vue';
import ShoppingCartContent from '@/components/ShoppingCartContent.vue'
export default {
    components: {
        MainLayout,
        ShoppingCartContent
    },
    data() {
        return {
            email: 'usuario@exemplo.com',
            password: 'senha123'
        }
    },
    methods: {
        login() {
            // Chamar a action de login da store Vuex
            this.$store.dispatch('login', { email: this.email, password: this.password })
        }
    }
}
</script>

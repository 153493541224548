<template>
    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-5 box-desc box-produto-interno">


        <h1 class="nome-produto">{{ productIn.name }}</h1>

        <!-- TRUSTVOX -->
        <!--  -->

        <p class="info">Cód. Barras:
            <span class="produto-codigo">
                PRD00288
            </span>
        </p>
        <p class="info">|</p>
        <p class="info"><span class="kcal">504</span> calorias</p>

        <!-- Se o produto for de Consulta -->

        <div class="desc">
            <p><b id="docs-internal-guid-e3ecf5c5-7fff-12bd-2cd9-ce6a6e1f5064">{{ productIn.description }}</b></p>
            <!-- 
            <p>
                <meta charset="utf-8">
            </p> -->

            <p dir="ltr">&nbsp;</p>
        </div>

        <div class="group-selos">
            <span v-for="(seal, index) in productIn.seals" :key="index" class="item-selo" data-toggle="tooltip"
                data-placement="top" :title="seal">
                <picture>
                    <img :src="seal.image" :alt="seal.title" />
                </picture>
            </span>
        </div>

        <!-- Mais cores -->

        <!-- Midias -->

        <!-- Guia de Medidas -->


        <div class="escolher">
            <div class="tipo">
                <select class="form-control" v-model="measurementsOptionsSelected">
                    <option v-for="(option, index) in productIn.measurementsOptions" :value="option" :key="index"
                        :selected="option.selected">
                        {{ option.text }}
                    </option>
                </select>
                <input type="hidden" :name="productIn.estoqueName" :value="productIn.estoqueValue" />
                <input type="hidden" :value="productIn.codValor" :name="productIn.codValorName" />
                <input type="hidden" :value="productIn.productId" :name="productIn.codProdutoName" />
                <input type="hidden" :value="productIn.tipo" :name="productIn.tipoName" />
                <input type="hidden" :value="productIn.url" :name="productIn.urlName" />
            </div>
            <div class="quantidade">
                <div class="box-number">
                    <div class="add-qtd" @click="minus"><i class="fa fa-minus"><span>-</span></i></div>
                    <input type="text" name="quantidade" class="qtd" v-model="quantity" />
                    <div class="add-qtd" @click="plus"><i class="fa fa-plus"><span>+</span></i></div>
                </div>
            </div>
        </div>
        <div class="group-valor" v-if="!productIn.discount.active">
            <div class="js-valor js-desconto valor valor-desconto new-price 2">{{ getProductPrice }}
            </div>
        </div>
        <div class="group-valor" v-else>
            <div class="js-valor-desc valor old-price">{{ getDiscountInPercentege }} OFF</div>
            <div class="js-valor valor old-price">de <span>{{ getProductPrice }}</span></div>
            <div class="js-desconto valor-desconto new-price 1">por {{ getValueProductWithDiscount }}</div>
        </div>




        <div class="box-adicionar adicionar clickable">
            <span @click="buy">COMPRAR</span>
        </div>



        <!-- FAVORITOS -->
        <span class="box-favorito clickable" @click="handleClick">
            <i :id="'tst-' + productIn.productId"
                :class="{ 'fa fa-heart': isFavorite, 'fa fa-heart-o': !isFavorite }"></i>
        </span>

        <!-- FIM FAVORITOS -->


        <input type="hidden" value="59" name="cod_produto">


        <!-- Calcular Frete -->
        <CalculateShipping />

    </div>
</template>

<script>
import { mapActions } from 'vuex';
import CalculateShipping from '@/components/DetailsProductPage/CalculateShipping.vue';

export default {
    props: {
        product: {
            type: Object,
            required: true
        }
    },
    components: {
        CalculateShipping
    },
    beforeMount() {
        // Modify the product prop here
        // this.product.name = 'Modified Product Name';
        // this.product.price = 'R$ 19.99';
        // ... other modifications
        this.productIn = {
            ...this.product,
            ...this.product.measurementsOptions.find(option => option.selected)
        }
        console.log({
            productIn: this.productIn
        })
        this.measurementsOptionsSelected = this.product.measurementsOptions.find(option => option.selected)
        // Note that modifying the prop directly may not trigger reactivity.
        // If you need reactivity, consider using local data properties instead.
        //this.price = this.product.price;
    },
    data() {
        return {
            quantity: 1,
            measurementsOptionsSelected: {},
            productPrice: this.product.measurementsOptions.find(option => option.selected).price,
            isFavorite: this.product.isFavorite,
            productIn: {}

        };
    },
    watch: {
        measurementsOptionsSelected(product) {
            this.productIn = {
                ...this.productIn,
                ...product,
                link: `/details/${product.productId}`
            }
            console.log({
                product
            })
        }
    },
    computed: {
        getProductPrice() {
            return this.productIn.price
        },
        getDiscountInPercentege() {
            return this.floatToPercentage(this.productIn.discount.value)
        },
        getValueProductWithDiscount() {
            if (this.productIn.discount.active) {

                // Obtém o preço atual e remove o símbolo 'R$' e quaisquer espaços em branco
                let precoAtual = parseFloat(this.productIn.price.replace('R$', '').replace(',', '.').trim());

                // Calcula o novo preço multiplicado por 5/3
                let novoPreco = precoAtual * (1 - this.productIn.discount.value);

                // Formata o novo preço para exibir como valor em reais com duas casas decimais
                let novoPrecoFormatado = novoPreco.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

                return novoPrecoFormatado;

            }
            return 0
        },
    },
    methods: {
        ...mapActions('cart', ['addProduct']),
        ...mapActions('user', ['removeFavorite', 'addFavorites']),
        minus() {
            if (this.quantity > 1) {
                this.quantity--;
            }
        },
        plus() {
            this.quantity++;
        },
        buy() {
            const product = {
                //...this.productIn,
                quantity: this.quantity,
                ... this.measurementsOptionsSelected,
                dataSrc: this.productIn.dataSrc,
                name: this.productIn.name,
                link: this.productIn.link
            };
            this.addProduct(product);
            this.quantity = 1
        },
        handleClick() {
            //if (this.$store.getters['auth/isLoggedIn']) {
            if (this.$store.state.auth.isLoggedIn) {
                // Usuário está logado, realizar ação de dar like
                this.likeAction();
            } else {
                // Usuário não está logado, redirecionar para a página de login
                this.$router.push('/login');
            }
        },
        async likeAction() {
            this.isFavorite = !this.isFavorite
            const result = await this.addFavorites({
                test: 4
            })
            console.log(result)
        },
        floatToPercentage(num) {
            let rounded = Math.round(num * 100); // Arredonda o número para o inteiro mais próximo
            let percentageStr = rounded + "%";
            return percentageStr;
        }
    }
};
</script>

<template>
    <div class="col-xs-12 animated fadeIn">
      <div class="tabela-3" v-if="orders.length">
        <table class="tabela-padrao">
          <!-- Tabela de pedidos -->
          <thead>
            <tr>
              <th width="13%"></th>
              <th width="16%" class="text-center">Pedido Nº</th>
              <th width="17%" class="text-center">Data do Pedido</th>
              <th width="18%" class="text-center">Valor</th>
              <th width="18%" class="text-center">Forma de Pagamento</th>
              <th width="18%" class="text-center">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(pedido, index) in displayedOrders" :key="index">
              <td><router-link :to="{ path: 'orders/' + pedido.id }" class="btn-link">Ver Detalhes</router-link></td>
              <td data-th="Pedido Nº" class="text-center">{{ pedido.numero }}</td>
              <td data-th="Data do Pedido" class="text-center">{{ pedido.data }}</td>
              <td data-th="Valor" class="text-center">{{ pedido.valor }}</td>
              <td data-th="Forma de Pagamento" class="text-center">{{ pedido.formaPagamento }}</td>
              <td data-th="Status" class="text-center">{{ pedido.status }}</td>
            </tr>
          </tbody>
        </table>
        <!-- Paginação -->
        <nav aria-label="Page navigation example">
          <ul class="pagination">
            <!-- Botão "Previous" -->
            <li class="page-item">
              <button type="button" class="page-link" @click="prevPage" :disabled="page === 1">Previous</button>
            </li>
            <!-- Números das páginas -->
            <li class="page-item" v-for="pageNumber in displayedPages" :key="pageNumber" :class="{ 'active': pageNumber === page }">
              <button type="button" class="page-link" @click="page = pageNumber">{{ pageNumber }}</button>
            </li>
            <!-- Botão "Next" -->
            <li class="page-item">
              <button type="button" class="page-link" @click="nextPage" :disabled="page === totalPages">Next</button>
            </li>
          </ul>
        </nav>
      </div>
      <div class="alert alert-warning" v-else>
        Não existe nenhum pedido no momento.
      </div>
    </div>
  </template>
  
  <script>
  import { mapActions, mapState } from 'vuex';
  
  export default {
    computed: {
      ...mapState('user', ['orders']),
      totalPages() {
        return Math.ceil(this.orders.length / this.perPage);
      },
      displayedOrders() {
        const start = (this.page - 1) * this.perPage;
        return this.orders.slice(start, start + this.perPage);
      },
      displayedPages() {
        const currentPage = this.page;
        const totalPages = this.totalPages;
        const interval = 5; // Definindo o intervalo de 5 páginas
        let start = currentPage - Math.floor(interval / 2);
        if (start < 1) {
          start = 1;
        }
        let end = start + interval - 1;
        if (end > totalPages) {
          end = totalPages;
          start = end - interval + 1;
          if (start < 1) {
            start = 1;
          }
        }
        return Array.from({ length: end - start + 1 }, (_, i) => start + i);
      },
    },
    methods: {
      ...mapActions('user', ['getOrdersUser']),
      nextPage() {
        if (this.page < this.totalPages) {
          this.page++;
        }
      },
      prevPage() {
        if (this.page > 1) {
          this.page--;
        }
      },
    },
    data() {
      return {
        page: 1,
        perPage: 5,
      };
    },
    mounted() {
      this.getOrdersUser();
    },
  };
  </script>
  
  <style scoped>
  /* Estilos para a paginação */
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination .page-link {
    color: rgb(161, 66, 17);
    padding: 0.5rem 0.75rem; /* Ajuste o padding conforme necessário */
    font-size: 1rem; /* Tamanho da fonte */
  }
  
  .pagination .page-link:hover {
    background-color: #f0f0f0;
  }
  
  .pagination .page-item.active .page-link {
    background-color: rgb(161, 66, 17);
    border-color: rgb(161, 66, 17);
    color: #f0f0f0;
  }
  
  .pagination .page-item.disabled .page-link {
    pointer-events: none;
  }
  
  .pagination .page-item:first-child .page-link,
  .pagination .page-item:last-child .page-link {
    border-radius: 0;
  }
  
  .pagination .page-item:first-child .page-link {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  
  .pagination .page-item:last-child .page-link {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  
  /* Estilos responsivos */
  @media (max-width: 576px) {
    .pagination .page-link {
      padding: 0.25rem 0.5rem; /* Reduza o padding para telas menores */
      font-size: 0.9rem; /* Reduza o tamanho da fonte para telas menores */
    }
  }
  </style>
  
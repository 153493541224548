<template>
    <MainLayout>
        <!-- Resto do template -->

        <form id="form-novasenha" name="frm_senha" @submit.prevent="changePassword" class="form-loja">
            <div class="col-xs-12">
                <label for="currentPassword">Senha atual*:</label>
                <div class="form-group">
                    <input type="password" class="form-control" id="currentPassword" v-model="currentPassword"
                        autocomplete="off">
                </div>
            </div>
            <div class="col-xs-12">
                <label for="newPassword">Nova senha*:</label>
                <div class="form-group">
                    <input type="password" class="form-control" id="newPassword" v-model="newPassword" autocomplete="off">
                </div>
            </div>
            <div class="col-xs-12">
                <label for="confirmNewPassword">Confirme a nova senha*:</label>
                <div class="form-group">
                    <input type="password" class="form-control" id="confirmNewPassword" v-model="confirmNewPassword"
                        autocomplete="off">
                </div>
            </div>
            <div class="col-xs-12">
                <p class="txt-campo-obrigatorio">(*) Campos obrigatórios</p>
            </div>
            <div class="col-xs-12">
                <div class="form-group">
                    <button type="submit" class="btn btn-wide" id="btenviar">Enviar »</button>
                    <input type="hidden" name="acao" value="alterar">
                </div>
            </div>
        </form>

        <!-- Resto do template -->
    </MainLayout>
</template>
  
<script>
import { mapActions } from 'vuex';
import MainLayout from '@/layouts/MainLayout.vue';

export default {
    components: {
        MainLayout
    },
    data() {
        return {
            currentPassword: '',
            newPassword: '',
            confirmNewPassword: ''
        };
    },
    methods: {
        ...mapActions('auth', ['changePassword']),
        changePassword() {
            if (this.validatePassword()) {
                this.changePassword({
                    newPassword: this.newPassword,
                    confirmNewPassword: this.confirmNewPassword
                }).then(() => {
                    // Senha alterada com sucesso
                    // Realizar ações adicionais, se necessário
                }).catch(error => {
                    console.log(error)
                    // Lidar com erros ao alterar a senha
                });
            }
        },
        validatePassword() {
            if (this.newPassword !== this.confirmNewPassword) {
                // Exibir mensagem de erro, informando que as senhas não coincidem
                return false;
            }
            return true;
        }
    }
};
</script>
  
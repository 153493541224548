<template>
  <CheckoutHeader />
  <CheckoutMain />
  <CheckoutFooter />
</template>
<script>
import CheckoutHeader from '@/components/Checkout/CheckoutHeader.vue'
import CheckoutMain from '@/components/Checkout/CheckoutMain.vue'
import CheckoutFooter from '@/components/Checkout/CheckoutFooter.vue'
export default {
  components: {
    CheckoutHeader,
    CheckoutMain,
    CheckoutFooter
  }
}
</script>
<style>
* {
  font-size: 1rem !important;
}
</style>

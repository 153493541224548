<!-- LoginPage.vue -->
<template>
    <MainLayout>
        <!-- Banner internas -->
        <section class="banner-interna"
            style="background-image: url(https://jullietcongelados.com.br/lojas/juliettecongelados/theme/assets/img/ban-interna-1.jpg);">
            <div class="pg-titulo">
                <div class="container">
                    <div class="row">
                        <div class="col-xs-12">
                            <h1 class="animated fadeIn">Você saiu!</h1>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- Vitrine -->
        <section class="vitrine-home">
            <div class="container">
                <div class="row">

                    <!-- Content Right -->
                    <div class="col-xs-12">
                        <div class="row">
                            <div class="alert alert-info">Você redirencionado para a página home. Muito obrigado pela
                                preferência</div>
                        </div>
                    </div>

                </div>

            </div>
        </section>
    </MainLayout>
</template>

<script>
import MainLayout from '@/layouts/MainLayout.vue';
import { mapActions } from 'vuex';
export default {
    components: {
        MainLayout
    },
    data() {
        return {
            route: '/',
        }
    },
    mounted() {
        this.handlerLogout();

    },
    methods: {
        ...mapActions('auth_google', ['signOut']),
        async handlerLogout() {
            try {
                await this.signOut()
                this.redirectToHome(3000)
            } catch (error) {
                console.log({
                    error
                })
            }
        },
        redirectToHome(time) {
            setTimeout(() => {
                this.$router.push(this.route);
            }, time);
        }
    }

}
</script>

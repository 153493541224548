<template>
    <div class="col-xs-12 nav-custom-scroll">
        <ul class="nav nav-custom-icon nav-categorias">
            <MenuItemCategoriesHorizontal v-for="item in getCategoriesSideBar" :item="item" :key="item.title" />
        </ul>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import MenuItemCategoriesHorizontal from './MenuItemCategoriesHorizontal.vue';

export default {
    components: {
        MenuItemCategoriesHorizontal
    },
    name: 'MenuCategoriesHorizontal',
    props: {

    },
    data() { 
        return {
            items: [
                {
                    title: 'Promoções',
                    link: '/promotions',
                    image: {
                        webp: '/assets/img/conteudo/uploads/icons8-desconto-64-645a7e33bedf3.webp',
                        png: '/assets/img/conteudo/uploads/icons8-desconto-64-645a7e33bedf3.png'
                    }
                },
                {
                    title: 'Kits',
                    link: '/kits',
                    image: {
                        webp: '/assets/img/conteudo/uploads/icon-kits-612fbf564c522-1-628542a329f8c.webp',
                        png: '/assets/img/conteudo/uploads/icon-kits-612fbf564c522-1-628542a329f8c.png'
                    }
                },
                {
                    title: 'Pratos',
                    link: '/meal',
                    image: {
                        png: '/assets/img/conteudo/uploads/icon-pratos-prontos-612fbf7a46726.png'
                    }
                },
                {
                    title: 'Fit',
                    link: '/fit',
                    image: {
                        png: '/assets/img/conteudo/uploads/icon-fit-low-carb-612fc16bda702.png'
                    }
                },
                {
                    title: 'Vegetariano',
                    link: '/vegetarian',
                    image: {
                        png: '/assets/img/conteudo/uploads/icon-vegetariano-vegano-612fbfc756ed7.png'
                    }
                },
                {
                    title: 'Sopas',
                    link: '/soups',
                    image: {
                        png: '/assets/img/conteudo/uploads/icon-sopas-612fc086f1d57.png'
                    }
                },
                {
                    title: 'Massas',
                    link: '/pastas',
                    image: {
                        png: '/assets/img/conteudo/uploads/icon-massas-molhos-612fc053b1205.png'
                    }
                },
                {
                    title: 'Sobremesas',
                    link: '/dessert',
                    image: {
                        webp: '/assets/img/conteudo/uploads/icon-sobremesas-sorvetes-614b7980b9d20.webp',
                        png: '/assets/img/conteudo/uploads/icon-sobremesas-sorvetes-614b7980b9d20.png'
                    }
                }
            ]
        };
    },
    computed: {
        ...mapGetters('shop', ['getCategoriesSideBar'])
    }

}
</script>

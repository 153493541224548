<template>
    <section class="container-fluid order">
        <div class="row">
            <div class="faixa-info">
                <div class="container">
                    <div class="row">
                        <InfoItemComponent v-for="item in infoItems" :key="item.title" :image="item.image" :alt="item.alt"
                            :title="item.title" :description="item.description" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
  
<script>
import InfoItemComponent from '@/components/InfoItemComponent.vue';

export default {
    name: 'InfoComponent',
    components: {
        InfoItemComponent
    },
    data() {
        return {
            infoItems: [
                {
                    image: '/assets/img/conteudo/uploads/icon-faixa-1svg-612fb67e281f0.svg',
                    alt: 'O frete é por nossa conta para São Paulo e Região em compras acima de R$250',
                    title: 'Frete',
                    description: 'O frete é por nossa conta para Rio de Janeiro (Ramos, Olaria) em compras acima de R$299,00'
                },
                {
                    image: '/assets/img/conteudo/uploads/icon-faixa-2svg-612fb67e28cd9.svg',
                    alt: 'Escolha o dia e período (manhã, tarde, noite) de sua entrega',
                    title: 'Período',
                    description: 'Escolha o dia de sua entrega'
                },
                {
                    image: '/assets/img/conteudo/uploads/icon-faixa-3svg-612fb67e29646.svg',
                    alt: 'Alimentos congelados preparados com carinho e qualidade para você!',
                    title: 'Alimentos congelados',
                    description: 'Alimentos congelados preparados com carinho e qualidade para você!'
                }
            ]
        };
    }
};
</script>
  
<template>
    <div>
      <div class="content">
        <div ref="slider" class="slider">
          <div class="slider-touch-left">
            <span></span>
          </div>
          <div class="slider-touch-right">
            <span></span>
          </div>
          <div ref="sliderBall" class="slider-ball"></div>
          <div class="slider-line">
            <span ref="sliderValue"></span>
          </div>
        </div>
      </div>
      <div id="result">{{ resultText }}</div>
    </div>
  </template>
  
  <style scoped>
  .content {
      width: 320px;
  }
  
  .slider {
      display: block;
      position: relative;
      height: 36px;
      width: 100%;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      -o-user-select: none;
      user-select: none;
  }
  
  .slider .slider-touch-left,
  .slider .slider-touch-right {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      display: block;
      position: absolute;
      height: 36px;
      width: 36px;
      padding: 6px;
      z-index: 2;
  }
  
  .slider .slider-touch-left span,
  .slider .slider-touch-right span {
      display: block;
      width: 100%;
      height: 100%;
      background: #F0F0F0;
      border: 1px solid #A4A4A4;
      border-radius: 50%;
  }
  
  .slider .slider-ball {
      position: absolute;
      top: 16px;
      left: 18px;
      width: 20px;
      height: 4px;
      background-color: orange;
      border-radius: 4px;
      transform: translateX(-50%);
      z-index: 1;
  }
  
  .slider .slider-line {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      position: absolute;
      width: calc(100% - 36px);
      left: 18px;
      top: 16px;
      height: 4px;
      border-radius: 4px;
      background: #F0F0F0;
      z-index: 0;
      overflow: hidden;
  }
  
  .slider .slider-line span {
      display: block;
      height: 100%;
      width: 0%;
      background: orange;
  }
  </style>
  
  <script>
  export default {
    data() {
      return {
        minValue: 0,
        maxValue: 140,
        resultText: "Min: 0 Max: 140"
      };
    },
    mounted() {
      const vm = this;
  
      const slider = this.$refs.slider;
      const sliderBall = this.$refs.sliderBall;
      const sliderValue = this.$refs.sliderValue;
  
      let isDragging = false;
  
      slider.addEventListener("mousedown", startDragging);
      slider.addEventListener("mouseup", stopDragging);
      slider.addEventListener("mousemove", drag);
      slider.addEventListener("touchstart", startDragging);
      slider.addEventListener("touchend", stopDragging);
      slider.addEventListener("touchmove", drag);
  
      function startDragging(event) {
        isDragging = true;
        drag(event);
      }
  
      function stopDragging() {
        isDragging = false;
      }
  
      function drag(event) {
        if (isDragging) {
          let mouseX = event.pageX || event.touches[0].pageX;
          let sliderX = slider.getBoundingClientRect().left;
          let percent = (mouseX - sliderX) / slider.offsetWidth;
          percent = Math.min(Math.max(0, percent), 1);
          let value = percent * (vm.maxValue - vm.minValue) + vm.minValue;
  
          sliderValue.style.width = percent * 100 + "%";
          sliderBall.style.left = percent * 100 + "%";
  
          vm.resultText = `Min: ${vm.minValue.toFixed(0)} Max: ${value.toFixed(0)}`;
        }
      }
    }
  };
  </script>
  
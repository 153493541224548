<template>
    <div>
        <div class="col-xs-12 col-md-3 css-desktop">
            <aside class="aside-left">
                <div class="box-nav-submenu">
                    <a href="javascript:" class="css-mobile navbar-toggle-close"><i class="fa fa-times"></i><span
                            class="sr-only">Fechar</span></a>

                    <div class="group-title menu-categorias">
                        <h4>Categorias</h4>
                    </div>
                    <ul class="nav menu-categorias">
                        <SubcategoryComponent v-for="(subcategory, index) in getCategoriesSideBar" :key="index"
                            :item="subcategory">
                        </SubcategoryComponent>
                    </ul>
                </div>

                <div class="box-sidebar css-desktop">
                    <div class="box-banner">
                        <!-- Seu conteúdo do banner lateral aqui -->
                    </div>
                </div>
            </aside>
        </div>
    </div>
</template>

<script>
import SubcategoryComponent from '@/components/SubcategoryComponent.vue';
import { mapGetters } from 'vuex';

export default {
    name: 'SidebarLeftComponent',
    components: {
        SubcategoryComponent,
    },
    computed: {
        ...mapGetters('shop', ['getCategoriesSideBar'])
    }
};
</script>

<style scoped>
/* Seus estilos específicos do componente aqui */
</style>

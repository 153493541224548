<template>
    <div class="js-overlay-html" v-show="show">
        <div class="dsac-modal dsac-fade" :class="{ 'show': show }" id="modalEndereco" data-bs-backdrop="static"
            data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
            <div
                class="dsac-modal-dialog dsac-modal-dialog-centered dsac-modal-dialog-scrollable dsac-modal-fullscreen-md-down dsac-modal-lg">
                <div class="dsac-modal-content">
                    <div class="dsac-modal-header">
                        <h2>Meus endereços</h2>
                        <button type="button" class="dsac-btn-close" @click="fecharModalEndereco"
                            data-bs-dismiss="modal" aria-label="Fechar">X
                        </button>
                    </div>
                    <div class="dsac-modal-body">
                        <div class="dsac-row">
                            <div class="dsac-col-12 dsac-mb-2 dsac-mb-sm-3">
                                <p>Selecione abaixo qual endereço que você deseja <strong>receber</strong> seus produtos
                                    e clique em Salvar, ou <strong>cadastre</strong> um novo endereço.</p>
                            </div>
                        </div>
                        <div id="js-lista-entrega" class="dsac-row">
                            <!--    <div class="dsac-col-12 dsac-col-sm-6 dsac-my-2" @click="getPosition">
                                <div class="dsac-form-check dsac-mod-card">
                                    <input class="dsac-form-check-input" type="radio" id="endereco0" value="0">
                                    <label class="dsac-form-check-label" for="endereco0">
                                        <div class="dsac-card-white">
                                            <h4>Pegar minha localizaçao atual</h4>
                                            <p class="dsac-small dsac-mt-2"></p>
                                        </div>
                                    </label>
                                </div>
                            </div> -->
                            <div v-for="(endereco, index) in getAddresses" :key="index"
                                class="dsac-col-12 dsac-col-sm-6 dsac-my-2">

                                <div class="dsac-form-check dsac-mod-card">
                                    <input class="dsac-form-check-input" type="radio" :id="'endereco' + index"
                                        :value="endereco.addressId" v-model="enderecoSelecionado">
                                    <label class="dsac-form-check-label" :for="'endereco' + index">
                                        <div class="dsac-card-white">
                                            <h4>{{ endereco.endereco }}, {{ endereco.numero }}</h4>
                                            <p class="dsac-small dsac-mt-2">{{ endereco.bairro }}, {{ endereco.cidade }}
                                                - {{ endereco.estado }}</p>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="dsac-modal-footer">
                        <button type="button" class="dsac-btn dsac-btn-primary dsac-w-xs-100 dsac-order-sm-2"
                            @click="salvarEndereco" data-bs-dismiss="modal">Salvar</button>
                        <button type="button" class="dsac-btn dsac-btn-white dsac-w-xs-100 dsac-order-sm-1"
                            @click="abrirModalEnderecoInt" data-bs-toggle="modal"
                            data-bs-target="#modalEnderecoInt">Cadastrar
                            novo endereço
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    props: {
        show: Boolean
    },
    data() {
        return {
            enderecoSelecionado: null,
            localizacao: {
                log: null,
                lng: null
            }
        };
    },
    computed: {
        ...mapGetters('user', ['getAddresses'])
    },
    methods: {
        ...mapActions('checkout', [
            'fecharModalEndereco',
            'abrirModalEnderecoInt',
            'abrirModalEnderecoInt',
            'selectAddressByAddressId',
            'getAdressByPosition',
            'getAddressesIfToExist'
        ]),
        salvarEndereco() {
            if (this.enderecoSelecionado !== null) {
                this.selectAddressByAddressId(this.enderecoSelecionado);
            }
            this.fecharModalEndereco();
        },
        getPosition() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    async position => {
                        await this.getAdressByPosition({
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        })
                        alert('pegou')
                    },
                    error => {
                        this.erro = error.message;
                    }
                );
            } else {
                this.erro = "Geolocalização não é suportada neste navegador.";
            }
        }
    },
    watch: {
        enderecoSelecionado(addressId) {
            this.selectAddressByAddressId(addressId)
        },
        async localizacao2(a, b) {
            console.log({ a, b })
            await this.getAdressByPosition({ ...a })

        }
    },
    beforeMount() {
        this.getAddressesIfToExist()
    }
};
</script>

<style scoped>
.dsac-modal-dialog-centered {
    top: 10%;
}
</style>

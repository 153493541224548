<template>
    <div class="col-xs-12">
        <article class="conteudo">

            <!-- Informações do produto -->
            <h2 class="titulo-desc">Detalhes do produto</h2>
            <p></p>
            <p><strong>Ingredientes:</strong>&nbsp;batata, filé de frango, arroz, leite
                integral, extrato de
                tomate, molho tomate Julliet (tomate, alho, cebola, cenoura, açúcar, manjericão,
                tomilho e óleo de
                soja),&nbsp;cebola, catchup, champignon, farinha de trigo, margarina, mostarda,
                sal,&nbsp;óleo de
                soja, tempero de aves ( sal, alho, páprica picante, alecrim, tomilho,
                manjericão), molho inglês, noz
                e alho.</p>

            <p>&nbsp;</p>

            <p><strong>CONTÉM GLÚTEN. CONTÉM LACTOSE.</strong></p>

            <p>&nbsp;</p>

            <p><strong>ALÉRGICOS: CONTÉM TRIGO/CENTEIO/CEVADA/AVEIA, LEITE, DERIVADOS DE SOJA,
                    TRIGO/CENTEIO/CEVADA/AVEIA, LEITE E TRIGO.</strong> <strong>PODE CONTER
                    CRUSTÁCEOS (CAMARÃO), OVO,
                    PEIXE, AMENDOIM, AMÊNDOA, AVELÃS, CASTANHA-DE-CAJU, CASTANHA-DO-PARÁ OU
                    CASTANHA-DO-BRASIL,
                    CASTANHAS, NOZES E LÁTEX NATURAL.</strong></p>
            <p></p>

            <!-- Texto complementar -->
            <SupplementaryText />




            <!-- Informações do kit / prato -->

        </article>
    </div>
</template>
<script>
import SupplementaryText from '@/components/DetailsProductPage/SupplementaryText.vue';
export default {
    components: {
        SupplementaryText
    }
}
</script>

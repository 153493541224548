<template>
    <MainLayout>
        <!-- Banner internas -->
        <section class="banner-interna"
            style="background-image: url(https://jullietcongelados.com.br/lojas/juliettecongelados/conteudo/uploads/6-63d855478c3e7.png);">
            <div class="pg-titulo">
                <div class="container">
                    <div class="row">
                        <div class="col-xs-12">
                            <h2 class="animated fadeIn">Pratos</h2>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- Breadcrumbs -->
        <section class="box-breadcrumb">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="https://jullietcongelados.com.br/">Home</a></li>
                                <li class="breadcrumb-item"><a
                                        href="https://jullietcongelados.com.br/pratos-prontos">Pratos</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Estrogonofe de frango, arroz e batata
                                    palha</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
        <!-- Content -->
        <main class="pg-internas">
            <div class="container">
                <div class="row">

                    <!-- Sidebar Left -->
                    <SidebarLeftComponent />

                    <!-- Detalhe Produto -->
                    <div class="col-xs-12 col-md-9 animated fadeIn">
                        <div class="row">

                            <!-- Voltar -->
                            <div class="col-xs-12">
                                <a href="https://jullietcongelados.com.br/pratos-prontos" class="btn-voltar icon-left"><i
                                        class="fa fa-arrow-left"></i>Voltar</a>
                            </div>

                            <div class="box-detalhe-produto">

                                <GaleryPhotosProduct :capaImage="capaImage" :capaTitle="capaTitle" :capaAlt="capaAlt"
                                    :thumbs="thumbs" @update:capaImage="capaImage = $event" />

                                <BoxProductIn :product="this.productIn" />

                                <!-- Descrição do Produto -->
                                <ProductDescription />

                            </div><!-- /box-detalhe-produto -->

                            <!-- Opiniões produto TRUSTVOX -->
                        

                        </div>
                    </div>

                    <!-- Produtos relacionados -->

                </div>
            </div><!-- /container -->
        </main>

        <!-- Modal Novidades-->


        <!-- Modal Snapchat e Whatsapp -->



        <div id="whatsapp-modal" class="modal-social modal fade" tabindex="-1" role="dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                        <div class="content">
                            <div class="icon"><i class="fa fa-whatsapp"></i></div>
                            <h2>WhatsApp</h2>
                            <div class="tel"><a href="tel:(11) 97702-0353">(11) 97702-0353</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </MainLayout>
</template>
  
<script>
import MainLayout from '@/layouts/MainLayout.vue';
import SidebarLeftComponent from '@/components/SidebarLeftComponent.vue'
// import DetailProduct from '@/components/DetailProductComponent.vue'
import ProductDescription from '@/components/DetailsProductPage/ProductDescription.vue';
import BoxProductIn from '@/components/DetailsProductPage/BoxProductIn.vue';
import GaleryPhotosProduct from '@/components/DetailsProductPage/GaleryPhotosProduct.vue';
export default {
    components: {
        SidebarLeftComponent,
        GaleryPhotosProduct,
        BoxProductIn,
        ProductDescription,
        MainLayout
        // DetailProduct
    },
    beforeMount() {
        let productId = this.$route.params.productId
        let productIn = this.selectProductById(productId)
        console.log({
            productId,
            productIn
        })
        this.productIn = this.products[0]
    },
    data() {
        return {
            products: [
                {
                    samesProductId: 2,
                    favoriteLink: '/login',
                    isFavorite: true,
                    link: '/details/123456',
                    lazySrcset: '/assets/img/logo-dsa.png',
                    dataSrcset: '/assets/img/logo-dsa.png',
                    lazyImg: '/assets/img/logo-dsa.png',
                    dataSrc: '/assets/img/logo-dsa.png',
                    name: 'Quibe assado com purê de abóbora, arroz e feijão.',
                    description: "O nosso arroz carreteiro, vem acompanhado de linguiça toscana acebolada e feijão. Ótima opção para o seu almoço e para aqueles que desejam saborear uma comida típica da região Sul do Brasil. Gostou da dica? É  só pedir, aquecer e bom apetite!",
                    measurementsOptions: [
                        {
                            productId: 101010,
                            price: 'R$ 14,90',
                            price2: {
                                value: 14.9,
                                unit: "R$",
                                discount: {
                                    active: true,
                                    value: .5
                                }
                            },
                            discount: {
                                active: true,
                                value: .2
                            },
                            text: '300g',
                            selected: false,
                            tag: {
                                image: "/assets/img/conteudo/uploads/etiqueta-promo.jpg",
                                type: "new",
                                active: true,
                                alt: "Promoção",
                                title: ""
                            },
                        },
                        {
                            productId: 1010100,
                            price: 'R$ 30,90',
                            text: '500g',
                            selected: true,
                            tag: {
                                image: "/assets/img/conteudo/uploads/etiqueta-promo.jpg",
                                type: "new",
                                active: true,
                                alt: "Promoção",
                                title: ""
                            },
                            discount: {
                                active: true,
                                value: .5
                            }
                        }
                    ],
                    seals: [
                        {
                            title: 'Sem lactose',
                            image: '/assets/img/conteudo/uploads/selo-semlactose-612fbe206f72f.png',
                        },
                        {
                            title: 'Cashback progressivo Dona Sol Ajuda',
                            image: '/assets/img/conteudo/uploads/selo-aniversario-desconto-632dea9f542bd.png',
                        },
                    ],
                    estoqueName: 'estoque',
                    estoqueValue: '999999',
                    codValor: '122',
                    codValorName: 'cod_valor',
                    codProdutoName: 'cod_produto',
                    tipo: 'produto',
                    tipoName: 'tipo',
                    url: 'https%3A%2F%2Fjullietcongelados.com.br%2Fquibe-assado-com-pure-de-abobora-arroz-e-feijao',
                    urlName: 'url',
                },
                {
                    samesProductId: 3,
                    favoriteLink: '/login',
                    isFavorite: true,
                    link: '/details/123456',
                    lazySrcset: '/assets/img/logo-dsa.png',
                    dataSrcset: '/assets/img/logo-dsa.png',
                    lazyImg: '/assets/img/logo-dsa.png',
                    dataSrc: '/assets/img/logo-dsa.png',
                    name: 'Quibe assado com purê de abóbora, arroz e feijão.',
                    description: "O nosso arroz carreteiro, vem acompanhado de linguiça toscana acebolada e feijão. Ótima opção para o seu almoço e para aqueles que desejam saborear uma comida típica da região Sul do Brasil. Gostou da dica? É  só pedir, aquecer e bom apetite!",
                    measurementsOptions: [
                        {
                            productId: 10101000,
                            price: 'R$ 14,90',
                            price2: {
                                value: 14.9,
                                unit: "R$",
                                discount: {
                                    active: true,
                                    value: .5
                                }
                            },
                            discount: {
                                active: true,
                                value: .2
                            },
                            text: '300g',
                            selected: false,
                            tag: {
                                image: "/assets/img/conteudo/uploads/etiqueta-promo.jpg",
                                type: "new",
                                active: true,
                                alt: "Promoção",
                                title: ""
                            },
                        },
                        {
                            productId: 101010000,
                            price: 'R$ 30,90',
                            text: '500g',
                            selected: true,
                            tag: {
                                image: "/assets/img/conteudo/uploads/etiqueta-promo.jpg",
                                type: "new",
                                active: true,
                                alt: "Promoção",
                                title: ""
                            },
                            discount: {
                                active: true,
                                value: .5
                            }
                        }
                    ],
                    seals: [
                        {
                            title: 'Sem lactose',
                            image: '/assets/img/conteudo/uploads/selo-semlactose-612fbe206f72f.png',
                        },
                        {
                            title: 'Cashback progressivo Dona Sol Ajuda',
                            image: '/assets/img/conteudo/uploads/selo-aniversario-desconto-632dea9f542bd.png',
                        },
                    ],
                    estoqueName: 'estoque',
                    estoqueValue: '999999',
                    codValor: '122',
                    codValorName: 'cod_valor',
                    codProdutoName: 'cod_produto',
                    tipo: 'produto',
                    tipoName: 'tipo',
                    url: 'https%3A%2F%2Fjullietcongelados.com.br%2Fquibe-assado-com-pure-de-abobora-arroz-e-feijao',
                    urlName: 'url',
                }


            ],
            productIn: {},
            imagens: [
                'https://octoshop.sfo2.digitaloceanspaces.com/lojas/juliettecongelados/uploads_produto/caldeirada-de-peixe-arroz-450g-6143629dd2935.jpg',
                'https://octoshop.sfo2.digitaloceanspaces.com/lojas/juliettecongelados/uploads_produto/caldeirada-de-peixe-arroz-450g-6143629dd2935.jpg',
                'https://octoshop.sfo2.digitaloceanspaces.com/lojas/juliettecongelados/uploads_produto/uma-oportunidade-muito-boa-em-diversos-itens-61-6143639c74a87.png',
            ],
            capaImage: 'https://octoshop.sfo2.digitaloceanspaces.com/lojas/juliettecongelados/uploads_produto/caldeirada-de-peixe-arroz-450g-6143629dd2935.jpg',
            thumbAtiva: 0,
            capaTitle: "test",
            capaAlt: "test",
            thumbs: [
                {
                    image: 'https://octoshop.sfo2.digitaloceanspaces.com/lojas/juliettecongelados/uploads_produto/caldeirada-de-peixe-arroz-450g-6143629dd2935.jpg',
                    title: 'Título da Miniatura 1',
                    alt: 'Descrição da Miniatura 1',
                },
                {
                    image: 'https://octoshop.sfo2.digitaloceanspaces.com/lojas/juliettecongelados/uploads_produto/caldeirada-de-peixe-arroz-450g-6143629dd2935.jpg',
                    title: 'Título da Miniatura 2',
                    alt: 'Descrição da Miniatura 2',
                },
                {
                    image: 'https://octoshop.sfo2.digitaloceanspaces.com/lojas/juliettecongelados/uploads_produto/uma-oportunidade-muito-boa-em-diversos-itens-61-6143639c74a87.png',
                    title: 'Título da Miniatura 2',
                    alt: 'Descrição da Miniatura 2',
                },
                // Adicione mais miniaturas conforme necessário
            ]

        }

    },
    methods: {
        selectProductById(productId) {
            //let selectedProduct = null;
            for (let product of this.products) {
                const foundOption = product.measurementsOptions.find((option) => option.productId === productId);
                if (foundOption) {
                    product.measurementOptions = product.measurementsOptions.map((option) => {
                        if (option.productId === productId) {
                            option.selected = true;
                        } else {
                            option.selected = false;
                        }
                        return option
                    });
                    return product;
                } else {
                    return {}
                }
            }
        },
        alterarCapa(index) {
            this.capa = this.imagens[index];
        }

    }
}
</script>
  
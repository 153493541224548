<template>
    <div>
        <li :id="'prod-' + item.productId">
            <div class="box-item-carrinho">
                <div class="img-produto">
                    <!-- Modificação do link -->
                    <router-link :to="item.link" @click="closeCarrinhoRight">
                        <img class="img-responsive" :src="item.dataSrc" alt="">
                    </router-link>
                </div>
                <div class="box-desc">
                    <!-- Modificação do link -->
                    <router-link :to="item.link" @click="closeCarrinhoRight">
                        <div class="nome-produto">{{ item.name }}</div>
                    </router-link>
                    <div class="quantidade">
                        Qtde: <span>{{ item.quantity }}</span>
                    </div>
                    <div class="valor">{{ calculateSubtotal(item) }}</div>
                    <span @click="removeProduct(item)" class="btn-excluir"><i class="fa fa-times"></i></span>
                </div>
            </div>
        </li>
    </div>
</template>
<script>
import { mapActions } from 'vuex';

export default {
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    methods: {
        ...mapActions('cart', ['removeProduct', 'closeCarrinhoRight']),
        calculateSubtotal(item) {
            let subtotal = 0;
            let price = item.price.value;
            if (item.price.discount.active) {
                price = price * (1 - item.price.discount.value)
            }
            subtotal = price * item.quantity;

            return subtotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

        }
    }
}
</script>

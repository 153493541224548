<template>
    <div class="container">
      <div class="row">
        <div class="col-xs-12">
          <div class="bg-conceito">
            <div class="bg-conceito-content">
              <h3>{{ title }}</h3>
              
              <p>&nbsp;</p>
              
              <p><strong>{{ subtitle }}</strong></p>
              
              <p>&nbsp;</p>
              
              <p>{{ description }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ConceptComponent',
    props: {
      title: {
        type: String,
        required: true
      },
      subtitle: {
        type: String,
        required: true
      },
      description: {
        type: String,
        required: true
      }
    }
  }
  </script>
  
/* eslint-disable */
function validaTexto(e) {
    return 0 == e.value.length
}
function validaCaracter(e) {
    return !new RegExp("^[a-zA-Z ]+$").test(e.value)
}
function validaOpcao(e) {
    if (isNaN(parseInt(e.length)))
        return !e.checked;
    for (var n = !0, t = 0; t < e.length; t++)
        e[t].checked && (n = !1);
    return !!n
}
function validaSelecao(e) {
    return "" == e.options[e.selectedIndex].value
}
function validaData(e) {
    e = e.value.split("/");
    return 3 != e.length || (0 == (numeros = e[0] + e[1] + e[2]).length || (e[0] < 1 || 31 < e[0] || (e[1] < 1 || 12 < e[1] || (e[2].length < 4 || e[2] < 1900))))
}
function validaDataExpiracao(e) {
    e = e.value.split("/");
    return 2 != e.length || (mes = parseInt(e[0]),
        ano = parseInt(e[1]),
        mes + ano == 0 || (mes < 1 || 12 < mes || (ano.length < 4 || ano < 1900)))
}
function validaEmail(e) {
    var n = Array("~", "!", "@", "#", "$", "%", "^", "&", "*", "(", ")", "+", "=", "[", "]", ":", ";", ",", '"', "'", "|", "{", "}", "\\", "/", "<", ">", "?", " ", "Ã§", "Ã¡", "Ã ", "Ã£", "Ã¢", "Ã©", "Ã¨", "Ãª", "Ã­", "Ã¬", "Ã®", "Ã³", "Ã²", "Ã´", "Ãµ", "Ãº", "Ã¹", "Ã»")
        , e = e.value;
    if (-1 == e.indexOf("@"))
        return !0;
    var t = e.split("@");
    if ("" == t[0] || t[0].length < 2)
        return !0;
    for (var r = 0; r < n.length; r++)
        if (-1 != t[0].indexOf(n[r]))
            return !0;
    if ("localhost" != t[1]) {
        if ("" == t[1])
            return !0;
        if (-1 == t[1].indexOf("."))
            return !0;
        if ("" == (ponto = t[1].split("."))[0] || ponto[0].length < 2)
            return !0;
        for (r = 0; r < n.length; r++)
            if (-1 != ponto[0].indexOf(n[r]))
                return !0;
        if ("" == ponto[1])
            return !0
    }
    return !1
}
function validaValor(e) {
    e = e.split(",");
    if (!(2 <= e.length))
        return !0;
    if (2 != e[1].length || 0 == e[0].length)
        return !0;
    var n = e[0].split(".");
    if (2 == n.length)
        for (var t = 1; t < n.length; t++)
            if (3 != n[t].length)
                return !0;
    return !1
}
function validaCep(e) {
    var n = Array("0", "1", "2", "3", "4", "5", "6", "7", "8", "9")
        , e = e.value
        , t = e.split("-")
        , r = 0;
    if (0 < e.length) {
        if (2 != t.length)
            return !0;
        var a = t[0] + t[1];
        if (0 == a.length)
            return !0;
        for (var i = 0; i < n.length; i++)
            for (var u = 0; u < a.length; u++)
                a.substring(u, u + 1) == n[i] && r++;
        return a.length != r ? !0 : 5 != t[0].length || 3 != t[1].length
    }
    return !0
}
function validaNumero(e) {
    var n = e.value
        , t = 0;
    if (0 == n.length)
        return !0;
    for (var r = 0; r < n.length; r++)
        for (var a = 0; a < 11; a++)
            n.substring(r, r + 1) == a && t++;
    return n.length != t
}
function validaCpf(e) {
    return !isCpf(e.value)
}
function validaCnpj(e) {
    return !isCnpj(e.value)
}
function validaIMG(e) {
    var n = e.value.split("\\");
    if ("" != n) {
        for (var t = 0, r = 0; r < n[n.length - 1].length; r++)
            " " == n[n.length - 1].substring(r, r + 1) && (t = 1);
        if (1 == t)
            return alert("NÃ£o sÃ© permitidos espaÃ§os no nome da imagem."),
                !0;
        n.split(".");
        if (".GIF" != (e = n.substr(tam - 4, 4).toUpperCase()) && ".JPG" != e)
            return alert("Para a imagem Ã© somente aceitos os formatos .JPG e .GIF"),
                !0
    }
    return !1
}
function validaNome(e) {
    e = e.value;
    if (0 < e.length && 1 < e.split(" ").length)
        return !1;
    return !0
}
function verificaTamanho(e, n, t) {
    var r = 0;
    "" != document.getElementById(e).value.length && (r += document.getElementById(e).value.length),
        1 == r && " " == document.getElementById(e).value.substring(0, 1) && (document.getElementById(e).value = "",
            r -= 1),
        t < r && (document.getElementById(e).value = document.getElementById(e).value.substring(0, t),
            r -= 1),
        document.getElementById(n).value = t - r
}
function verificaCEP(e, t) {
    $.ajax({
        data: "acao=busca_cep&cep=" + e,
        dataType: "json",
        success: function (e) {
            var n;
            $('input[name="endereco"]').val(e.rua),
                $('input[name="bairro"]').val(e.bairro),
                $('input[name="cidade"]').val(e.cidade),
                $('input[name="codigo_ibge"]').val(e.ibge),
                $('select[name="estado"]').length && (n = 0,
                    $('select[name="estado"] option').each(function () {
                        e.estado == $(this).val() && $('select[name="estado"]').prop("selectedIndex", n),
                            n++
                    })),
                "function" == typeof t && t()
        }
    })
}
function formataPreco(e) {
    for (var e = new String(parseFloat(e)), n = e.lastIndexOf("."), t = (-1 == n && (n = (e += ".00").lastIndexOf(".")),
        new String(e.substr(0, n))), e = e.substr(n + 1, 2), r = (e < 10 && (e = new String(parseInt(e) + "0")),
            t.length), a = new String; 3 < r;)
        a = "." + t.substr(r -= 3, 3) + a;
    return (a = 0 < r ? t.substr(0, r) + a : a) + "," + e
}
function convertePreco(e) {
    var n = 0;
    return n = e ? (n = e.replace(".", "")).replace(",", ".") : n
}
function diaSemana(e) {
    var e = e.split("/")
        , e = new Date(e[2], parseInt(e[1]) - 1, e[0])
        , n = new Array(7);
    return n[0] = "Domingo",
        n[1] = "Segunda-Feira",
        n[2] = "TerÃ§a-Feira",
        n[3] = "Quarta-Feira",
        n[4] = "Quinta-Feira",
        n[5] = "Sexta-Feira",
        n[6] = "SÃ¡bado",
        n[e.getDay()]
}
function setCookie(e, n, t) {
    var r = new Date
        , t = (r.setTime(r.getTime() + 24 * t * 60 * 60 * 1e3),
            "expires=" + r.toUTCString());
    document.cookie = e + "=" + n + ";" + t + ";path=/"
}
function getCookie(e) {
    for (var n = e + "=", t = decodeURIComponent(document.cookie).split(";"), r = 0; r < t.length; r++) {
        for (var a = t[r]; " " == a.charAt(0);)
            a = a.substring(1);
        if (0 == a.indexOf(n))
            return a.substring(n.length, a.length)
    }
    return ""
}
function unformatNumber(e) {
    return String(e).replace(/\D/g, "").replace(/^0+/, "")
}
function formatCpfCnpj(e, n, t) {
    null == n && (n = !0);
    var r = (t = null == t ? !1 : t) ? NUM_DIGITOS_CNPJ : NUM_DIGITOS_CPF
        , e = (e = unformatNumber(e)).lpad(r, "0");
    return e = n ? t ? (reCnpj = /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
        e.replace(reCnpj, "$1.$2.$3/$4-$5")) : (reCpf = /(\d{3})(\d{3})(\d{3})(\d{2})$/,
            e.replace(reCpf, "$1.$2.$3-$4")) : e
}
function dvCpfCnpj(e, n) {
    for (var t, r, a, i, u = (n = null == n ? !1 : n) ? NUM_DGT_CNPJ_BASE : NUM_DIGITOS_CPF, o = n ? NUM_DIGITOS_CNPJ : NUM_DIGITOS_CPF, l = (l = formatCpfCnpj(e + "00", !1, n)).substring(0, o - 2), f = "", s = 1; s <= 2; s++) {
        for (r = 2,
            a = 0,
            t = l.length - 1; 0 <= t; t--)
            a += (l.charAt(t) - "0") * r,
                r = (r - 1) % u + 2;
        l += i = 9 < (i = 11 - a % 11) ? 0 : i,
            f += i
    }
    return f
}
function isCpf(e) {
    var n = formatCpfCnpj(e, !1, !1);
    if (n.length > NUM_DIGITOS_CPF)
        return !1;
    var t, r, e = n.substring(0, n.length - 2);
    if (n != "" + e + dvCpfCnpj(e, !1))
        return !1;
    for (t = !0,
        r = 1; t && r < NUM_DIGITOS_CPF; r++)
        t = n.charAt(r - 1) == n.charAt(r);
    return !t
}
function isCnpj(e) {
    var n = formatCpfCnpj(e, !1, !0);
    if (n.length > NUM_DIGITOS_CNPJ)
        return !1;
    var t, e = n.substring(0, NUM_DGT_CNPJ_BASE), r = n.substring(NUM_DGT_CNPJ_BASE, 12);
    if (n != "" + e + r + dvCpfCnpj(e + r, !0))
        return !1;
    for (t = "0" != n.charAt(0),
        i = 1; t && i < NUM_DGT_CNPJ_BASE; i++)
        t = n.charAt(i - 1) == n.charAt(i);
    return !t && ("0000" != r && ("00000000" == e || parseInt(r, 10) <= 300 || "000" != e.substring(0, 3)))
}
function isCpfCnpj(e) {
    return (e.replace(/\D/g, "").length > NUM_DIGITOS_CPF ? isCnpj : isCpf)(e)
}
NUM_DIGITOS_CPF = 11,
    NUM_DIGITOS_CNPJ = 14,
    NUM_DGT_CNPJ_BASE = 8,
    String.prototype.lpad = function (e, n) {
        for (var t = this, r = e - t.length, a = String(n).charAt(0); 0 < r; r--)
            t = a + t;
        return t
    }
    ,
    String.prototype.trim = function () {
        return this.replace(/^\s*/, "").replace(/\s*$/, "")
    }
    ;

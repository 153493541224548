<template>
    <footer class="dsac-footer dsac-bg-secondary dsac-text-white">
        <div class="dsac-container">
            <div class="dsac-row">
                <div class="dsac-col-12">
                    <div class="dsac-d-sm-inline-flex dsac-align-items-center dsac-justify-content-between dsac-w-100">
                        <p class="dsac-text-muted">©2024 Juliette Comidas Congeladas. Todos os direitos reservados.</p>
                        <div class="dsac-group-by">
                            <img class="dsac-img-fluid dsac-img-selo" src="https://jullietcongelados.com.br/checkout/assets/img/selo-footer-pagar-me.svg" alt="Pagamento seguro" title="Pagar.me">
                            <a href="https://transparencyreport.google.com/safe-browsing/search?url=https://jullietcongelados.com.br/" aria-label="Google Safe Browsing" target="_blank" rel="noopener">
                                <img class="dsac-img-fluid" src="https://jullietcongelados.com.br/checkout/assets/img/google-safe-browsing.svg" alt="Você está em uma navegação segura." title="Google Safe Browsing">
                            </a>
                            <a href="https://bigfishdigital.com.br" target="_blank" rel="noopener" class="dsac-logo-by" aria-label="Big Fish Digital">
                                <img class="dsac-img-fluid dsac-by" src="https://jullietcongelados.com.br/checkout/assets/img/logo-by.svg" title="Big Fish Digital" alt="Construímos experiências de usabilidade em nossas plataformas para que você inove no seu negócio.">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    // Coloque as opções do componente aqui
}
</script>

<style scoped="">
.footer {
    padding: 20px 0;
    text-align: center;
}

.footer .container {
    max-width: 1200px;
}

.footer p {
    margin-bottom: 0;
}

.group-by {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.group-by img {
    margin: 0 10px;
    height: 35px;
    width: auto;
}

.logo-by img {
    height: 30px;
    width: auto;
}
</style>


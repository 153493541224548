<template>
    <div class="col-xs-12 col-sm-4">
        <div class="item-info boxHeight">
            <img class="img-responsive" :src="image" :alt="alt" :title="title">
            <p>{{ description }}</p>
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'InfoItemComponent',
    props: {
        image: {
            type: String,
            required: true
        },
        alt: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        description: {
            type: String,
            required: true
        }
    }
}
</script>
  
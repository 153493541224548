<template>
    <div class="dsac-row">
        <div class="dsac-col-12 dsac-mb-3 dsac-mb-sm-4">
            <i class="dsac-fa dsac-la-check-double dsac-icon dsac-text-success dsac-mb-2"></i>
            <h1 class="dsac-h2 dsac-mb-3">Seu pedido foi concluído com sucesso!</h1>
            <p>Todas as informações sobre sua compra foram enviadas para seu e-mail <strong></strong>.</p>
            <p>Você pode acompanhar todas as etapas de seus pedidos em nosso site no menu <router-link
                    :to="{ path: '/' }"><strong>Meus pedidos</strong></router-link>.</p>
        </div>

        <!-- Detalhes -->
        <StepPixChosenDetails />

        <!-- Formas de Pagamento -->
        <div class="dsac-col-12 dsac-col-xl-6">
            <div class="dsac-row">
                <div class="dsac-col-12 dsac-mb-3 dsac-mb-sm-4">
                    <div class="dsac-card-white">
                        <h4 class="dsac-mb-1">PIX</h4>
                        <div id="boxPagamento" class="dsac-row">
                            <div
                                class="dsac-col-12 dsac-col-sm-6 dsac-text-center dsac-mb-4 dsac-mb-sm-0 dsac-order-sm-2">
                                <div class="dsac-d-block dsac-mt-3 dsac-mt-sm-0 dsac-mb-3">
                                    <img class="dsac-img-fluid dsac-md" src="/assets/img/checkout/svg/logo-pix.svg"
                                        alt="PIX">
                                </div>
                                <div class="dsac-box-qrcode">
                                    <!-- Display the image only if qrcodeURL is not null -->
                                    <img class="dsac-img-fluid" v-if="getImagePix" :src="getImagePix" alt="Código">
                                    <!-- Optionally, you can show a placeholder or loading indicator while the image is being fetched -->
                                    <div v-else>Loading...</div>
                                </div>

                            </div>
                            <div class="dsac-col-12 dsac-col-sm-6 dsac-order-sm-1">
                                <p class="dsac-small dsac-mb-2">Aponte a câmera do seu celular para o <strong>QR
                                        Code</strong> para realizar o pagamento.</p>
                                <p class="dsac-small dsac-mb-2">Se preferir <strong>copie o código</strong> abaixo para
                                    realizar o pagamento.</p>
                                <div class="dsac-tag dsac-tag-warning dsac-mb-2">Aguardando pagamento</div>
                                <div class="dsac-form-group dsac-my-2">
                                    <label for="codigoPix" class="dsac-form-label dsac-sr-only">Código</label>
                                    <input type="text" id="codigoPix" class="dsac-form-control dsac-disabled"
                                        :value="pixChosenPaymentData.pixCode">
                                </div>
                                <button @click="copiarCodigoPIX" type="button" id="copiarCodigo"
                                    class="dsac-btn dsac-btn-primary dsac-w-100">Copiar código PIX</button>
                            </div>
                            <div class="dsac-col-12 dsac-mt-3 dsac-mt-sm-4 dsac-order-sm-3" v-if="getImagePix">
                                <div class="dsac-alert dsac-alert-info dsac-text-center">Este código de pagamento expira
                                    em:
                                    <strong>
                                        <TimerComponent :startDate="getPixChosenPaymentExpiredDate.startDate"
                                            :endDate="getPixChosenPaymentExpiredDate.endDate" />
                                    </strong>.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="dsac-col-12 dsac-text-sm-end dsac-mt-2 dsac-col-voltar">
                    <div class="dsac-d-sm-inline-flex dsac-align-items-center dsac-justify-content-end dsac-w-100">
                        <router-link :to="{ path: '/' }" @click="resetCheckout"
                            class="dsac-btn dsac-btn-white dsac-w-xs-100" role="button">Voltar para o Site</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import StepPixChosenDetails from './StepPixChosenDetails.vue'
import TimerComponent from '@/components/Shared/TimerComponent.vue'
export default {
    data() {
        return {
            pixChosenPaymentData: null,
            imageData: '', // Inicialize com uma string vazia,

        }
    },
    components: {
        StepPixChosenDetails,
        TimerComponent
    },
    computed: {
        ...mapState('checkout', ['pixChosenPayment', 'pixImageShow']),
        ...mapGetters('checkout', ['getImagePix', 'getPixChosenPaymentExpiredDate'])
    },
    async beforeMount() {
        this.pixChosenPaymentData = { ...this.pixChosenPayment }
        await this.gerarPixAssync()
    },
    watch: {
        pixImageShow() {
            alert('mudou')
        }
    },
    methods: {
        ...mapActions('checkout', ['gerarPix', 'resetCheckout']),
        async gerarPixAssync() {
            try {
                await this.gerarPix(this.$router); // Chama a action Vuex gerarPix
            } catch (error) {
                console.error('Erro ao gerar o PIX:', error);
            }
        },
        copiarCodigoPIX() {
            const input = document.getElementById('codigoPix');
            input.select();
            document.execCommand('copy');
            input.setSelectionRange(0, 0); // Reseta a seleção do texto
        }
    }
}
</script>

<style scoped>
/* Estilos específicos do componente */
</style>

export default {
    namespaced: true,
    state: {
        cart: [],
        quantityCart: 0,
        subtotal: 0,
        isCarrinhoOpen: false
    },
    getters: {
        getSubtotalFormatted(state) {
            let subtotal = 0;
            state.cart.forEach(product => {
                let price = product.price.value
                if (product.price.discount.active) {
                    price = price * (1 - product.price.discount.value)
                }
                subtotal += price * product.quantity;
            });
            return subtotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
        },
        getSubtotal(state) {
            let subtotal = 0;
            state.cart.forEach(product => {
                let price = product.price.value
                if (product.price.discount.active) {
                    price = price * (1 - product.price.discount.value)
                }
                subtotal += price * product.quantity;
            });
            return subtotal
        },
        getQuantityFromCart({ cart }) {
            return cart.reduce((a, { quantity }) => a + quantity, 0);
        },
        getCartItens({ cart }) {
            return cart;
        },
        getCartItensToCheckout({ cart }) {
            return cart.map(item => ({
                codigo: item.productId,
                descricao: item.name,
                quantidade: item.quantity,
                valorUnitario: (item.price.value * (1 - item.price.discount.value)),
                valorTotal: (item.price.value * (1 - item.price.discount.value)) * item.quantity
            }))
        }
    },
    mutations: {
        ADD_PRODUCT(state, product) {
            console.log('cart - [ADD_PRODUCT]', product)
            const existingProduct = state.cart.find(p => p.productId === product.productId);
            if (existingProduct) {
                existingProduct.quantity += product.quantity;
                state.quantityCart += product.quantity;
            } else {
                state.quantityCart += product.quantity;
                state.cart.push(product);
            }
        },
        REMOVE_PRODUCT(state, { productId }) {
            console.log({ productId }, state.cart)
            const index = state.cart.findIndex(p => p.productId === productId);
            if (index !== -1) {
                const removedProduct = state.cart[index];
                state.quantityCart -= removedProduct.quantity;
                state.cart.splice(index, 1);
            }
        },
        INCREMENT_PRODUCT_QUANTITY(state, { productId }) {
            const index = state.cart.findIndex(p => p.productId === productId);
            if (index !== -1) {
                state.cart[index].quantity++;
                state.quantityCart++;
            }
        },
        DECREMENT_PRODUCT_QUANTITY(state, { productId }) {
            const index = state.cart.findIndex(p => p.productId === productId);
            if (index !== -1 && state.cart[index].quantity > 1) {
                state.cart[index].quantity--;
                state.quantityCart--;
            }
        },
        SET_CARRINHO_OPEN(state) {
            state.isCarrinhoOpen = true;
        },
        SET_CARRINHO_CLOSE(state) {
            state.isCarrinhoOpen = false;
        },
        RESET_CART(state) {
            state.cart = [];
        }
    },
    actions: {
        addProduct({ commit }, product) {
            commit('ADD_PRODUCT', product);
        },
        removeProduct({ commit }, { productId }) {
            commit('REMOVE_PRODUCT', { productId });
        },
        incrementProductQuantity({ commit }, { productId }) {
            commit('INCREMENT_PRODUCT_QUANTITY', { productId });
        },
        decrementProductQuantity({ commit }, { productId }) {
            commit('DECREMENT_PRODUCT_QUANTITY', { productId });
        },
        openCarrinhoRight({ commit }) {
            commit('SET_CARRINHO_OPEN');
        },
        closeCarrinhoRight({ commit }) {
            commit('SET_CARRINHO_CLOSE');
        },
        resetCart({ commit }) {
            commit('RESET_CART');
        },
        addProductsList({ commit, rootGetters }) {
            try {
                const productsList = rootGetters['shop/getProductsList']
                console.log({
                    productsList,
                    'test': true
                })
                for (const product of productsList) {
                    commit('ADD_PRODUCT', product)
                }
            } catch (error) {
                console.error(error)
            }
        }
    }
};

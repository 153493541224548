<template>
    <div class="titulo-vitrine">
        <div class="group-title">
            <picture><img class="img-responsive" :src="item.image" alt="">
            </picture>
            <h3 class="titulo-box animated fadeInDown">{{ item.title }}</h3>
            <p>{{ item.description }}</p>
        </div>
    </div>
</template>
<script>
export default {
    name: "ShowcaseTitle",
    props: {
        item: {
            type: Object,
            required: true
        }
    }
}
</script>

<template>
    <main class="dsac-main">
        <div class="dsac-container">
            <!-- Steps -->
            <div class="dsac-row dsac-pb-3 dsac-pb-sm-4 dsac-pb-xxl-5">
                <div
                    class="dsac-d-md-inline-flex dsac-align-items-center dsac-justify-content-between dsac-flex-wrap dsac-w-100">
                    <div class="dsac-col-12 dsac-col-md-8">
                        <ul class="dsac-nav dsac-nav-steps">
                            <li><span :class="{ 'dsac-active': activeStepComponent === 'StepChoiceType' }"
                                    @click="setActiveStepComponent('StepChoiceType')">Endereço</span></li>
                            <li><span :class="{ 'dsac-active': activeStepComponent === 'StepShedule' }"
                                    @click="setActiveStepComponent('StepShedule')">Agendamento</span></li>
                            <li><span :class="{ 'dsac-active': activeStepComponent === 'StepCupom' }"
                                    @click="setActiveStepComponent('StepCupom')">Cupom</span></li>
                            <li><span :class="{ 'dsac-active': activeStepComponent === 'StepPaymentForm' }"
                                    @click="setActiveStepComponent('StepPaymentForm')">Pagamento</span></li>
                            <li><span :class="{ 'dsac-active': activeStepComponent === 'StepPixChosen' }"
                                    @click="setActiveStepComponent('StepPixChosen')">Pix Gerado</span></li>
                        </ul>
                    </div>
                    <!-- Direcionar para a página do carrinho do cliente -->
                    <div
                        class="dsac-col-12 dsac-col-md-4 dsac-text-md-end dsac-mt-3 dsac-mt-md-0 dsac-d-none dsac-d-sm-block">
                        <a href="/shopping-cart" class="dsac-btn-link" role="button">
                            <i class="dsac-fa dsac-la-arrow-left"></i>Voltar para o Carrinho
                        </a>
                    </div>
                </div>
            </div>
            <!-- Renderize o componente do step com base no activeStepComponent -->
            <component :is="activeStepComponent">
            </component>
        </div>
        <CheckoutModalAdress :show="modalEnderecoVisivel" />
        <CheckoutModalRegisterAdress :show="modalEnderecoIntVisivel" />
        <CheckoutShoppingCart />
    </main>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import CheckoutModalAdress from './CheckoutModalAdress.vue';
import CheckoutModalRegisterAdress from './CheckoutModalRegisterAdress.vue';
import StepChoiceType from '@/components/Checkout/Steps/StepChoiceType.vue';
import StepShedule from '@/components/Checkout/Steps/StepShedule.vue';
import StepCupom from '@/components/Checkout/Steps/StepCupom.vue';
import StepPaymentForm from '@/components/Checkout/Steps/StepPaymentForm.vue';
import StepPixChosen from '@/components/Checkout/Steps/StepPixChosen.vue';
import CheckoutShoppingCart from '@/components/Checkout/ShoppingCart.vue';

export default {
    components: {
        StepChoiceType,
        StepShedule,
        StepCupom,
        StepPaymentForm,
        StepPixChosen,
        CheckoutShoppingCart,
        CheckoutModalAdress,
        CheckoutModalRegisterAdress
    },
    methods: {
        ...mapActions('checkout', ['abrirModalEndereco', 'abrirModalEnderecoInt', 'setActiveStepComponent'])
    },
    computed: {
        ...mapState('checkout', ['modalEnderecoVisivel', 'modalEnderecoIntVisivel', 'activeStepComponent'])
    }
};
</script>

<style>
@keyframes fadeEffect {
    from {
        opacity: 0;
        visibility: hidden;
    }

    to {
        opacity: 1;
        visibility: visible;
    }
}

.dsac-fade {
    animation: fadeEffect 0.5s ease-in-out forwards;
    /* Aplica a animação de fade */
}

</style>

<template>
    <div class="toast" :class="{ 'show': showToast }">
        <div class="toast-content">
            <img :src="iconUrl" alt="Ícone do Toast" class="toast-icon">
            <div class="toast-description">{{ message }}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        showToast: {
            type: Boolean,
            default: false
        },
        message: {
            type: String,
            default: ''
        },
        iconUrl: {
            type: String,
            required: true
        }
    },
    methods: {
        hideToast() {
            this.$emit('hide-toast'); // Emitindo evento para ocultar o toast
        }
    },
    mounted() {
        if (this.showToast) {
            setTimeout(() => {
                this.hideToast(); // Ocultar o toast após 5 segundos
            }, 5000);
        }
    }
};
</script>

<style scoped>
.toast {
    visibility: hidden;
    max-width: 200px;
    height: 50px;
    margin: auto;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    position: fixed;
    z-index: 9999;
    left: 0;
    right: 0;
    bottom: 30px;
    font-size: 14px;
    transition: visibility 0s, opacity 0.5s linear;
}

.toast.show {
    visibility: visible;
    opacity: 1;
}

.toast-content {
    display: flex;
    align-items: center;
}

.toast-icon {
    width: 50px;
    height: 50px;
    padding: 12px;
    background-color: #111;
    border-radius: 50%;
    /* Se desejar um ícone redondo */
}

.toast-description {
    flex: 1;
    padding: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>

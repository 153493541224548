<template>
  <button @click="signInWithGoogle" v-if="!currentUser">Login com Google</button>
  <div v-else>
    Logged in as: {{ currentUser.email }}
    <button @click="handleSignOut">Logout</button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  computed: {
    ...mapGetters('auth_google', ['currentUser'])
  },
  methods: {
    ...mapActions('auth_google', ['signInWithGoogle', 'signOut']),
    async handleSignOut() {
      await this.signOut(this.$router);
    }

  }
};
</script>

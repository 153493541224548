<!-- LoginPage.vue -->
<template>
    <MainLayout>
        <!-- Banner internas -->
        <section class="banner-interna" style="background-image: url(/assets/img/orders/bg.jpg);">
            <div class="pg-titulo">
                <div class="container">
                    <div class="row">
                        <div class="col-xs-12">
                            <h1 class="animated fadeIn">Meus Pedidos</h1>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- Content -->
        <main class="pg-internas bg-loja">
            <div class="container">
                <div class="row">

                    <!-- Pedidos -->
                    <LoggedOrdersPageTable />
                </div>
            </div>
        </main>
    </MainLayout>
</template>

<script>
import MainLayout from '@/layouts/MainLayout.vue';
import LoggedOrdersPageTable from '@/components/Order/LoggedOrdersPageTable.vue';
export default {
    components: {
        MainLayout,
        LoggedOrdersPageTable
    }
}
</script>

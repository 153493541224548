import {
    database,
    dbRef,
    dbSet,
    dbGet,
    dbPush,
    dbRemove,
} from '@/firebase';

// Módulo Vuex
export default {
    namespaced: true,
    state: {
        myCadastre: {},
        myCredits: [],
        myFavorites: [],
        myIndications: [],
        myOrders: []
    },
    mutations: {
        // Mutação para adicionar um cadastro
        ADD_CADASTRE(state, cadastre) {
            state.myCadastre = cadastre;
        },
        // Mutação para adicionar um crédito
        ADD_CREDIT(state, credit) {
            state.myCredits.push(credit);
        },
        // Mutação para adicionar um favorito
        ADD_FAVORITE(state, favorite) {
            state.myFavorites.push(favorite);
        },
        // Mutação para adicionar uma indicação
        ADD_INDICATION(state, indication) {
            state.myIndications.push(indication);
        },
        // Mutação para adicionar um pedido
        ADD_ORDER(state, order) {
            state.myOrders.push(order);
        },
        // Mutação para atualizar um cadastro
        UPDATE_CADASTRE(state, updatedCadastre) {
            state.myCadastre = { ...state.myCadastre, ...updatedCadastre };
        },
        // Mutação para remover um favorito
        REMOVE_FAVORITE(state, favoriteId) {
            state.myFavorites = state.myFavorites.filter(favorite => favorite.id !== favoriteId);
        },
        // Mutação para remover um pedido
        REMOVE_ORDER(state, orderId) {
            state.myOrders = state.myOrders.filter(order => order.id !== orderId);
        }
    },
    actions: {
        // Ação para adicionar um cadastro
        addCadastre({ commit }, cadastre) {
            commit('ADD_CADASTRE', cadastre);
        },
        // Ação para adicionar um crédito
        addCredit({ commit }, credit) {
            commit('ADD_CREDIT', credit);
        },
        // Ação para adicionar uma indicação
        addIndication({ commit }, indication) {
            commit('ADD_INDICATION', indication);
        },
        // Ação para adicionar um pedido
        addOrder({ commit }, order) {
            commit('ADD_ORDER', order);
        },
        // Ação para atualizar um cadastro
        updateCadastre({ commit }, updatedCadastre) {
            commit('UPDATE_CADASTRE', updatedCadastre);
        },
        // Ação para remover um pedido
        removeOrder({ commit }, orderId) {
            commit('REMOVE_ORDER', orderId);
        },
        // Ação para carregar os dados do usuário pelo UID
        loadUserData({ commit }, uid) {
            console.log({
                uid
            })
            const userRef = dbRef(database, `users/${uid}`);

            // Obtém os dados do usuário do Realtime Database
            dbGet(userRef)
                .then(snapshot => {
                    const userData = snapshot.val();

                    // Atualiza as propriedades do estado com os dados do usuário
                    commit('ADD_CADASTRE', userData.myCadastre);
                    //commit('ADD_CREDIT', userData.myCredits);
                    commit('ADD_FAVORITE', userData.myFavorites);
                    //commit('ADD_INDICATION', userData.myIndications);
                    //commit('ADD_ORDER', userData.myOrders);
                })
                .catch(error => {
                    console.error('Erro ao carregar os dados do usuário:', error);
                });
        },
        // Ação para salvar os dados do usuário no Realtime Database
        saveUserData({ rootState }) {
            const uid = rootState.auth.user.uid;
            const userRef = dbRef.child(`users/${uid}`);
            const userData = {
                myCadastre: rootState.myModule.myCadastre,
                myCredits: rootState.myModule.myCredits,
                myFavorites: rootState.myModule.myFavorites,
                myIndications: rootState.myModule.myIndications,
                myOrders: rootState.myModule.myOrders
            };

            // Salva os dados do usuário no Realtime Database
            dbSet(userRef, userData)
                .then(() => {
                    console.log('Dados do usuário salvos com sucesso.');
                })
                .catch(error => {
                    console.error('Erro ao salvar os dados do usuário:', error);
                });
        },
        // Ação para remover um favorito do usuário no Realtime Database
        removeFavorite({ commit, rootState }, favoriteId) {
            const uid = rootState.auth.user.uid;
            const userFavoritesRef = dbRef.child(`users/${uid}/myFavorites`);

            // Remove o favorito do Realtime Database
            dbRemove(userFavoritesRef.child(favoriteId))
                .then(() => {
                    commit('REMOVE_FAVORITE', favoriteId);
                    console.log('Favorito removido com sucesso.');
                })
                .catch(error => {
                    console.error('Erro ao remover o favorito:', error);
                });
        },
        addFavorites({ commit, rootState }, favorite) {
            const uid = rootState.user.myCadastre.uid;
            const userFavoritesRef = dbRef(database, `users/${uid}/myFavorites`);

            // Adiciona o favorito ao Realtime Database
            dbPush(userFavoritesRef, favorite)
                .then((response) => {
                    const favoriteId = response.key;
                    commit('ADD_FAVORITE', { ...favorite, id: favoriteId });
                    console.log('Favorito adicionado com sucesso.');
                })
                .catch((error) => {
                    console.error('Erro ao adicionar o favorito:', error);
                });
        },
        // Ação para remover um pedido do usuário no Realtime Database
        removeUserOrder({ commit, rootState }, orderId) {
            const uid = rootState.auth.user.uid;
            const userOrdersRef = dbRef.child(`users/${uid}/myOrders`);

            // Remove o pedido do Realtime Database
            dbRemove(userOrdersRef.child(orderId))
                .then(() => {
                    commit('REMOVE_ORDER', orderId);
                    console.log('Pedido removido com sucesso.');
                })
                .catch(error => {
                    console.error('Erro ao remover o pedido:', error);
                });
        }
    },
    getters: {
        getCadastre: state => state.myCadastre,
        getCredits: state => state.myCredits,
        getFavorites: state => state.myFavorites,
        getIndications: state => state.myIndications,
        getOrders: state => state.myOrders
    }
};

<template>
    <main class="pg-internas bg-loja bg-indique-amigo">
        <div class="container">
            <div class="row">

                <div class="col-xs-12 col-md-7">
                    <div class="d-block mb-3">
                        <h2 class="mb-2">{{ title }}</h2>
                        <h4 class="mb-2">Ganhe <span>R$ {{ creditAmount }}</span> em créditos para você e ofereça
                            <span>15% de desconto</span> na primeira compra para o seu amigo indicado.</h4>
                        <div class="group-btn-flex">
                            <a href="javascript:;" class="btn js-btn-gerar" style="display: none;">Gerar meu código</a>
                            <a href="#como-funciona" class="btn-link js-scroll">Como funciona »</a>
                        </div>
                    </div>
                    <div class="d-block js-box-gerar" style="">
                        <div class="d-block mb-3">
                            <h5 class="mb-1">Seu código</h5>
                            <div class="form-flex-lg">
                                <input ref="referralCodeInput" type="text" id="cupom_amigo"
                                    class="form-control disabled" :value="referralCode" autocomplete="off">
                                <button @click="copyReferralCode" class="btn js-copiar"><i
                                        class="fa fa-files-o"></i>Copiar</button>
                            </div>
                        </div>
                        <div class="d-block">
                            <h5 class="mb-1">Compartilhe com seus amigos</h5>
                            <div class="group-flex-btn">
                                <button @click="shareWhatsApp" class="btn btn-whatsapp js-btn-whatsapp"><i
                                        class="fa fa-whatsapp"></i>WhatsApp</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xs-12 col-md-5 col-lg-4 col-lg-offset-1">
                    <div class="row">
                        <div class="col-xs-12 col-sm-6">
                            <div class="card-extrato boxHeight" style="height: 212px;">
                                <i class="fa fa-money"></i>
                                <h3>R$ {{ referralCredits }}</h3>
                                <h5>Créditos de indicação</h5>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6">
                            <div class="card-extrato boxHeight" style="height: 212px;">
                                <i class="fa fa-thumbs-o-up"></i>
                                <h3>{{ friendsUsed }}</h3>
                                <h5>Amigos que utilizaram</h5>
                            </div>
                        </div>
                        <div class="col-xs-12 text-center">
                            <a href="https://jullietcongelados.com.br/login-creditos" class="btn"><i
                                    class="fa fa-bar-chart"></i>Ver todos seus créditos</a>
                        </div>
                    </div>
                </div>

                <div class="col-xs-12 mt-5">
                    <hr>
                </div>

                <div id="como-funciona" class="col-xs-12 mt-3">
                    <h2 class="mb-2">Como funciona?</h2>
                    <div class="row">
                        <div class="col-xs-12 col-sm-6 col-md-4" v-for="(step, index) in steps" :key="index">
                            <div class="card-help boxHeight" style="height: 271px;">
                                <i :class="step.icon"></i>
                                <h4>{{ step.title }}</h4>
                                <h5>{{ step.description }}</h5>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </main>
</template>

<script>
export default {
    data() {
        return {
            title: "Indique amigos e ganhe créditos",
            creditAmount: 20.00,
            referralCode: "AMIGO.ALBATROIZ165",
            referralCredits: 0.00,
            friendsUsed: 0,
            steps: [
                {
                    icon: "fa fa-tags",
                    title: "Compartilhe",
                    description: "Envie seu código de desconto para seus amigos. Quanto mais amigos indicar, mais créditos você vai ganhar!"
                },
                {
                    icon: "fa fa-shopping-basket",
                    title: "Ganhe créditos",
                    description: "Você ganha a cada novo amigo que utilizar o seu código em sua primeira compra."
                },
                {
                    icon: "fa fa-smile-o",
                    title: "Aproveite",
                    description: "Utilize os créditos acumulados em suas próximas compras no site!"
                }
            ]
        };
    },
    methods: {
        copyReferralCode() {
            const input = this.$refs.referralCodeInput;
            input.select();
            document.execCommand("copy");
        },
        shareWhatsApp() {
            const message = `Olá! Use meu código de desconto "${this.referralCode}" e ganhe R$${this.creditAmount} de créditos na sua primeira compra. Aproveite!`;
            const whatsappURL = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`;
            window.open(whatsappURL, "_blank");
        }
    }
};
</script>

<style scoped>
/* Estilos específicos para este componente */
</style>

<template>
    <MainLayout>
        <section class="banner-interna"
            style="background-image: url(https://jullietcongelados.com.br/lojas/juliettecongelados/theme/assets/img/ban-interna-1.jpg);">
            <div class="pg-titulo">
                <div class="container">
                    <div class="row">
                        <div class="col-xs-12">
                            <h1 class="animated fadeIn">Favoritos</h1>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="container">
            <div class="row">
                <div class="col-xs-12">
                    <div class="box-filtro-produtos">
                        <div class="box-filtro-itens">
                            <div class="group-right">
                                <h5>{{ filteredCategoriesProducts.length }} itens</h5>
                                <div class="box-btn-group" :style="{ display: true ? 'block' : 'none' }">
                                    <!-- Selos -->
                                    <div class="btn-group js-filtro-dropdown js-filtro-selos">
                                        <a href="javascript:;" class="btn btn-line dropdown-toggle"
                                            data-toggle="dropdown">Selos</a>
                                        <div class="dropdown-menu lg">
                                            <div class="dropdown-content">
                                                <div class="group-selos categorias">
                                                    <span v-for="seal in seals" :key="seal.id" class="item-selo"
                                                        data-toggle="tooltip" :title="seal.name">
                                                        <div class="check-selo">
                                                            <input type="checkbox" :id="'selos_' + seal.id"
                                                                :value="seal" v-model="selectedSeals">
                                                            <label :for="'selos_' + seal.id">
                                                                <img :src="seal.image" :alt="seal.name" width="50"
                                                                    height="50">
                                                            </label>
                                                        </div>
                                                        <span class="badge">{{ seal.count }}</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Ordenação -->
                                    <div class="btn-group js-filtro-dropdown">
                                        <a href="javascript:;" class="btn btn-line dropdown-toggle"
                                            data-toggle="dropdown">Ordenação</a>
                                        <div class="dropdown-menu">
                                            <div class="dropdown-content">
                                                <div class="check-custom">
                                                    <label v-for="(option, index) in sortingOptions" :key="index"
                                                        :for="'ordenacao_' + option.value">
                                                        <input type="radio" name="ordenacao"
                                                            :id="'ordenacao_' + option.value" :value="option"
                                                            v-model="selectedSorting">
                                                        <i class="fa fa-circle-o"></i><i
                                                            class="fa fa-check-circle-o"></i>
                                                        {{ option.label }}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button @click="applyFilters"
                                        class="btn btn-filtrar js-filtro-aplicar">Aplicar</button>
                                </div>
                            </div>
                        </div>
                        <div class="box-filtro-resultado js-filtro-resultado"
                            v-if="selectedSeals.length > 0 || selectedSorting">
                            <h6>Filtros ativos</h6>
                            <div class="group scroll-mob">
                                <a v-for="(seal, index) in selectedSeals" :key="'seal-' + index" href="javascript:;"
                                    class="item-resultado js-filtro-range" @click="clearSealFilter(index)">
                                    {{ seal.name }}
                                    <span class="remove-filter">&times;</span>
                                </a>
                                <a v-if="selectedSorting" href="javascript:;" class="item-resultado js-filtro-range"
                                    @click="clearSortFilter()">
                                    {{ selectedSorting.label }}
                                    <span class="remove-filter">&times;</span>
                                </a>
                            </div>
                            <span class="btn btn-2 js-filtro-limpar" @click="filtersClean">Limpar filtros</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="box-order-mobile">
            <section class="vitrine-home order">
                <div class="container">

                    <div class="row" v-if="filteredCategoriesProducts.length">
                        <h1 class="seo sr-only">Deseja Comprar Comida Congelada? Conheça a Dona Sol Ajuda Congelados
                            Especiais! A
                            Melhor Solução em Comidas Prontas Congeladas em Rio de Janeiro.</h1>
                        <div class="col-xs-12" v-if="filteredCategoriesProducts.length">
                            <article class="" v-for="category in filteredCategoriesProducts" :key="category.categoryId">
                                <ShowcaseComponent :infoTitle="{ ...category, products: undefined }"
                                    :products="category.products || []" />
                            </article>
                        </div>
                    </div>

                    <div class="col-xs-12" v-else>
                        <div class="row">
                            <div class="alert alert-info">Não há produtos na sua lista de Desejos</div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </MainLayout>
</template>

<script>
import MainLayout from '@/layouts/MainLayout.vue';
import ShowcaseComponent from '@/components/ShowcaseComponent.vue'

import { mapActions, mapGetters } from 'vuex';

export default {
    components: {
        MainLayout,
        ShowcaseComponent
    },
    data() {
        return {
            sortingOptions: [
                { label: 'Menor preço', value: 'low_to_high' },
                { label: 'Maior preço', value: 'high_to_low' },
                { label: 'A - Z', value: 'a_to_z' },
                { label: 'Z - A', value: 'z_to_a' }
            ],
            seals: [],
            selectedSeals: [],
            selectedSorting: null,
            filteredCategoriesProducts: [],
            categoriesWishList: null,
        };
    },
    computed: {
        ...mapGetters('shop', ['getProductsFromWishListShop']),
        getFilteredProducts() {
            return this.filteredCategoriesProducts
        }
    },
    async beforeMount() {
        try {
            await this.loadMisingProduct()
            await this.getProductsFromWishList()
            console.log("axé")

            this.setCategoriesWishList()
            this.getAllSeals()
            this.filterProducts()

            console.log({
                getProductsFromWishListShop: this.getProductsFromWishListShop,
            })
        } catch (error) {
            console.error(error)
        }
    },
    methods: {
        ...mapActions('shop', ['loadMisingProduct', 'setProductsFilteredCategorySelected']),
        ...mapActions('user', ['getProductsFromWishList']),
        initializeFilters() {
            this.setCategorySelected();
            this.getAllSeals();
            // Adiando a chamada para filterProducts após a configuração inicial
            this.$nextTick(() => {
                this.filterProducts();
            });
        },
        getAllSeals() {
            const allSeals = {};
            for (const category of this.categoriesWishList) {

                const products = category.products;
                products.forEach(product => {
                    product.measurementsOptions.forEach(option => {
                        if (option.seals && Array.isArray(option.seals)) {
                            option.seals.forEach(seal => {
                                if (!allSeals[seal.sealId]) {
                                    allSeals[seal.sealId] = {
                                        id: seal.sealId,
                                        image: seal.image,
                                        name: seal.title,
                                        count: 1
                                    };
                                } else {
                                    allSeals[seal.sealId].count++;
                                }
                            });
                        }
                    });
                });
            }
            this.seals = Object.values(allSeals);
        },
        filterProducts() {
            this.filteredCategoriesProducts = []; // Reset filtered products

            for (const category of this.categoriesWishList) {
                let filteredCategoryProducts = JSON.parse(JSON.stringify(category.products)); // Copy products

                if (this.selectedSeals.length > 0) {
                    filteredCategoryProducts = this.filterProductsBySeal(filteredCategoryProducts);
                }

                if (this.selectedSorting) {
                    filteredCategoryProducts = this.sortProducts(filteredCategoryProducts, this.selectedSorting.value);
                }

                if (filteredCategoryProducts.length) {
                    // Verifique se selectedProduct é uma opção válida dentro de measurementsOptions
                    filteredCategoryProducts.forEach(product => {
                        const matchingOption = product.measurementsOptions.find(option => option.productId === product.selectedProduct);
                        if (!matchingOption) {
                            // Se não houver correspondência, atualize selectedProduct para a primeira opção
                            product.selectedProduct = product.measurementsOptions[0].productId;
                        }
                    });
                    this.filteredCategoriesProducts.push({ ...category, products: filteredCategoryProducts }); // Update filtered products
                }
            }
        }
        ,
        filterProductsBySeal(products) {
            const filteredCategoriesProducts = [];
            products.forEach(product => {
                const filteredOptions = product.measurementsOptions.filter(option => {
                    return option.seals.some(seal => {
                        return this.selectedSeals.some(selectedSeal => selectedSeal.id === seal.sealId);
                    });
                });
                if (filteredOptions.length > 0) {
                    const clonedProduct = { ...product, measurementsOptions: filteredOptions };
                    filteredCategoriesProducts.push(clonedProduct);
                }
            });
            return filteredCategoriesProducts;
        },
        sortProducts(products, sorting) {
            const sortMap = {
                'low_to_high': (a, b) => this.getMinPriceOfAllOptions(a) - this.getMinPriceOfAllOptions(b),
                'high_to_low': (a, b) => this.getMaxPriceOfAllOptions(b) - this.getMaxPriceOfAllOptions(a),
                'a_to_z': (a, b) => (this.getProductNameOfAllOptions(a) || '').localeCompare(this.getProductNameOfAllOptions(b) || ''),
                'z_to_a': (a, b) => (this.getProductNameOfAllOptions(b) || '').localeCompare(this.getProductNameOfAllOptions(a) || '')
            };

            const sortingFunction = sortMap[sorting];
            if (sortingFunction) {
                products.sort(sortingFunction);
            }
            return products;
        },
        getMaxPriceOfAllOptions(product) {
            let maxPrice = -Infinity;
            product.measurementsOptions.forEach(option => {
                const price = option.price.discount.active ? option.price.value * (1 - option.price.discount.value) : option.price.value;
                if (price > maxPrice) {
                    maxPrice = price;
                }
            });
            return maxPrice;
        },
        getMinPriceOfAllOptions(product) {
            let minPrice = Infinity;
            product.measurementsOptions.forEach(option => {
                const price = option.price.discount.active ? option.price.value * (1 - option.price.discount.value) : option.price.value;
                if (price < minPrice) {
                    minPrice = price;
                }
            });
            return minPrice;
        },
        getProductNameOfAllOptions(product) {
            // Concatenar todos os nomes das opções de medição
            return product.measurementsOptions.map(option => option.name).join(', ');
        },
        applyFilters() {
            this.filterProducts();
        },
        clearSealFilter(index) {
            this.selectedSeals.splice(index, 1);
            this.filterProducts();
        },
        clearSortFilter() {
            this.selectedSorting = null;
            this.filterProducts();
        },
        setCategoriesWishList() {
            this.categoriesWishList = this.getProductsFromWishListShop
        },
        filtersClean() {
            this.selectedSeals = [];
            this.selectedSorting = null;
            this.filterProducts()
        }
    },
    watch: {
        getProductsFromWishListShop() {
            this.setCategoriesWishList()
            this.filterProducts()
        }
    }
};
</script>

<template>
    <div>
      <span v-if="isExpired">
        Tempo esgotado! Tempo decorrido: {{ formatTime(elapsedTime) }}
      </span>
      <span v-else-if="isRunning">
      {{ formatTime(internalDuration) }}
      </span>
      <span v-else>
        O temporizador não está em execução.
      </span>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      startDate: {
        type: String,
        required: true
      },
      endDate: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        isRunning: false,
        isExpired: false,
        startTime: null,
        elapsedTime: 0,
        internalDuration: 0
      };
    },
    computed: {
      formatTime() {
        return function(milliseconds) {
          const totalSeconds = Math.floor(milliseconds / 1000);
          const days = Math.floor(totalSeconds / (60 * 60 * 24));
          const hours = Math.floor((totalSeconds % (60 * 60 * 24)) / (60 * 60));
          const minutes = Math.floor((totalSeconds % (60 * 60)) / 60);
          const seconds = totalSeconds % 60;
  
          let formattedTime = '';
          if (days > 0) {
            formattedTime += `${days} dia${days > 1 ? 's' : ''}, `;
          }
          if (hours > 0 || days > 0) {
            formattedTime += `${hours < 10 ? '0' : ''}${hours}:`;
          }
          formattedTime += `${minutes < 10 ? '0' : ''}${minutes}:`;
          formattedTime += `${seconds < 10 ? '0' : ''}${seconds}`;
  
          return formattedTime;
        };
      }
    },
    methods: {
      startTimer() {
        if (!this.isRunning) {
          this.startTime = new Date(this.startDate).getTime();
          const endTime = new Date(this.endDate).getTime();
          this.internalDuration = endTime - this.startTime;
          this.isExpired = false;
          this.intervalId = setInterval(() => {
            const currentTime = Date.now();
            const remainingTime = endTime - currentTime;
            if (remainingTime <= 0) {
              this.stopTimer();
            } else {
              this.internalDuration = remainingTime;
            }
          }, 1000);
          this.isRunning = true;
        }
      },
      stopTimer() {
        clearInterval(this.intervalId);
        this.isRunning = false;
        this.isExpired = true;
        this.elapsedTime = Date.now() - this.startTime;
      }
    },
    mounted() {
      this.startTimer();
    }
  };
  </script>
  